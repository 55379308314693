import { useEffect, useRef, useState } from "react";
import { Card, Input, Spinner } from "@nextui-org/react";
import { whiteGlow, yellowGlow } from "../../../helpers/glows";
import useSignMessage from "../../../hooks/useSignMessage";
import { toast } from "react-toastify";
import sounds from "../../../services/sounds";
import { useLetsTalk } from "../../../hooks/useLetsTalk";
import { useSelector } from "react-redux";
import MinefieldBoard from "./MinefieldBoard";
import DuelTurnTimer from "../DuelTurnTimer";
import { CardNice } from "../../small/CardNice";

export default function MinefieldCurrentRound({ action, mines, endsAt, whichPlayer = 0 }: any) {
    const [selected, setSelected] = useState<any>(null);

    const [loading, setLoading] = useState(null);

    // const [soundReminderInterval, setSoundReminderInterval] = useState<any>(null);
    const [shouldShake, setShouldShake] = useState(false);
    const intervalRef = useRef<any>();


    const { doPost } = useLetsTalk();
    const { user } = useSelector((state: any) => state.userData);

    const [disabled, setDisabled] = useState(false);


    async function submitCoord(coord) {
        // console.log("submitCoord", coord, whichPlayer, user);
        if (!whichPlayer) return;
        if (!user?.wallet) return;
        if (!action) return;
        if (loading) return;

        setSelected(coord);
        setLoading(coord);

        // clearTimeout(soundReminderInterval);
        clearInterval(intervalRef.current);


        let data = {
            duelId: action.duelId,
            minefieldActionId: action.id,
            walletId: user.wallet,
            coord,
        };
        // console.log("sending", data);
        try {
            let res = await doPost("duel/minefield/place-mine", data);

            // console.log("RES pas MinefieldCurrentRound", res);
            if (res?.error) {
                toast.error(res.error);
                // return false;
            }
        } catch (err) {
            console.error("Error in MinefieldCurrentRound", err);
            toast.error("Error submitting mine placement!");
        }
        setSelected(null);
        setLoading(null);

    }

    function remindPlayer() {
        // console.log("SOUND REMINDER", intervalRef.current, action.hand1, action.hand2, whichPlayer);
        sounds.playMakeYourMove();
        setShouldShake(true);
        const timer = setTimeout(() => setShouldShake(false), 1500);
        return timer;
    }

    useEffect(() => {
        // console.log("action changed", action?.square1, action?.square2, whichPlayer);
        if ((!action.square1 && whichPlayer === 1) || (!action.square2 && whichPlayer === 2)) {
            // console.log("setting sound reminder ");
            setDisabled(false);

            let totalDuration = (new Date(endsAt).getTime()) - (new Date().getTime());

            // Calculate the initial delay for the first reminder
            let initialDelay = totalDuration % (30 * 1000); // Remainder of totalDuration divided by 30 seconds
            if (initialDelay < 15 * 1000) { // If less than 15 seconds, adjust to the next 30-second interval
                initialDelay += 30 * 1000;
            }

            // let reminderIntervalTime = - 30 * 1000;
            let reminder = setInterval(() => {
                let timer = remindPlayer();
                return () => clearTimeout(timer);
            }, initialDelay - 15 * 1000);  //30*1000
            intervalRef.current = reminder;
            // console.log("set sound reminder", reminder);
        } else {
            setDisabled(true);
            // console.log("clearing sound reminder (faled if)", intervalRef.current);
            clearInterval(intervalRef.current);
        }
        return () => {
            // console.log("DESTRUCTOR, clearing sound reminder", intervalRef.current);
            clearInterval(intervalRef.current);
        }
    }, [action, whichPlayer]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);
    // useEffect(() => {
    //     console.log("action changed", action?.square1, action?.square2, whichPlayer);
    //     // if (!action) return;

    //     if (soundReminderInterval) {
    //         clearInterval(soundReminderInterval);
    //         console.log("CLEARING REMINDER INTERVAL");
    //     }

    //     if ((action.square1 == null && whichPlayer === 1) || (action.square2 == null && whichPlayer === 2)) {
    //         setDisabled(false);
    //         console.log("SETTING REMINDER INTERVAL");
    //         // if(soundReminderInterval) return;
    //         // setSoundReminderInterval(setInterval(() => {
    //         //     if (disabled) {
    //         //         clearInterval(soundReminderInterval);
    //         //         return;
    //         //     }
    //         //     sounds.playMakeYourMove();
    //         //     setShouldShake(true);
    //         //     const timer = setTimeout(() => setShouldShake(false), 1500);
    //         //     return () => clearTimeout(timer);
    //         // }, 20 * 1000));
    //         const newInterval = setInterval(() => {
    //             if (disabled) {
    //                 clearInterval(newInterval);
    //                 return;
    //             }
    //             sounds.playMakeYourMove();
    //             setShouldShake(true);
    //             const timer = setTimeout(() => setShouldShake(false), 1500);
    //             // Cleanup the shake timeout when the interval runs
    //             return () => clearTimeout(timer);
    //         }, 20 * 1000);
    //         setSoundReminderInterval(newInterval);
    //     } else {
    //         setDisabled(true);
    //         // if (soundReminderInterval) {
    //         //     clearInterval(soundReminderInterval);
    //         //     console.log("CLEARING REMINDER INTERVAL");
    //         // }
    //     }
    //     return () => {
    //         if (soundReminderInterval) {
    //             clearInterval(soundReminderInterval);
    //         }
    //     };
    // }, [action, whichPlayer]);

    // useEffect(() => {
    //     return () => {
    //         clearInterval(soundReminderInterval);
    //     }
    // }, []);


    // useEffect(() => {
    //     console.log("mines or action changed", mines, action);
    // }, [mines, action]);

    //  ${!loading && "scale-in-center"} 
    return (<CardNice
        className={` w-full max-w-xl ${shouldShake ? 'shake1500 blinkBg1500' : ''}`}
    // isBlurred
    // className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10  p-2 

    //     transition-all
    //     ${shouldShake ? 'shake1500 blinkBg1500' : ''}`}
    // shadow="sm"
    >

        <DuelTurnTimer endsAt={endsAt} />


        <div className=" w-full flex flex-col justify-center items-center">
            {(!loading && action &&
                ((action.square1 == null && whichPlayer === 1) || (action.square2 == null && whichPlayer === 2)))
                ?
                <div className="text-lg my-2">Place the mine!</div>
                :
                <div className="font-bold my-2">
                    {whichPlayer === 0 ? "Minefield hidden until finish..." : "Waiting for opponent action..."}
                </div>
            }

            {action?.size &&
                <MinefieldBoard size={action.size} mines={mines} disabled={disabled} selected={selected} loading={loading} clickCallback={submitCoord} />
            }

            {/* {action?.size &&
                <div className={`grid w-full gap-1`}
                    style={{
                        gridTemplateColumns: `repeat(${action.size}, 1fr)`,
                        gridTemplateRows: `repeat(${action.size}, 1fr)`,
                        opacity: disabled ? 0.5 : 1
                    }}
                >
                    {Array.from({ length: action.size * action.size }, (_, i) => i).map((coord, i) => {
                        let used = mines.includes(coord);
                        return (
                            // max-w-[${100/action.size}%] w-[${100/action.size}%]  aspect-square`
                            //aspect-w-1 aspect-h-1 cursor-pointer drop-shadow-lg aspect-square
                            <div key={i}
                                className={` ${!used && `bg-foreground/50 cursor-pointer `} 
                                            ${!used && !disabled && `hover:bg-foreground/100`} 
                                            rounded-md drop-shadow-lg aspect-square`}
                                style={{ ...(!selected || selected === coord ? whiteGlow : {}) }}
                                onClick={() => {
                                    if (used || disabled) return;
                                    submitCoord(coord)
                                }}
                            >
                                {used && <img src="/duels/minefield/mine.svg" className="w-full h-full" alt="Mine" />}
                            </div>
                        );
                    })}
                </div>
            } */}

        </div>


        {/* {loading && <Spinner size="lg" color="white" label="Submitting..."></Spinner>} */}

    </CardNice>);
}