import { Chip, Link, Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import ToolTipFix from "../../components/extensions/TooltipFix";


export default function YourDuelsPage() {

    const [duelsList, setDuelsList] = useState<any[]>([]);
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();

    function load(){
        if (duelsList.length > 0) return;
        setLoading(true);

        doGet("duel/get-history").then((ret) => {
            if (ret.ok) {
                setDuelsList(ret.data);
            } else {
                setError(ret.error || "An error has occurred.");
            }
            // setDuelsList(data);
            // console.log("duel data", data);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [publicKey]);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Your duels</h2>
        <div>
            <TransparentTable
                headers={['ID', "Type", "Opponent", "Result", "VSCN"]}
                label="Your duels"
                isLoading={loading}
                emptyContent={"No duel history found."}
                error={error}
                load={load}
                data={
                    duelsList.map((duel, idx) => {
                        let opponent = duel?.player1 === publicKey ? duel?.player2 : duel?.player1;
                        let result;
                        if (!duel?.winner) result = "Pending";
                        else if (duel?.winner === publicKey) result = "Win";
                        else result = "Lose";
                        return ([<Link href={`/duel/${duel.type}/${duel.id}`} size="sm"  >#{duel.id}</Link>,
                        duel?.typeName || duel?.type,
                        opponent ? <ToolTipFix showArrow={true} content={opponent}>
                            {opponent?.substring(0, 6)}
                        </ToolTipFix> : "None",
                            result,
                        Intl.NumberFormat().format(duel?.amount)
                        ]);
                    })
                }
            />

            <div className="text-sm m-4">
                Showing up to 200 latest duels.
            </div>
        </div>
    </div >
    );
}