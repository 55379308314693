import React from "react";
import Ship from "./Ship";
import { IShip } from "../../shared/types";
import { LABELS_OF_SHIP } from "../../shared/ship";
// import { LABELS_OF_SHIP } from "../../constants";

interface IProps {
  ships: IShip[];
  isDraggable?: boolean;
  shipOnClick?: Function;
}

const ShipList = ({ ships, isDraggable = false, shipOnClick }: IProps) => {
  const categories = ships.reduce((res: any, item: IShip) => {
    if (!res[item.length]) {
      res[item.length] = {
        type: LABELS_OF_SHIP[item.length],
        ships: []
      };
    }

    res[item.length].ships.push(item);

    return res;
  }, {});

  return (
    <div className="flex w-full flex-col gap-2 uppercase">
      {Object.values(categories).reverse().map((category: any) => (
        // <div key={`ship-row-${category.type}`} className="ship-list__row">
        <div key={`ship-row-${category.type}`} className="flex flex-row items-center p-1 gap-2 w-full m-auto">
          {/* <div className="ship-list__row__label">{category.type}</div> */}
          <div className="min-w-20 mr-1 font-bold text-sm text-left">{category.type}</div>
          {category.ships.map((ship: IShip) => (
            ship.isOnBoard() ? "" : <Ship id={ship.id} key={`ship-${ship.id}`} ship={ship} isDraggable={!!isDraggable} shipOnClick={shipOnClick} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ShipList;
