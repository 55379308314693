import { Button, Popover, PopoverContent, PopoverTrigger, Tooltip } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import ToolTipFix from "../extensions/TooltipFix";



export default function InfoTooltip({ className = '', classNames = { trigger: '' }, triggerClassName="inline",  infoText, infoHeader = '' }) {
    const [isOpen, setIsOpen] = useState(false);

    // const iconRef = useRef(null);
    // useEffect(() => {
    //     const iconElement = iconRef.current;

    //     if (iconElement) {
    //         const stopPropagation = (e) => {
    //             e.stopPropagation();
    //         };

    //         (iconElement! as any).addEventListener('click', stopPropagation);

    //         return () => {
    //             (iconElement! as any).removeEventListener('click', stopPropagation);
    //         };
    //     }
    // }, []);

    return (
        <ToolTipFix
            showArrow={false}
            triggerScaleOnOpen={true}
            // triggerClassName={""}
            triggerClassName={`min-w-4 min-h-4 ${classNames['trigger']}`}
            // placement="left-start"
            color="primary"
            // isOpen={isOpen}
            shouldFlip={true}

            content={
                <div className="z-10 px-1  py-2 max-w-64 text-sm ">
                    {infoHeader && infoHeader.length > 1 && <div className="text-small font-bold">{infoHeader}</div>}
                    <div className="text-md">{infoText}</div>
                </div>
            }
        >
            {/* <Button isIconOnly 
            // className=" absolute z-10 right-2"
            > */}
            <FontAwesomeIcon
                // ref={iconRef}
                // onMouseEnter={() => setIsOpen(true)}
                // onMouseLeave={() => setIsOpen(false)}
                onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    // e.nativeEvent.stopImmediatePropagation();
                    // setIsOpen(!isOpen);
                    // console.log("clicked", e);
                    // return false;
                }}

                className={`opacity-70 w-auto h-auto  '}`} icon={faCircleInfo} />

                {/* className={`cursor-pointer opacity-70 ${classNames['trigger'] ? classNames['trigger'] : 'm-2 max-w-6 absolute z-10 right-2 right-2'} ${className}`} icon={faCircleInfo} /> */}
            {/* </Button> */}
        </ToolTipFix>
    );

    // return (
    //     <Popover showArrow={true}

    //         placement="left-start"
    //         color="primary"

    //     >
    //         <PopoverTrigger>
    //             <FontAwesomeIcon className={`cursor-pointer m-2 text-xl max-w-6 opacity-70 absolute z-10 right-2 ${classNames['trigger'] ? classNames['trigger']  : '' }`} icon={faCircleInfo} />
    //         </PopoverTrigger>
    //         <PopoverContent>
    //             <div className="px-1 py-2 max-w-64 text-sm ">
    //                 {infoHeader && infoHeader.length > 1 && <div className="text-small font-bold">{infoHeader}</div>}
    //                 <div className="text-md">{infoText}</div>
    //             </div>
    //         </PopoverContent>
    //     </Popover>
    // );

    // return (
    //     <Tooltip
    //         placement="left-start"
    //         color="primary"
    //         content={
    //             <div className="px-1 py-2 max-w-64 text-sm">
    //                 {infoHeader && infoHeader.length > 1 && <div className="text-small font-bold">{infoHeader}</div>}
    //                 <div className="text-md">{infoText}</div>
    //             </div>
    //         }
    //     >
    //         <FontAwesomeIcon className=' m-2 text-xl max-w-6 opacity-70 absolute z-10 right-2' icon={faCircleInfo} />
    //         {/* <Button className={className} variant="bordered">

    //         </Button> */}
    //     </Tooltip>
    // );
}