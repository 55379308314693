import { Button, Card, CardBody, Link } from "@nextui-org/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import VSCNCoin from "../components/small/VSCNCoin";
import { CardNice } from "../components/small/CardNice";


declare global {
    interface Window {
        Jupiter: any;
    }
}


export default function GetVSCNPage() {

    const passthroughWalletContextState = useWallet();
    useEffect(() => {
        if (!window.Jupiter.syncProps) return;
        window.Jupiter.syncProps({ passthroughWalletContextState });
    }, [passthroughWalletContextState.connected]);


    useEffect(() => {
        window.Jupiter.init({
            displayMode: "integrated",
            integratedTargetId: "integrated-terminal",
            strictTokenList: false,
            /** Required
             * Solana RPC endpoint
             * We do not recommend using the public RPC endpoint for production dApp, you will get severely rate-limited
            */
            endpoint: process.env.REACT_APP_RPC_ENDPOINT!,
            enableWalletPassthrough: true,
            defaultExplorer: "Solscan",
            formProps: {
                fixedInputMint: true,
                fixedOutputMint: true,
                initialInputMint: "So11111111111111111111111111111111111111112",
                initialOutputMint: process.env.REACT_APP_VSCN_MINT,
            },
        });
    }, []);



    return (<div className="max-w-2xl m-auto">
        <h2 className="text-xl font-bold m-3">Get VSCN <VSCNCoin className="max-h-8" /></h2>



        <CardNice >
            <CardBody>

                <div className="flex justify-center space-x-4">
                    <Button as={Link} color="primary" showAnchorIcon className="" href="https://raydium.io/swap/?outputCurrency=4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r" target="_blank" >
                        <img className='max-w-6' src="/img/raydium.png" alt="Raydium" /> Raydium
                    </Button>
                    <Button as={Link} color="primary" showAnchorIcon className="" href="https://jup.ag/swap/SOL-4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r" target="_blank" >
                        <img className=' max-w-6' src="/img/jupiter.png" alt="Jupiter" /> Jupiter
                    </Button>
                </div>

                <div className="text- text-md mt-12">
                    <h3 className="text-lg text-center font-bold m-2">Or swap Solana to VSCN <VSCNCoin /> here!</h3>
                    <div id="integrated-terminal"></div>
                </div>
            </CardBody>
        </CardNice>

    </ div>);
}