import { useDraggable, useDroppable } from "@dnd-kit/core";
import React from "react";

interface IProps {
  id?: string;
  className?: string;
  x?: number;
  y?: number;
  shipId?: number;
  onClick?: Function;
  isDamaged?: boolean;
  isInactive?: boolean;
  hidden?: boolean;
  isOpen?: boolean;
  isInvalid?: boolean;
  children?: any;
  isDroppable?: boolean;
  onCellDragStart?: Function;
}

const Cell = ({
  id,
  className,
  hidden,
  x,
  y,
  shipId = -1,
  onClick,
  isDamaged,
  isInactive,
  isOpen,
  isInvalid = false,
  children,
  isDroppable = false,
  onCellDragStart,
}: IProps) => {
  let cellClass = "cell";

  const { attributes, listeners, setNodeRef: setDraggableNodeRef, transform } = useDraggable({
    id: id as any,
    disabled: false
  });



  const { isOver, setNodeRef } = useDroppable({
    id: id || `${x}:${y}`,
    disabled: !isDroppable
  });
  const style = {
    // backgroundColor: isOver ? 'green' : undefined,
    transform: transform ? `translate3d(${transform?.x}px, ${transform?.y}px, 0)` : undefined,

  };

  if (className) {
    cellClass = `${cellClass} ${className}`;
  }

  if (shipId > -1 && !hidden) {
    cellClass = `${cellClass} cell--ship`;
  } else if (!hidden && isInactive) {
    cellClass = `${cellClass} cell--inactive`;
  }

  // if (isDamaged) {
  //   cellClass = `${cellClass} cell--damaged`;
  // }

  // if (!hidden && isInactive) {
  //   cellClass = `${cellClass} cell--inactive`;
  // }

  // if (isOpen) {
  //   //shot but empty probably
  //   cellClass = `${cellClass} cell--open`;
  // }

  // cellClass = `${cellClass} bg-gray-500`;

  const handleOnClick = (ev: any) => {
    if (onClick) {
      onClick(x, y, ev);
    }
  };

  return (
    <div
      ref={setNodeRef}
      id={id}
      className={`${cellClass} ${isOver ? "bg-gray-400/50":''} 
      ${shipId > -1 && !isDamaged && !isInvalid ? "bg-white":''} 
      ${isDamaged ? 'bg-red-300/50 text-red-500':'text-black'} 
      ${onClick ? "cursor-pointer":''}
      `} // ${cellClass}
      // ${isDamaged ? 'text-red': 'text-black'}
      data-x={x}
      data-y={y}
      data-shipid={shipId}
      onClick={handleOnClick}
      style={style}
      onMouseDown={(e) => { if (onCellDragStart) onCellDragStart(e) }}

    // {...listeners} {...attributes}
    >
      {/* {isDroppable ? "y" : "n"} */}
      {children}
    </div>
  );
};

export default Cell;
