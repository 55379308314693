import { Card, CardBody, Link } from "@nextui-org/react";
import Socials from "./Socials";


export function AppFooter({ }) {


    return (<>
        <Socials />
        <Card shadow="none" className="text-white text-center p-4 mb-6 border-none bg-transparent">
            <CardBody className="text-white text-center block ">© VersusCoin | <Link color="foreground" href="https://t.me/vscn_bet" target="_blank">Support (Telegram)</Link>
                <br /><br /><br />
                <p>▲<br />▲▲</p>
            </CardBody>
        </Card>


    </>);

}