const BASE58_ALPHABET = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
const BASE58_MAP = new Map(BASE58_ALPHABET.split("").map((char, index) => [char, index]));

export function base58ToHex(base58: string): string {
    let num = BigInt(0);

    for (let i = 0; i < base58.length; i++) {
        const char = base58[i];
        const value = BASE58_MAP.get(char);
        if (value === undefined) {
            throw new Error(`Invalid Base58 character: ${char}`);
        }
        num = num * BigInt(58) + BigInt(value);
    }

    const hexString = num.toString(16).padStart(6, '0').slice(0, 6);
    return `#${hexString}`;
}

// Example usage:
// const base58String = "3MNQE1X";
// const hexColor = base58ToHex(base58String);
// console.log(hexColor);  // Outputs: #00e1e1 (for example)


export function base58ToRGBA(base58: string, alpha=1): string {
    let num = BigInt(0);

    for (let i = 0; i < base58.length; i++) {
        const char = base58[i];
        const value = BASE58_MAP.get(char);
        if (value === undefined) {
            throw new Error(`Invalid Base58 character: ${char}`);
        }
        num = num * BigInt(58) + BigInt(value);
    }

    const r = Number(num >> BigInt(16) & BigInt(255));
    const g = Number(num >> BigInt(8) & BigInt(255));
    const b = Number(num & BigInt(255));
    // const a = 1; // Alpha channel set to 1 (fully opaque)

    return `rgba(${r},${g},${b},${alpha})`;
}