import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useRef } from "react";
import { setUserData } from "../store/actions/reducerActions";
import { useWallet } from "@solana/wallet-adapter-react";


const JWT_STORAGE_KEY = "VSCN_JWT"; //ALSO CHANGE IN useAuth.tsx and LetsTalk and AnalSingleton !!!!!


export function useLetsTalk() {
    // const { getJWT } = useAuth();
    let { jwt, jwtLoaded } = useSelector((state: any) => state.userData);

    const dispatch = useDispatch();
    const { disconnect } = useWallet();

    // const jwtLoadedRef = useRef(jwtLoaded);

    // useEffect(() => {
    //     jwtLoadedRef.current = jwtLoaded;
    // }, [jwtLoaded]);

    async function removeJWT() {
        localStorage.removeItem(JWT_STORAGE_KEY);
        jwt = null;
        dispatch(setUserData({ user: null, jwt: null, jwtLoaded: true }));
        disconnect();
    }

    async function doGet<T>(url: string, options?: any): Promise<T | any> {
        return new Promise(async (resolve, reject) => {
            if (!jwtLoaded) {
                // console.log("JWT not loaded yet", jwtLoaded);
                // return reject("JWT not loaded yet");
                jwt = localStorage.getItem(JWT_STORAGE_KEY);
                // console.log("letstalk read jwt", jwt);
            }
            // console.log("jwtLoaded", jwtLoaded);
            // while (!jwtLoaded) {
            //     // Wait for jwt to be available
            //     console.log("jwtLoaded", jwtLoaded);

            //     await new Promise(resolve => setTimeout(resolve, 100));
            // }

            // console.log("get", jwt, jwtLoaded);

            fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...(jwt ? { 'Authorization': `Bearer ${jwt}` } : {})
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        console.error("[LetsTalk] GET error with status code: ", response.status);
                        if (response.status == 403) {
                            // console.error("JWT expired, logging out");
                            // removeJWT();
                        }
                    }
                    return response.json().then((data) => {
                        return resolve(data);
                    });
                })
                // .then((response) => response.json())
                // .then((data) => {
                //     return resolve(data);
                // })
                .catch((error) => {
                    console.error("[LetsTalk] GET error: ", error);
                    return reject(error);
                });
        });
    }

    async function doPost<T>(url: string, data: any): Promise<T | any> {
        return new Promise(async (resolve, reject) => {
            if (!jwtLoaded) {
                // console.log("JWT not loaded yet", jwtLoaded);
                // return reject("JWT not loaded yet");
                jwt = localStorage.getItem(JWT_STORAGE_KEY);
                // console.log("letstalk read jwt", jwt);
            }

            fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...(jwt ? { 'Authorization': `Bearer ${jwt}` } : {})
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    return resolve(data);
                })
                .catch((error) => {
                    console.error("[LetsTalk] POST error: ", error);
                    return reject(error);
                });
        });
    }

    async function doPostForm(url: string, formData: FormData): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (!jwtLoaded) {
                // console.log("JWT not loaded yet", jwtLoaded);
                jwt = localStorage.getItem(JWT_STORAGE_KEY);
            }

            fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: "POST",
                body: formData, // Directly use FormData object
                headers: {
                    'Accept': 'application/json',
                    ...(jwt ? { 'Authorization': `Bearer ${jwt}` } : {})
                    // Do not set 'Content-Type' manually for FormData
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    return resolve(data);
                })
                .catch((error) => {
                    console.error("[LetsTalk] POST Form error: ", error);
                    return reject(error);
                });
        });
    }

    return { doGet, doPost, doPostForm };
}