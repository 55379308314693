import { useEffect, useState } from "react";
import { BattleOutcome } from "./ResultsOverlay";
import { Button, Link } from "@nextui-org/react";
import BattleTimer from "./BattleTimer";
import InfoBattleTooltip from "../info-tooltip/InfoBattleTooltip";
import AmountChangeNotifier from "../small/AmountChangeNotifier";
import VSCNCoin from "../small/VSCNCoin";



const scoreChangeNotif = {
    WebkitFilter: "drop-shadow(0 0 12px rgba(255,255,255, 0.65))",
    color: "rgb(255,255,255)",
    marginBottom: "20px"
};


export default function BattleInsinde({ battle, small, betButtonCallback }: any) {
    const { name1, img1, name2, img2, amount1, amount2, wallet1, tokenWallet1, wallet2, tokenWallet2 } = battle;
    const battleId = battle.id;

    const namesTextSize = (name1?.length + name2?.length) < 12 ? "text-tiny p-1 sm:text-md md:text-lg md:p-2 " : "text-sm md:text-md p-1 md:p-2 ";

    const [finished, setFinished] = useState(battle.finished || false);
    const [winner, setWinner] = useState(battle.winner || 0);


    const [healthBar1, setHealthBar1] = useState(0);
    const [healthBar2, setHealthBar2] = useState(0);

    // const [amount1, setamount1] = useState(amount1);
    // const [amount2, setamount2] = useState(amount2);

    const [swordLeftAttack, setSwordLeftAttack] = useState(false);
    const [swordRightAttack, setSwordRightAttack] = useState(false);
    const [t1, setT1] = useState<any>(null);
    const [t2, setT2] = useState<any>(null);

    useEffect(() => {
        if (battle.winner) setWinner(battle.winner);
    }, [battle.winner]);
    useEffect(() => {
        if (battle.finished) setFinished(battle.finished);
    }, [battle.finished]);

    useEffect(() => {
        if (amount1 === 0 && amount2 === 0) {
            setHealthBar1(50);
            setHealthBar2(50);
            return;
        }
        let health1 = Math.round(amount1 / (amount1 + amount2) * 100);
        setHealthBar1(health1);
        setHealthBar2(100 - health1);
    }, [amount1, amount2]);

    const animateAttack = (type) => {
        switch (type) {
            case "LEFT":
                // console.log("t1", t1);
                if (t1) return;
                setSwordLeftAttack(true);
                setT1(setTimeout(() => {
                    setSwordLeftAttack(false);
                    setT1(null);
                }, 600));
                break;
            case "RIGHT":
                if (t2) return;
                setSwordRightAttack(true);
                setT2(setTimeout(() => {
                    setSwordRightAttack(false);
                    setT2(null);
                }, 600));
                break;
            default:
                return null
        }
    }

    useEffect(() => {
        animateAttack("LEFT");
    }, [amount1]);

    useEffect(() => {
        animateAttack("RIGHT");
    }, [amount2]);

    const betButtonClick = async (betD) => {
        // if (!publicKey) {
        //     toast.error("Please connect your wallet first!");
        //     return;
        // }

        betButtonCallback({ ...betD, battleId: battleId },
        );
    };

    const handleTimeEnd = () => {
        setFinished(true);
    }


    return (<div className="">
        {small && <div className="text-tiny opacity-70 m-0 float-left">#{battleId}</div>}
        {!small && <Link showAnchorIcon className="text-tiny opacity-70 m-0 float-left cursor-pointer text-white"
            onPress={() => {
                let url = window.location.href;
                let title = `Join battle "${battle?.name1} vs ${battle?.name2}" on vscn.bet!`;
                if (navigator.share) {
                    navigator.share({ url, title });
                } else {
                    navigator.clipboard.writeText(`${title} ${url}`);
                }
            }}>#{battleId}</Link>
        }


        {!small && <InfoBattleTooltip />}
        {finished ? <div className={`${small ? 'text-md' : 'text-xl'} m-auto p-2`}>Battle finished! {winner === 0 && "Calculating outcome..."}</div> : <BattleTimer battleData={battle} onTimeEnd={handleTimeEnd} small={small} />}

        <div className="relative animWrp m-0" >
            <div className="flex flex-row m-auto items-center" style={{ maxHeight: "80vw", maxWidth: "600px", animationDelay: "300ms" }}>
                <div className="warriorLeft flex flex-col items-center w-1/2 mr-0 relative">
                    <div className="aspect-square w-full h-full bg-center bg-contain bg-no-repeat relative" style={{ backgroundImage: 'url("/img/skeleton_left.svg")' }}>
                        <div className="aspect-square m-auto mt-6 flex flex-col items-center justify-center " style={{
                            width: "70%", height: "70%",
                            //paddingRight: "5%" 

                        }}>
                            <div className="aspect-square overflow-hidden max-w-full max-h-full w-full h-full flex justify-center items-center" style={{
                                zIndex: 3, borderRadius: "50%", objectFit: 'contain', marginLeft: "5%",
                                WebkitFilter: "drop-shadow(1px 0 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white)"
                                
                                // backgroundImage: `url("${img1}")`,
                                // filter: "blur(8px)"
                            }}>
                                <img loading="eager" className="max-w-full max-h-full w-full h-full" style={{
                                    zIndex: 3, objectFit: 'contain',
                                }}
                                    src={img1} alt={name1}

                                />
                            </div>

                            {/* <div className=" aspect-square overflow-hidden  flex justify-center items-center" style={{ zIndex: 3, borderRadius: "50%", }}>
                                <img loading="eager" className="w-full h-full " style={{ zIndex: 3, objectFit: 'contain' }} src={img1} alt={name1} />
                            </div> */}

                            {/* <div className="w-full aspect-square  max-w-[90%]  grid items-center justify-center m-4 overflow-hidden " style={{ marginRight: "25%", }}>
                                <div className="w-full h-full aspect-square overflow-hidden  flex justify-center items-center" style={{ zIndex: 3, borderRadius: "50%", }}>
                                    <img loading="eager" className="w-full max-h-full " style={{ objectFit: 'contain', }} src={img1} alt={name1} />
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className={`swordLeft float-right absolute ${swordLeftAttack ? 'swordLeftAttack' : ''}`}
                        style={{ maxHeight: "50%", width: "70%", zIndex: 0, top: "10%", right: "0" }} >
                        <img className="w-full h-full object-contain" src="/img/sword_left_hand2.png" alt="Sword of the left fighter" />
                    </div>
                    {finished && winner ? <BattleOutcome winner={winner === 1} /> : null}
                </div>
                <div className="warriorRight flex flex-col items-center w-1/2 ml-6 relative">
                    <div className="aspect-square w-full h-full bg-center bg-contain bg-no-repeat relative" style={{ backgroundImage: 'url("/img/skeleton_right.svg")' }}>
                        <div className="aspect-square m-auto mt-6 flex flex-col items-center justify-center " style={{
                            width: "70%", height: "70%",
                            // paddingLeft: "5%"

                        }}>

                            <div className="aspect-square overflow-hidden max-w-full max-h-full w-full h-full flex justify-center items-center" style={{
                                zIndex: 3, borderRadius: "50%", objectFit: 'contain', marginRight: "5%",
                                WebkitFilter: "drop-shadow(1px 0 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 -1px 0 white)"

                                //bg-center bg-contain bg-no-repeat
                                // backgroundImage: `url("${img2}")`,

                            }}>
                                <img loading="eager" className="max-w-full max-h-full w-full h-full" style={{ zIndex: 3, objectFit: 'contain' }} src={img2} alt={name2} />
                            </div>


                            {/* <div className=" aspect-square overflow-hidden max-w-full max-h-full flex justify-center items-center" style={{ zIndex: 3, borderRadius: "50%", objectFit:'contain' }}>
                                <img loading="eager" className="max-w-full max-h-full" style={{ zIndex: 3, objectFit:'contain' }} src={img2} alt={name2} />
                            </div> */}


                            {/* <div className="w-full aspect-square max-w-[90%] grid items-center justify-center m-4 overflow-hidden" style={{ zIndex: 3, marginLeft: "25%", borderRadius: "50%", }}>
                                <div className="w-full h-full aspect-square  flex justify-center items-center overflow-hidden" style={{ zIndex: 3, borderRadius: "50%", }}>
                                    <img loading="eager" className="w-full max-h-full " style={{ zIndex: 3, objectFit: 'contain', }} src={img2} alt={name2} />
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className={`swordRight float-left absolute ${swordRightAttack ? 'swordRightAttack' : ''}`}
                        style={{
                            maxHeight: "50%", width: "70%", zIndex: 0, top: "10%", left: "0",
                        }} >
                        <img className="w-full h-full object-contain" src="/img/sword_right_hand2.png" alt="Sword of the right fighter" />
                    </div>
                    {finished && winner ? <BattleOutcome winner={winner === 2} /> : null}
                </div>
            </div>
            <div className="battleInfo flex flex-col m-auto pt-0 justify-items-center content-center items-center justify-center overflow-hidden" style={{ marginTop: "-0.5em", animationDelay: "1s", maxWidth: "80vh" }} >
                <div className="healthBars justify-items-center content-center items-center justify-center  flex flex-row w-full  overflow-hidden mt-1"
                    style={{ maxWidth: "100%" }}>
                    <div className="healthBarLeft rounded-l-md bg-green-500 h-6 text-center text-black"
                        style={{ width: `${healthBar1}%`, border: "solid white 1px", transition: 'width 1s ease-out' }}>
                        {healthBar1}%
                    </div>
                    <div className="healthBarRight  rounded-r-md  bg-primary h-6 text-center text-black"
                        style={{ width: `${healthBar2}%`, border: "solid white 1px", transition: 'width 1s ease-out' }}>
                        {healthBar2}%
                    </div>
                </div>
                <div className="flex flex-row items-stretch space-evenly mt-2 md:m-4 w-full">
                    <div className="flex flex-col mr-0 grow flex-1">
                        <div className="healthTextLeft mb-1 relative"
                            style={{ animationDelay: "2000ms" }}>
                            <AmountChangeNotifier style={scoreChangeNotif} className="text-md " amount={amount1} />
                            {Intl.NumberFormat().format(Math.floor(Number.parseFloat(amount1)))}
                            <VSCNCoin />
                        </div>
                        <Button isDisabled={finished && !small}
                            // href={small ? `/battles/${battleId}` : undefined}

                            className={`name  text-white  h-auto min-h-10 min-w-full flex-grow shadow-md text-wrap ${namesTextSize} ${small ? "pointer-events-none " : ""} m-auto ${finished ? "opacity-100" : ""}`}
                            onClick={(ev) => {
                                // small && ev.preventDefault();
                                if (!betButtonCallback) return;
                                betButtonClick({ name: name1, img: img1, toWallet: wallet1, toTokenWallet: tokenWallet1, side: 1 });
                            }
                            } variant="solid" color="success"><span className="drop-shadow-[0_1px_3px_rgba(0,0,0,0.5)]">{name1}</span></Button>
                    </div>
                    <div className={`flex items-center w-auto max-w-12 mx-1 ${small ? "max-w-12 " : "max-w-16 md:max-w-20 md:mx-4"}`}>
                        <img src="/img/logo.png" alt="VersusCoin Logo" className={`logoFlip m-auto max-w-full`}
                        // style={{ WebkitFilter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.8));" }} 
                        />
                    </div>
                    <div className="flex flex-col ml-0 grow flex-1">
                        <div className="healthTextRight mb-1 relative" style={{ animationDelay: "2000ms" }}>
                            <AmountChangeNotifier style={scoreChangeNotif} className="text-md " amount={amount2} />

                            {Intl.NumberFormat().format(Math.floor(Number.parseFloat(amount2)))}
                            <VSCNCoin />
                        </div>
                        <Button isDisabled={finished && !small}
                            // as={small ? Link : undefined}
                            // href={small ? `/battles/${battleId}` : undefined}
                            className={`name text-white h-auto min-h-10  min-w-full flex-grow shadow-md text-wrap ${namesTextSize} ${small ? "pointer-events-none " : ""}  m-auto ${finished ? "opacity-100" : ""}`}
                            onClick={(ev) => {
                                // if (small) {
                                //     ev.preventDefault();
                                //     return
                                // };
                                // small && ev.preventDefault();
                                if (!betButtonCallback) return;
                                betButtonClick({ name: name2, img: img2, toWallet: wallet2, toTokenWallet: tokenWallet2, side: 2, });
                            }
                            }

                            variant="solid" color="primary"><span className="drop-shadow-[0_1px_3px_rgba(0,0,0,0.5)]">{name2}</span></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>);

}