
import { useEffect, useState } from "react";
import "../../css/dice-rolling.css"

export default function DiceRoll({ diceRoll, finishedCallback, userRoll, won = false, className = "" }) {
    const [currRoll, setCurrRoll] = useState(0);
    const [rollInterval, setRollInterval] = useState<any>();
    // const [showResult, setShowResult] = useState(false);
    // var elDiceOne = document.getElementById('dice1');



    // function rollDice() {

    //     var diceOne = Math.floor((Math.random() * 6) + 1);
    //     var diceTwo = Math.floor((Math.random() * 6) + 1);

    //     console.log(diceOne + ' ' + diceTwo);

    //     for (var i = 1; i <= 6; i++) {
    //         elDiceOne.classList.remove('show-' + i);
    //         if (diceOne === i) {
    //             elDiceOne.classList.add('show-' + i);
    //         }
    //     }

    //     //   setTimeout(rollDice(), 1000);
    // }

    const randRoll = () => {
        setCurrRoll(Math.floor((Math.random() * 6) + 1));
    }

    const doRolls = () => {
        if (rollInterval) clearInterval(rollInterval);
        let intv = setInterval(() => {
            randRoll();
        }, 200);
        setRollInterval(intv);

        setTimeout(() => {
            clearInterval(intv);
            setCurrRoll(diceRoll);
            // setShowResult(true);
            setTimeout(() => {
                finishedCallback(diceRoll);
                //animacija uztrunka 1s
            }, 800);
        }, 3000);
    };

    useEffect(() => {
        // console.log("diceRoll changed", diceRoll);
        doRolls();

    }, [diceRoll]);


    return (
        <div 
        // onClick={() => { doRolls() }} 
        className={`game `}>
            <div className="h-36 flex items-center justify-center w-full align-center" style={{ fontSize: "12px" }}>
                <div className={`dice m-auto show-${currRoll}`}>
                    <div className='side one'>
                        <div className="dot one-1"></div>
                    </div>
                    <div className='side two'>
                        <div className="dot two-1"></div>
                        <div className="dot two-2"></div>
                    </div>
                    <div className='side three'>
                        <div className="dot three-1"></div>
                        <div className="dot three-2"></div>
                        <div className="dot three-3"></div>
                    </div>
                    <div className='side four'>
                        <div className="dot four-1"></div>
                        <div className="dot four-2"></div>
                        <div className="dot four-3"></div>
                        <div className="dot four-4"></div>
                    </div>
                    <div className='side five'>
                        <div className="dot five-1"></div>
                        <div className="dot five-2"></div>
                        <div className="dot five-3"></div>
                        <div className="dot five-4"></div>
                        <div className="dot five-5"></div>
                    </div>
                    <div className='side six'>
                        <div className="dot six-1"></div>
                        <div className="dot six-2"></div>
                        <div className="dot six-3"></div>
                        <div className="dot six-4"></div>
                        <div className="dot six-5"></div>
                        <div className="dot six-6"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}