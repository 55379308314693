
import { useEffect, useState } from "react";
import { Button, Card, Link, Spinner } from "@nextui-org/react";
import { socket } from "../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { setLotteriesAction } from "../store/actions/reducerActions";
import LotteryInside from "./lottery/LotteryInside";
import { useLetsTalk } from "../hooks/useLetsTalk";
import { CardNice } from "./small/CardNice";


export default function CurrentLotteries({ className }: any) {
    const dispatch = useDispatch();

    const [lotteriesLoading, setlotteriesLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const { lotteries } = useSelector((state: any) => state.lotteriesData);

    const { doGet } = useLetsTalk();


    async function loadLotteries() {
        if (lotteriesLoading) return;
        setlotteriesLoading(true);

        doGet("lottery/get-running").then((ret) => {
            if (ret.ok) {
                dispatch(setLotteriesAction(ret.data));
                setError(undefined);
            } else {
                setError(ret.error || "An error has occurred.");
                console.error("Error loading lotteries", ret);
            }
            setlotteriesLoading(false);
        }).catch((error) => {
            console.error(error);
            setError(error?.toString() || JSON.stringify(error));
            setlotteriesLoading(false);
        });

    }


    useEffect(() => {
        loadLotteries();
        socket.io.on("reconnect", () => { loadLotteries() });
        return () => {
            socket.io.off("reconnect", () => { loadLotteries() })
        };
    }, []);



    return (<>
        <div className={`m-auto ${className}`}>
            <h2 className="text-3xl font-bold m-3">Current lotteries</h2>
            {lotteriesLoading && <Spinner size="lg">Loading...</Spinner>}
            {!lotteriesLoading && (!lotteries || lotteries?.length < 1) && !error && <div className="text-lg">No lotteries right now. Stay tuned!</div>}

            {!lotteriesLoading && error && <div>
                <div className="text-red-400">{error}</div>
                <br />
                <Button color="primary" onClick={() => loadLotteries()}>Retry</Button>
            </div>}


            <div className={`m-auto grid grid-cols-1 ${lotteries?.length > 1 ? 'md:grid-cols-2' : ''} ${lotteries?.length > 2 ? 'lg:grid-cols-3' : ''} gap-8 items-start`}>
                {lotteries?.length > 0 && lotteries?.map((lottery) => {
                    return (
                        <Link key={lottery?.id} href={`/lottery/${lottery.id}`} disableAnimation={true} className="no-hover-opacity hover:bg-background/10 rounded-xl"
                        >
                            <CardNice finished={lottery.finished}>
                                <LotteryInside lottery={lottery} small={true} />
                            </CardNice>
                        </Link>
                    );
                })}
            </div >
        </div>
    </>
    );
}