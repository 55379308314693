import { Card, CardBody, Link } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { CardNice } from "../components/small/CardNice";

export default function InfoPage() {

    // const fee = 5.0;
    // const { balanceVSCN, balanceSOL } = useSelector((state: any) => state.beta);
    const { currentFee, betLimit } = useSelector((state: any) => state.betaData);



    return (<div className="max-w-2xl m-auto">
        <h2 className="text-2xl font-bold m-3 ">Beta information</h2>

        <CardNice>
            <CardBody>
                <div className="text- text-md">
                    <h3 className="text-2xl text-center font-bold m-2">Welcome to the Open Beta!</h3>
                    <p className="text-warning text-center text-lg m-2 font-bold ">Warning: You may lose your crypto!</p>
                    <p className="text-justify">This is still a beta version of the platform, please report any issues to the developers on our <Link href="https://t.me/vscn_bet" target="_blank" color="success" >Telegram channel</Link>.
                        The platform is under active development and new features are added regularly.
                    </p>
                    <br />
                    <p><b>Some things to know before betting:</b></p>
                    <ul className="list-disc ml-8">
                        <li>The platform is currently in beta and might contain bugs.</li>
                        <li>Current single bet limit: {betLimit} $VSCN.</li>
                        <li>Every bet also cost a little bit of SOL to cover payout transaction fees.</li>
                        <li>If your bet transaction goes through only after the battle is finished, your bet will not register!</li>
                        <li>No refunds! If something goes bad, you WILL LOSE YOUR CRYPTO. Act accordingly.</li>
                        <li>Current fee rate is {currentFee}% of winnings.</li>

                    </ul>
                    <br />
                    <h3 className="text-2xl text-center font-bold m-2">Battles</h3>
                    <p><b>How is the winner calculated?</b></p>
                    <p>Winning side is calculated randomly, but the amount betted influence the chance. For example, the left fighter has 20% and the right fighter has 80%. The left fighter has only 20% chance of winning, but their winnings will be much greater.</p>
                    <br />
                    <p><b>How are the payouts calculated?</b></p>
                    <p>Winners split all the coins depending of the amount of their total bets.</p>
                    <p>Example: You bet 10 VSCN on the left fighter. In total, the left fighter received 100 VSCN, and the right fighter received 200 VSCN. We take {currentFee}% of the winnings, therefore total amount is: left + (right - {currentFee}%) = {100 + (200 - (200 * (currentFee / 100)))} VSCN. Your payout would be 10% of the total amount and equal {(100 + (200 - (200 * (currentFee / 100)))) / 10} VSCN.</p>
                    <br />
                    <br />
                    <h3 className="text-2xl text-center font-bold m-2">Lotteries</h3>
                    <p><b>Weekly Jackpot</b></p>
                    <p>Thrown in VSCN counts as tickets and increased the jackpot. The winner is calculated randomly. We take a {currentFee}% fee from the amount won.</p>
                    <br />
                    <p><b>Dice Roll</b></p>
                    <p>Thrown in VSCN and roll the dice. If you manage to guess the dice roll, you win the bag! If you fail, some amount goes to the dice bag increasing it. We take a {currentFee}% fee from the amount won.</p>
                    <br />
                    <br />
                    <h3 className="text-2xl text-center font-bold m-2">Duels</h3>
                    <p><b>General information</b></p>
                    <p>Create a duel, thrown in VSCN and wait for an opponent!</p>
                    <p>Set the amount, how many wins of the duel you want to play for, and a maximum time to wait for an opponent or between the actions. Failing to find an opponent will result in a canceled duel (you will get a refund). Failing to do an action before the time runs out results in a loss. We take a {currentFee}% fee from the amount won.</p>
                    <br />
                    <p><b>Coin Flip</b></p>
                    <p>A simple coin flip duel with a 50/50 chance of winning or losing.</p>
                    <br />
                    <p><b>Rock Paper Scissors</b></p>
                    <p>Classic childhood game. Rock beats Scissors, Paper beats Rock and Scissors beat Paper. Throwing the same hand results in a tie.</p>
                    <br />
                    <p><b>Minefield</b></p>
                    <p>Place mines! If you place a mine on a square where your opponent has placed theirs earlier, you lose! If you both place on each other's squares the same turn, it's a tie and a new board is played!</p>
                    <br />
                    <p><b>Battleships</b></p>
                    <p>Classic game of Battleships! Place all 10 of your ships on the board, and try to sink enemy's ships before they sink yours! Player 1 goes first. Ships can't touch each other. If your shot was a hit, you get to shoot again!</p>


                </div>
            </CardBody>
        </CardNice>

    </ div>);
}