import { Card, CardBody } from "@nextui-org/react";
import { useSelector } from "react-redux";

export default function MaintenanceMessage() {
    const maintenance = useSelector((state: any) => state.maintenanceData);
    // console.log("maintenance", maintenance);

    return (<>
        {maintenance?.message && <Card className="m-auto mt-4 mb-4 max-w-80 bg-red-600">
            <CardBody>
                <p>{maintenance?.message} {maintenance?.starts ? maintenance.starts.toLocaleString() : ''}</p>
            </CardBody>
        </Card>
        }
    </>);
}
