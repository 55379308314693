import React, { useEffect } from "react";
import Cell from "./Cell";
import { IShip } from "../../shared/types";
import { useDraggable } from "@dnd-kit/core";
import { Orientation } from "../../shared/ship";
// import { Orientation } from "../../types/enums";

interface IProps {
  id: number;
  ship: IShip | null;
  isSelected?: boolean;
  isDraggable?: boolean;
  nodeRef?: any;
  className?: string;
  style?: any;
  shipOnClick?: Function;
}

const Ship = ({ id, ship, isSelected, nodeRef, isDraggable = false, className, style, shipOnClick }: IProps) => {

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    disabled: !isDraggable
  });
  const styleTransform = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;


  useEffect(() => {
    if (nodeRef) {
      setNodeRef(nodeRef);
    }
  }, [nodeRef, setNodeRef]);

  if (!ship) return null;



  // let className = `ship ship--${ship.orient}`;

  // if (isSelected) {
  //   className += " ship--current";
  // }

  // const rotated = ;

  // const styleTransform = {
  //   // transformOrigin: "left top",

  //   // ...(transform ? {
  //   //   transform: `${ship.orient === Orientation.Vertical && ""} translate3d(${transform.x}px, ${transform.y}px, 0)`
  //   // } : {}),
  //   ...(ship.orient === Orientation.Vertical ? {
  //     transformOrigin: "left top",
  //     transform: `rotate(90deg) translateY(-100%) ${transform &&  `translate3d(${transform.y}px, -${transform.x}px, 0)`}`,
  //   } : {
  //     transform: `${transform &&  `translate3d(${transform.x}px, ${transform.y}px, 0)`}`
  //   })
  // };

  function onClick(e:any){
    // console.log("ship click", ship);
    if(shipOnClick){
      shipOnClick(e, ship);
    }
  }

  return (
    <div id={`ship-${id}`} className={`ship ${ship.orient === Orientation.Vertical ? "flex-col" : ''} ship--${ship.orient} ${isSelected ? " ship--current":''} ${className} ${ship.invalid ? "bg-red-500/50 z-20": " z-10 "} `} data-id={ship.id} ref={setNodeRef}
      style={{ ...style, ...styleTransform }}
      {...listeners} {...attributes}
      // onClick={() => {
      //   console.log("rotating ship", ship);
      //   ship.rotate()
      // }}
      onClick={onClick}
    >
      {ship.positions().map((i: IShip, index: number) => (
        <Cell
          // id={id}
          // className="bg-gray-200 z-10"
          key={`${ship.id}-${index}`}
          shipId={ship.id}
          // isInactive={ship.isOnBoard()}
          isInvalid={ship.invalid}
          isDroppable={false}
        />
      ))}
    </div>
  );
};

export default Ship;
