import { Button, Card, CardBody, Chip, Spinner, Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { socket } from "../../services/socket";

import '../../css/chat.css';
import VSCNCoin from "../small/VSCNCoin";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import ToolTipFix from "../extensions/TooltipFix";
import Message from "./Message";
import { useSelector } from "react-redux";


export default function MessagesBox({ url, title, noMessages, sideFunc, socketChannel = undefined }: any) {
    const [loading, setLoading] = useState(false);

    const [messages, setMessages] = useState<any>([]);
    const { doGet } = useLetsTalk();

    const [error, setError] = useState<string | undefined>(undefined);

    const { user } = useSelector((state: any) => state.userData);
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        setIsAdmin(user?.admin && (user?.adminRoles?.includes("ALL") || user?.adminRoles?.includes("CHAT")));
    }, [user]);


    useEffect(() => {
        load();
    }, [url]);


    useEffect(() => {
        function newMessage(message) {
            // console.log("MessagesBox newMessage", message);
            // message.timestamp = new Date().getTime(); // jibanas reactas
            setMessages(prevMessages => [message, ...prevMessages]);
        }
        socket.on(socketChannel, newMessage);

        return () => {
            socket.off(socketChannel, newMessage);
        };
    }, []);


    async function load() {
        if (loading) return;
        setLoading(true);
        doGet(url).then((ret) => {
            if (ret.ok) {
                setMessages([...ret.messages]);
                setError(undefined);
            } else {
                setError(ret?.error || ret?.toString() || "Error loading messages.");
            }

            setLoading(false);
        }).catch((data) => {
            console.error(data);
            setError(data?.error || data?.toString() || "Error loading messages.");
        });

    }

    useEffect(() => {
        function newMessage(message) {
            // console.log("newMessage", message);
            message.timestamp = new Date().getTime(); // jibanas reactas
            setMessages(prevMessages => [message, ...prevMessages]);
        }
        socket.on('message', newMessage);

        return () => {
            socket.off('message', newMessage);
        };
    }, []);

    return (<Card className="mt-8 p-2 pt-0 w-full bg-background/10 " shadow="sm">
        <h2 className="text-xl m-6 font-bold">{title}</h2>

        {loading && <Spinner size="lg" />}

        {((!error && (!messages && !loading) || messages.length < 1)) &&
            <div>
                <p className="text-center text-sm">No messages yet.</p>
                <p className="text-center text-sm">{noMessages}</p>
            </div>
        }

        {error &&
            <div>
                <p className="text-center text-sm">Error!</p>
                <p className="text-center text-sm">{error}</p>
                <Button className="my-4 mx-auto" onClick={load}>Retry</Button>
            </div>
        }


        {messages.map((message: any, index) =>
            <Message key={message.id || index} message={message} right={sideFunc(message)} admin={isAdmin} />
        )}

    </Card >
    );
}