import { socket } from "../../services/socket";

const initialState = {
    user: null,
    jwt: null,
    jwtLoaded: false,
    checked: false
}

export const USER_REDUCER_TYPES = {
    SET_USER: "SET_USER",
}

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USER_REDUCER_TYPES.SET_USER:
            // console.log("SET_USER", action.payload);
            if (action.payload.user && action.payload.jwt) {
                socket.emit('wallet-connect', action.payload.user, action.payload.jwt);
            }

            return {
                user: action.payload.user,
                jwt: action.payload.jwt,
                jwtLoaded: action.payload.jwtLoaded,
                checked: true
            };
        default:
            return state;
    }
}

export default userReducer;