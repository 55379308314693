
// import './coin-flip.css';

import { useEffect, useRef, useState } from "react";


export default function CoinFlipResult({ size = "12", player1, player2, winner, amount }) {
    //if winner, atsukt i puse kurios player == winner (p1 heads, p2 tails)+
    const img = "/img/logo.png";
    const img2 = "/img/coin1.png";

    const coinRef = useRef(null);

    const [anim, setAnim] = useState("");
    // useEffect(() => {
    //     if (winner) {
    //         // Wait for the next frame for the animation cancellation to take effect
    //         (coinRef.current as any).animation[0].onfinish = () => { 
    //             requestAnimationFrame(() => {
    //                 // Now rotate the coin to the specific degree
    //                 let x = (coinRef.current as any).offsetHeight;
    //                 (coinRef.current as any).style!.transform = winner === player2 ? 'rotateY(180deg)' : 'rotateY(0deg)';
    //             });
    //         };

    //     }
    // }, [winner, player1, player2]);

    useEffect(() => {
        if (winner) {
            // console.log("winner", winner, winner === player2);
            setAnim(winner === player2 ? 'coinToTails 0.5s 1 ease-out forwards' : 'coinToHeads 0.5s 1 ease-out forwards');
        }else{
            setAnim("coinFlip 0.5s infinite linear forwards");
        }
    }, [winner, player1, player2]);

    return (
        <div className="flex flex-col w-auto m-auto my-8">
            {/* {winner} */}
            <div className="block relative m-auto"
                style={{ perspective: '1000px', fontSize: size }} >
                <div className=" rotatingCoin"
                    ref={coinRef}
                    style={{
                        animation: anim,
                        // animationDuration: '0.5s',
                        // transition: 'transform 0.5s ease-out',
                        // transition: 'transform 1s ease-out',
                        // ...(winner === player2 ? {transform: "rotateY(180deg)"} : {}),
                    }}>

                    <div className="front" style={{ backgroundImage: `url(${img})` }}></div>

                    <div className="coinFlipSide">
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                        <div className="spoke"></div>
                    </div>

                    <div className="back" style={{ backgroundImage: `url(${img2 ? img2 : img})` }}></div>
                </div>
            </div>
        </div >);


    // return (<div className="block relative w-[400px] h-[400px] relative"
    //     style={{ perspective: '1000px', marginTop: "400px" }} >
    //     {/* marginTop: "400px"  */}
    //     <div className="text-2xl"  >
    //         <div className=" coin">


    //             <div className="front"></div>

    //             <div className="coinFlipSide">
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 {/* <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div>
    //                 <div className="spoke"></div> */}
    //             </div>

    //             <div className="back flex flex-col justify-center justify-items-center content-center items-center"
    //                 style={{ fontFamily: "Barmo" }}>
    //                 <div className=" p-2 bg-background/50 rounded-md">
    //                     <div className="text-lg">100K</div>
    //                     <div className="text-md">VSCN</div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div >);
}