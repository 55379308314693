import { Card } from "@nextui-org/react";

export function CardNice({ isBlurred = true, ...props }) {
    return (
        <Card {...props}
        //max-w-xl
            className={`text-center m-auto w-auto  border-none ${props?.finished ? "bg-gray-700/80 dark:bg-gray-700/80 " : "bg-background/50 dark:bg-default-100/30"} p-2 transition-all ${props.className} `}
            isBlurred={isBlurred}
            shadow={props.shadow || "sm"} >
            {props.children}
        </Card>)
}