
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { getAccount, getAssociatedTokenAddressSync, TokenAccountNotFoundError } from '@solana/spl-token';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { useDispatch, useSelector } from 'react-redux';
import { setBetaAccess, setBetaData, setWalletBalanceAction, setWalletBalanceGettingAction } from '../store/actions/reducerActions';


//REAL: 4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r
//DEV: 9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL
const VSCN_MINT = new PublicKey(process.env.REACT_APP_VSCN_MINT!);
const VSCN_DECIMALS = Number.parseInt(process.env.REACT_APP_VSCN_DECIMALS!);

// const TOKEN_ADDRESS = new PublicKey('9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL'); //👈 Replace with your wallet address
// const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
//     'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
// );

// function findAssociatedTokenAddress(
//     walletAddress: PublicKey,
//     tokenMintAddress: PublicKey
// ): PublicKey {
//     return PublicKey.findProgramAddressSync(
//         [
//             walletAddress.toBuffer(),
//             TOKEN_PROGRAM_ID.toBuffer(),
//             tokenMintAddress.toBuffer(),
//         ],
//         SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
//     )[0];
// }

// async function getTokenBalanceWeb3(connection, tokenAccount) {
//     const info = await connection.getTokenAccountBalance(tokenAccount);
//     if (info.value.uiAmount == null) throw new Error('No balance found');
//     console.log('Balance (using Solana-Web3.js): ', info.value.uiAmount);
//     return info.value.uiAmount;
// }

const useWalletBalance = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    //VIDINIS!!!!
    // const [savedPublicKey, setSavedPublicKey] = useState<any>(null);

    const [balanceVSCN, setBalanceVSCN] = useState<any>(0);
    const [balanceSOL, setBalanceSOL] = useState<any>(0);
    const dispatch = useDispatch();

    const [fetchingBalance, setFetchingBalance] = useState<any>(false);
    const [gettingBetaData, setGettingBetaData] = useState<any>(false);
    // const [initialFetched, setInitialFetched] = useState<any>(false);

    const { walletBalance } = useSelector((state: any) => state.walletBalance);


    useEffect(() => {
        if (!publicKey) {
            dispatch(setWalletBalanceAction({ publicKey: null, balanceSOL: 0, balanceVSCN: 0, gettingBalance: false }));
        }
    }, [publicKey]);


    //ehh login logika
    useEffect(() => {
        // console.log("publicKey", publicKey);
        if (!publicKey || gettingBetaData) return;

        setGettingBetaData(true);
        // console.log("Getting beta data wallet", publicKey);
        fetch(`${process.env.REACT_APP_API_URL}beta/check-wallet`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ walletId: publicKey.toString() }),
        })
            .then(response => response.json())
            .then((ret) => {
                // console.log("response", ret);
                if (ret.allowed) {
                    // console.log("ALLOWED", ret, ret.allowed);
                    dispatch(setBetaData({
                        betaAccess: ret.allowed, betLimit: ret.betLimit, currentFee: ret.currentFee, lamportsRequiredToBet: ret.lamportsRequiredToBet,
                        lotteryDicePercent: ret.lotteryDicePercent
                    }));
                } else {
                    // setError(ret.error ? ret.error : "Something went wrong. Please try again later.");
                    dispatch(setBetaAccess({ betaAccess: false }));
                }
                setGettingBetaData(false);
            })
            .catch(async (data) => {
                console.error("ERROR", data);
                dispatch(setBetaAccess({ betaAccess: false }));
                // setError(data.error ? data.error : "Server error!");
                setGettingBetaData(false);
            });

    }, [publicKey]);


    const fetchBalance = useMemo(() => async () => {
        // console.log("fetchBalance publickey", publicKey?.toString(), connection);
        if (!publicKey) return;
        if (fetchingBalance) return;
        try {
            dispatch(setWalletBalanceGettingAction({ gettingBalance: true }));

            setFetchingBalance(true);
            // console.log("Fetching balance for", publicKey.toString());

            const balance = await connection.getBalance(publicKey, "confirmed");
            setBalanceSOL((balance / 1e9).toFixed(3)); // Convert lamports to SOL

            // let vscnBalance = 0;//BigInt(0);

            // const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
            //     publicKey, { programId: TOKEN_PROGRAM_ID } //, mint: VSCN_MINT
            // );

            // tokenAccounts.value.map(acc => {
            //     if (new PublicKey(acc.account.data.parsed.info.mint).equals(VSCN_MINT)) {
            //         vscnBalance = acc.account.data.parsed.info.tokenAmount.uiAmount
            //         setBalanceVSCN(vscnBalance.toFixed(1));
            //     }
            // });


            let tokenPubkey = getAssociatedTokenAddressSync(new PublicKey(VSCN_MINT), publicKey);

            // const infoToken = await getAccount(connection, tokenPubkey);
            // const amountReal = infoToken.amount;
            // vscnBalance = Number(amountReal / BigInt(10 ** VSCN_DECIMALS));


            let amountReal = BigInt(0);
            let amount = 0;
            let decimals = VSCN_DECIMALS;
            let infoToken;
            try {
                infoToken = await getAccount(connection, tokenPubkey);
            } catch (e: any) {
                if (e instanceof TokenAccountNotFoundError) {
                    console.log("Token account not found, acting as balance is 0. ", e);
                } else {
                    console.error(" Error getting token account! ", e);
                    // return null;
                }
            }

            if (infoToken) {
                amountReal = infoToken?.amount || 0;
                amount = Number(amountReal / BigInt(10 ** decimals));
            } else {
                console.log("[getBalance] No token info found (balance probably 0)", tokenPubkey.toString());
            }
            // console.log('[getBalance] Amount, real: ', amount, amountReal);

            let vscnBalance = amount; //Number(amountReal / BigInt(10 ** decimals));



            const _publicKey = publicKey.toString();
            const _balanceSOL = (balance / 1e9).toFixed(3);
            const _balanceVSCN = vscnBalance.toFixed(1);
            // console.log("Setting balance", _balanceSOL, _balanceVSCN);
            // @REDUXHELP cia sukisa i reduxa
            dispatch(setWalletBalanceAction({ publicKey: _publicKey, balanceSOL: _balanceSOL, balanceVSCN: _balanceVSCN, gettingBalance: false }));


            setFetchingBalance(false);

        } catch (error) {
            console.error("ERROR while fetching balance!", error);
            setFetchingBalance(false);
        }
    }, [connection, publicKey]);

    // useEffect(() => {
    //     // if (!initialFetched) {
    //     // console.log("Fetching Balance");
    //     fetchBalance();
    //     // }
    //     // eslint-disable-next-line
    // }, [fetchBalance]);

    return { balanceVSCN, balanceSOL, refetchBalance: fetchBalance };
};

export default useWalletBalance;