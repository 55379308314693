
import { useEffect, useRef, useState } from 'react';
import '../css/rotating-coin.css';


export default function RotatingCoin({ size = "12px", img = "/img/logo.png", img2 = undefined, front = undefined, back = undefined, animationSpeed = 8000 }) {
    const [animSpeed, setAnimSpeed] = useState(animationSpeed);
    const [isHovered, setIsHovered] = useState(false);

    const coinRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        if (coinRef.current) {
            animationRef.current = (coinRef.current as any).animate(
                [
                    { transform: 'rotateY(0deg)' },
                    { transform: 'rotateY(360deg)' }
                ],
                {
                    duration: animationSpeed,
                    iterations: Infinity
                }
            );
        }
    }, [coinRef, animationSpeed]);

    const handleMouseEnter = () => {
        if (animationRef.current) {
        (animationRef.current as any).updatePlaybackRate(8);
        }
    };

    const handleMouseLeave = () => {
        if (animationRef.current) {
            (animationRef.current as any).updatePlaybackRate(1);
        }
    };

    return (<div className="block relative "
        style={{ perspective: '1000px', fontSize: size }} >
        <div className=" rotatingCoin" 
        ref={coinRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleMouseEnter}
        onTouchEnd={handleMouseLeave}
        // style={{
        //     animation: `coinFlip ${animSpeed}ms infinite linear`,
        //     animationDuration: `${animSpeed}ms `,
        //     animationPlayState: isHovered ? 'paused' : 'running',

        // }}
        //     onMouseEnter={() => {
        //         setAnimSpeed(animationSpeed / 2)
        //         setIsHovered(true);
        //     }}
        //     onMouseLeave={() => {
        //         setAnimSpeed(animationSpeed)
        //         setIsHovered(false);
        //     }}
        >

            <div className="front" style={{ backgroundImage: `url(${img})` }}>
                {front}
            </div>

            <div className="coinFlipSide">
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                <div className="spoke"></div>
                {/* <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div>
                    <div className="spoke"></div> */}
            </div>

            <div className="back" style={{ backgroundImage: `url(${img2 ? img2 : img})` }}>
                {back}
            </div>
        </div>
    </div >);
}