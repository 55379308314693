import InfoTooltip from "./InfoTooltip";

const lotteryTypeExplanations = {
    "normal": "As the clock strikes zero, a winner is randomly chosen, with their contributed VSCN amount tipping the scales in their favor.",
    "dice": "Throw in a percentage of the bag and pick a number between 1 and 6. If the dice rolls your number, you win the pot! If you fail, the increased bag waits for the next roll. ",
};

const lotteryTypeHeaders = {
    "normal": "Normal Lottery",
    "dice": "Dice Bag Lottery",
}

export default function InfoLotteryTooltip({ className = '', classNames={trigger:'w-4 h-4 float-right'}, type }) {

    return (<InfoTooltip className={className} classNames={classNames}
        infoHeader={lotteryTypeHeaders[type]}
        infoText={lotteryTypeExplanations[type]}
    />);
}