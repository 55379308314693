import sounds from "../../services/sounds";

const initialState = {
    battles: []
}

export const BATTLES_REDUCER_TYPES = {
    BATTLE_ADD: "BATTLE_ADD",
    BATTLE_UPDATE: "BATTLE_UPDATE",
    BATTLE_REMOVE: "BATTLE_REMOVE",
    BATTLES_SET: "BATTLES_SET"

}

const battlesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case BATTLES_REDUCER_TYPES.BATTLE_ADD: {
            if (!action.payload?.battle) return state;
            let battle = action.payload.battle;
            let found = false;
            const updatedBattles = state.battles.map((x: any) => {
                if (x.id === battle.id) {
                    found = true;
                    return {
                        ...x,
                        ...battle
                    };
                }
                return x; 
            });

            if (found) {
                return {
                    ...state, 
                    battles: updatedBattles
                };
            } else {
                let battles: any[] = [...(state.battles || []), battle];
                let sorted = battles.sort((a: any, b: any) => {
                    if (a.finished && !b.finished) {
                        return 1;
                    }
                    if (b.finished && !a.finished) {
                        return -1;
                    }
                    return new Date(a.endsAt).getTime() - new Date(b.endsAt).getTime();
                });
                return {
                    ...state, 
                    battles: sorted
                };
            }
        }
        case BATTLES_REDUCER_TYPES.BATTLE_UPDATE:
            // console.log("battle-update received", action.payload.battle);
            // let found = false;
            const battleUpdate = action.payload.battle;
            let btls = state.battles.map((battle: any) => {
                // found = true;
                if (battle.id === battleUpdate.id) {
                    // console.log("BATTLE_UPDATE in redux", battle, action.payload.battle);
                    if (battleUpdate.amount1 && (battle.amount1 != battleUpdate.amount1) ||
                        battleUpdate.amount2 && (battle.amount2 != battleUpdate.amount2)) {
                        sounds.playSwing();
                    }
                    return {
                        ...battle,
                        ...battleUpdate
                    };
                }
                return battle;
            })
            // let sorted = btls.sort((a: any, b: any) => {
            //     // If a is finished and b is not, a should go to the end
            //     if (a.finished && !b.finished) {
            //         return 1;
            //     }
            //     // If b is finished and a is not, b should go to the end
            //     if (b.finished && !a.finished) {
            //         return -1;
            //     }
            //     // If both are finished or both are not finished, sort by date
            //     return new Date(a.endsAt).getTime() - new Date(b.endsAt).getTime();
            // });

            return {
                battles: btls
            };
        case BATTLES_REDUCER_TYPES.BATTLE_REMOVE:
            return {
                // ...state,
                battles: state.battles.filter((battle: any) => battle.id !== action.payload.battleId)
            };
        case BATTLES_REDUCER_TYPES.BATTLES_SET:
            // console.log("BATTLES_REDUCER_TYPES.BATTLES_SET", action.payload.battles);
            return {
                battles: action.payload.battles
            };
        default:
            return state;
    }
}

export default battlesReducer;