import { useCallback, useMemo, useState } from "react";
import { Button, Card, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { WalletBalance } from "../components/small/WalletBalance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useBuyTicketsModal from "./useBuyTicketsModal";
import useDiceRollModal from "./useDiceRollModal";


const useTicketsModalByType = ({ lottery, onCloseCallback, onSuccessCallback, modalData: _modalData }) => {
    const buyTicketsModal = useBuyTicketsModal({ onCloseCallback,  onSuccessCallback, modalData: _modalData });
    const diceRollModal = useDiceRollModal({ lottery, onCloseCallback, onSuccessCallback, modalData: _modalData });

    if(lottery?.type == "normal"){
        return buyTicketsModal;
    }else if(lottery?.type == "dice"){
        return diceRollModal;
    }else{
        throw new Error("Invalid type");
    }
}

export default useTicketsModalByType;
