import { useEffect, useRef, useState } from "react";
import BattleTimer from "../battle/BattleTimer";
import { Button, Card, Chip, Link, Tooltip } from "@nextui-org/react";
import AmountChangeNotifier from "../small/AmountChangeNotifier";
import InfoDuelTooltip from "../info-tooltip/InfoDuelTooltip";
import VSCNCoin from "../small/VSCNCoin";
import { useSelector } from "react-redux";
import { yellowGlow } from "../../helpers/glows";
import ToolTipFix from "../extensions/TooltipFix";
import UserChip from "../small/UserChip";
import sounds from "../../services/sounds";
import DuelTurnTimer from "./DuelTurnTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

// import "../../css/duel.css"; // Changed from lottery.css



export default function DuelInside({ duel, small, participateInDuelCallback, className = "" }: any) {
    // const { id, type, name, player1, player2, typeName, scoreToWin, waitLength, img, amount, wallet, tokenWallet } = duel;

    const [shouldShake, setShouldShake] = useState(false);

    // const [finished, setFinished] = useState(duel.finished || false);
    const [winner, setWinner] = useState(duel.winner || 0);

    const [loadingJoin, setLoadingJoin] = useState(false);

    // const { publicKey } = useSelector((state: any) => state.walletBalance);
    const { user } = useSelector((state: any) => state.userData);

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (duel.finished) {
            // setFinished(duel.finished);
            setCollapsed(false);
        }
    }, [duel.finished]);
    useEffect(() => {
        if (duel.winner) setWinner(duel.winner);
    }, [duel.winner]);

    // useEffect(() => {
    //     setShouldShake(true);
    //     const timer = setTimeout(() => setShouldShake(false), 300); 
    //     return () => clearTimeout(timer); 
    // }, [duel.amount]); 

    const handleTimeEnd = () => {
        // setFinished(true);
        duel.finished = true;
    }

    const prevPlayer2PresentRef = useRef(!!duel.player2);

    useEffect(() => {
        if (small) return;
        const prevPlayer2Present = prevPlayer2PresentRef.current;
        const currentPlayer2Present = !!duel.player2;

        if (!prevPlayer2Present && currentPlayer2Present) {
            if (currentPlayer2Present == user?.wallet) {
                // console.log('You appeared');
                // sounds.playYouJoinedDuel();
            } else {
                // console.log('Player 2 appeared');
                sounds.playOpponentJoined();
            }
        }
        // Update the ref to the current value for the next render
        prevPlayer2PresentRef.current = currentPlayer2Present;
    }, [duel.player2, small, user]);



    return (<>
        <div className={`w-full transition-[max-height] ease-in-out duration-500 overflow-hidden `}
            style={{
                // transition: "max-height 0.5s ease-in-out",
                maxHeight: collapsed ? "5rem" : "1000px",
            }}>
            {small && <div className="text-tiny opacity-70 m-0 float-left">#{duel?.id}</div>}
            {!small && <Link showAnchorIcon className="text-tiny opacity-70 m-0 float-left cursor-pointer text-white"
                onPress={() => {
                    let url = window.location.href;
                    let title = `Join ${duel?.typeName} duel ${duel.name} on vscn.bet!`;
                    if (navigator.share) {
                        navigator.share({ url, title });
                    } else {
                        navigator.clipboard.writeText(`${title} ${url}`);
                    }
                }}>#{duel?.id}</Link>
            }

            {/* {!small && <div className="text-tiny opacity-70 m-0 float-left cursor-pointer"
                onClick={() => {
                    navigator.share({
                        url: window.location.href,
                        title: `Join ${duel?.typeName} duel ${duel.name} on vscn.bet!`,
                    });
                }}>#{duel.id}</div>
            } */}

            {!small && <InfoDuelTooltip type={duel.type} />}

            {duel.finished ?
                <div className={`${small ? 'text-md' : 'text-xl'} mx-8 text-center p-2`}>Duel finished! {!winner && <><br />Calculating outcome...</>}</div>
                :
                (duel.endsAt && <BattleTimer battleData={duel} onTimeEnd={handleTimeEnd} small={small} />)
            }

            <div className="relative animWrp m-0 gap-1 " >

                <div className="flex flex-row justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <div className="mx-auto text-lg font-bold" style={{ fontFamily: "Barmo" }}>{duel.typeName}</div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <div className={`flex flex-col justify-center items-center max-w-80 max-h-80  ${shouldShake ? 'shake' : ''}`}>
                        <div className="flex items-center max-w-[70%] max-h-[70%] min-h-16 m-2">
                            <img src={duel.img} alt={duel.typeName} className={`max-h-16 ${shouldShake ? 'yellowGlow' : ''}`} />
                        </div>



                        <div className="p-2 bg-background/30 rounded-lg text-center" style={{ fontFamily: "Barmo", marginTop: "0", zIndex: 5, }}>
                            {/* <AmountChangeNotifier style={yellowGlow} amount={duel.amount} className="text-2xl" />  */}
                            <div className="text-2xl lg:text-4xl "  >
                                <span style={{ ...yellowGlow, zIndex: 0 }}>{Intl.NumberFormat().format(Math.round(duel.amount))}</span>
                                {/* <VSCNCoin /> */}
                            </div>
                            <div className="text-xl lg:text-2xl" style={yellowGlow}>VSCN </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <div className="m-2 text-sm overflow-hidden bg-background/30 p-2 rounded-lg text-center" style={{ fontFamily: "Poppins" }}>{duel.name}</div>
                </div>
                <div className="flex flex-col justify-center items-center text-md">
                    <div className="text-sm">First to {duel.scoreToWin} win{duel.scoreToWin !== 1 && 's'}. Turn time: {`${Math.floor(duel.waitLengthTurn)}sec`} </div>

                    <div className="mt-4 mb-2 flex justify-between items-center w-full items-center gap-2">
                        <div className="flex justify-end flex-1">
                            {/* <ToolTipFix showArrow={true} content={duel.player1} triggerClassName={"text-md"} >
                                <UserChip wallet={duel.player1} size="md" />
                            </ToolTipFix> */}
                            <ToolTipFix showArrow={true} content={duel.player1}>
                                <Chip color={duel.player1 === winner ? "success" : "primary"} size={small ? "md" : "lg"} className="text-white">{duel.player1?.substring(0, 6)}</Chip>
                            </ToolTipFix>
                        </div>
                        <div className="flex justify-center flex-grow-0 mx-auto">
                            <p className="text-md"><VSCNCoin /></p>
                        </div>


                        <div className="flex justify-start flex-1">
                            {/* <ToolTipFix showArrow={true} content={duel.player2} triggerClassName={"text-md"} >
                                <UserChip wallet={duel.player2} size="md" />
                            </ToolTipFix> */}

                            <ToolTipFix showArrow={true} content={duel.player2 ? duel.player2 : "Waiting for an opponent"}>
                                <Chip color={duel.player2 === winner ? "success" : "primary"} size={small ? "md" : "lg"} className={`text-white `} >{duel.player2 ? duel.player2?.substring(0, 6) : "Waiting..."}</Chip>
                            </ToolTipFix>
                        </div>


                    </div>

                </div>

            </div>

            {!small && !duel.finished && !duel.player2 && duel.player1 != user?.wallet &&
                <Button
                    className={`name my-4 text-xl w-auto`}
                    isDisabled={duel.finished || duel.player2 || loadingJoin}
                    isLoading={loadingJoin}
                    style={{ fontFamily: "Barmo" }}
                    variant="solid" color="primary"
                    onClick={async (ev) => {
                        ev.preventDefault();
                        if (!participateInDuelCallback) return;
                        setLoadingJoin(true);
                        await participateInDuelCallback({ name: duel.name, img: duel.img, type: duel.type, toWallet: duel.wallet, toTokenWallet: duel.tokenWallet });
                        setLoadingJoin(false);

                    }}>
                    Join Duel
                </Button>
            }

            {!small && duel.finished && winner != 0 && (duel.player1 === user?.wallet || duel.player2 === user?.wallet) && winner != user?.wallet &&
                <div className={`text-center rounded-lg mt-2 p-2 bg-danger/100`}>
                    <div style={{ fontFamily: "Barmo" }}>You lost!</div>
                    <div className="text-sm">Pathetic. Go play again!</div>
                </div>
            }

            {!small && duel.finished && winner != 0 &&
                //   <div className={`text-center rounded-lg mt-2 p-2 ${winner === publicKey ? 'bg-success/60' : 'bg-primary/100'} `}>
                <div className={`text-center rounded-lg mt-2 p-2 ${winner === user?.wallet ? "bg-success/70" : "bg-primary/70"} `}>
                    <div style={{ fontFamily: "Barmo" }}>{winner === user?.wallet ? "You're The Winner!" : 'Winner'}</div>
                    <div className="text-tiny">{winner}</div>

                    {duel.expired && (
                        <div className="text-md mt-4">
                            {duel.playerTimedOut === 1 || duel.playerTimedOut === 2
                                ? `Player ${duel.playerTimedOut} failed to take action.`
                                : "Duel expired."}
                        </div>
                    )}
                </div>
            }


        </div>

        {!small && duel?.player2 &&
            <Button size="sm" className="bg-transparent/10 w-full mt-2 mb-4"
                onPress={() => setCollapsed(!collapsed)}
                startContent={<FontAwesomeIcon className={`transition-all ${collapsed ? "rotate-180" : ""}`} icon={faChevronUp} />}
                endContent={<FontAwesomeIcon className={`transition-all ${collapsed ? "rotate-180" : ""}`} icon={faChevronUp} />}>{collapsed ? "Expand" : "Collapse"}
            </Button>
        }
    </>
    );
}