import { useEffect, useState } from "react";
import "../../css/dice-select.css"


export default function DiceSelect({ className = "", onSelect, disabled = false }) {
    const [chosenDice, setChosenDice] = useState(0);

    useEffect(() => {
        onSelect(chosenDice);
    }, [chosenDice]);

    return (
        <div className={`dice-select-container flex justify-center gap-1 m-1 ${!disabled ? 'diceSelectionEnabled' : ''} `} style={{ fontSize: "7px" }}>
            <div className={`dice ${chosenDice == 1 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(1)} >
                <div className='side one'>
                    <div className="dot one-1"></div>
                </div>
            </div>
            <div className={`dice ${chosenDice == 2 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(2)} >
                <div className='side two'>
                    <div className="dot two-1"></div>
                    <div className="dot two-2"></div>
                </div>
            </div>
            <div className={`dice ${chosenDice == 3 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(3)} >
                <div className='side three'>
                    <div className="dot three-1"></div>
                    <div className="dot three-2"></div>
                    <div className="dot three-3"></div>
                </div>
            </div>
            <div className={`dice ${chosenDice == 4 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(4)} >
                <div id="dice-one-side-four" className='side four'>
                    <div className="dot four-1"></div>
                    <div className="dot four-2"></div>
                    <div className="dot four-3"></div>
                    <div className="dot four-4"></div>
                </div>
            </div>
            <div className={`dice ${chosenDice == 5 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(5)} >
                <div id="dice-one-side-five" className='side five'>
                    <div className="dot five-1"></div>
                    <div className="dot five-2"></div>
                    <div className="dot five-3"></div>
                    <div className="dot five-4"></div>
                    <div className="dot five-5"></div>
                </div>
            </div>
            <div className={`dice ${chosenDice == 6 ? 'selected' : ''}`} onClick={() => !disabled && setChosenDice(6)} >
                <div id="dice-one-side-six" className='side six'>
                    <div className="dot six-1"></div>
                    <div className="dot six-2"></div>
                    <div className="dot six-3"></div>
                    <div className="dot six-4"></div>
                    <div className="dot six-5"></div>
                    <div className="dot six-6"></div>
                </div>
            </div>
        </div>
    );
}