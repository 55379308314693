import { useEffect, useState } from "react";
import { Button, Card, Link, Spinner } from "@nextui-org/react";
import { socket } from "../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { setDuelsAction } from "../store/actions/reducerActions";
import DuelInside from "./duel/DuelInside";
import useCreateDuelModal from "../hooks/useDuelCreateModal";
import useDoTransaction from "../hooks/useDoTransaction";
import { useLetsTalk } from "../hooks/useLetsTalk";
import { whiteGlow } from "../helpers/glows";
import { useNavigate } from "react-router-dom";
import { CardNice } from "./small/CardNice";


export default function CurrentDuels({ className, limit = 6, isPage }: any) {
    const dispatch = useDispatch();

    const [duelsLoading, setDuelsLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { duels } = useSelector((state: any) => state.duelsData);
    const { user } = useSelector((state: any) => state.userData);

    const [displayLimit, setDisplayLimit] = useState(9999);


    const { doTransaction } = useDoTransaction();
    const { doGet } = useLetsTalk();

    const navigate = useNavigate();


    useEffect(() => {
        const updateDisplayedDuels = () => {
            if (isPage) return;
            const screenWidth = window.innerWidth;
            let count;

            if (screenWidth >= 1536) { // 2xl breakpoint (1536px)
                count = 6;
            } else if (screenWidth >= 1280) { // xl breakpoint (1280px)
                count = 5;
            } else if (screenWidth >= 1024) { // lg breakpoint (1024px)
                count = 4;
            } else if (screenWidth >= 768) { // md breakpoint (768px)
                count = 3;
            } else { // sm and below
                count = 2;
            }

            setDisplayLimit(count);
        };

        updateDisplayedDuels();
        window.addEventListener('resize', updateDisplayedDuels);

        return () => {
            window.removeEventListener('resize', updateDisplayedDuels);
        };
    }, [duels]);




    async function loadDuels() {
        if (duelsLoading) return;
        setDuelsLoading(true);

        doGet(`duel/get-running?limit=${limit}`).then((ret) => {
            if (ret.ok) {
                dispatch(setDuelsAction(ret.data));
                setError(undefined);
            } else {
                setError(ret.error || "An error has occurred.");
            }
            setDuelsLoading(false);
        }
        ).catch((error) => {
            console.error(error);
            setError(error?.toString() || JSON.stringify(error));
            setDuelsLoading(false);
        });

    }


    useEffect(() => {
        loadDuels();
        socket.io.on("reconnect", () => { loadDuels() });
        return () => {
            socket.io.off("reconnect", () => { loadDuels() })
        };
    }, []);

    useEffect(() => {
        loadDuels();
    }, [user]);


    const { ModalComponent, openModal, closeModal } = useCreateDuelModal({
        onCloseCallback: () => null,
        onSuccessCallback: async ({ duel }) => {
            // console.log("CREATING DUEL", duel);
            let transData = {
                amount: duel.amount,
                toWallet: duel.wallet,
                duelId: duel.id,
            }
            // return;
            let res = await doTransaction({ url: "duel/join", transData, successMessage: "Duel created!" });

            if (res) {
                // console.log("after useCreateDuelModal doTransaction result", res);
                if (res.ok && res.data?.duel?.type && res.data?.duel?.id) {
                    navigate(`/duel/${res.data.duel.type}/${res.data.duel.id}`);
                }
                // return true;
            } else {

            }
        },
        modalData: {
            betData: {},
        }
    })


    return (<>
        <div className={`m-auto ${className || ''}`}>


            {isPage &&
                <><Button className="mt-2" color="primary" variant="solid" size="lg" onClick={(e) => { openModal({}) }}>Create a Duel</Button>
                    <br />
                    <br />
                </>}

            {duelsLoading && <Spinner size="lg">Loading...</Spinner>}
            {!duelsLoading && (!duels || duels?.length < 1) && !error && <div className="text-lg">No duels right now. Go create one!</div>}

            {!duelsLoading && error && <div>
                <div className="text-red-400">{error}</div>
                <br />
                <Button color="primary" onClick={() => loadDuels()}>Retry</Button>
            </div>}

            <div className={`m-auto grid grid-cols-1 ${duels?.length > 1 ? 'grid-cols-2' : ''} ${duels?.length > 2 && 'md:grid-cols-3'} ${duels?.length > 4 && 'lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'} gap-1 md:gap-2 items-start`}>
                {duels?.length > 0 && duels?.slice(0, displayLimit).map((duel) => {
                    return (
                        <Link key={duel?.id} href={`/duel/${duel.type}/${duel.id}`} disableAnimation={true} className="no-hover-opacity hover:bg-background/10 rounded-xl inline-block"
                        >
                            <CardNice
                                finished={duel?.finished}
                                style={{ ...((duel?.player1 === user?.wallet || duel?.player2 === user?.wallet) ? whiteGlow : {}) }}
                            >
                                <DuelInside duel={duel} small={true} />

                            </CardNice>
                        </Link>
                    );
                })}
            </div >

            {!isPage && <Button as={Link} color="primary" className="mt-4" variant="solid" href="/duels">More duels</Button>}

            {ModalComponent}
        </div>
    </>
    );
}