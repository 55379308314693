import { createContext, useContext, useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';
import useSignMessage from './useSignMessage';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setUserData } from '../store/actions/reducerActions';


// import { useWallet } from "../hooks/useWallet";

const JWT_STORAGE_KEY = "VSCN_JWT"; //ALSO CHANGE IN useLetsTalk.tsx !!!!!

interface AuthContextValue {
  getJWT: () => string | null;
  // requestJWT: (walletId: any, signedMessage: any) => Promise<any>;
  // onWalletDisconnect: () => Promise<boolean>;
  // onWalletConnected: (publicKey: PublicKey) => Promise<boolean>;
  // requestJWT: (walletId: any, signedMessage: any) => Promise<any>;
  // clearJWT: () => Promise<boolean>;
  // checkJWT: (jwt: string | null) => Promise<boolean>;
  // readJWT: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

// Create a provider component
export function AuthProvider({ children }) {
  const [jwt, setJwt] = useState<string | null>(null);
  const { signIn, signMessage, connect, connected, connecting, disconnect, disconnecting, publicKey, select, wallet, wallets } = useWallet();

  const { doSignMessage } = useSignMessage();

  const dispatch = useDispatch();



  useEffect(() => {
    init();
  }, []);

  const init = () => {
    // console.log("[Auth] init called");
    let rdjwt = readJWT();
    if (rdjwt) {
      // console.log("rdjwt", rdjwt, wallet);

    }
  }

  useEffect(() => {
    // console.log("[Auth] useEffect called", publicKey, connected, connecting, disconnecting);
    // if (!connected && !connecting && !publicKey) {
    //   console.log("[Auth] NOT CONNECTED, not connecting, no publickey. Remove jwt ");
    //   clearJWT();
    // }
  }, [connecting, connected, disconnecting, publicKey, disconnect]);

  const checkKey = async (publicKey) => {
    // console.log("[Auth] checkKey called", publicKey, jwt, connected, connecting, disconnecting);


    if (!connected) {
      // console.log("[checkKey] !connected", connected);
      return false;
    }

    if (disconnecting) {
      // console.log("[checkKey] disconnecting", disconnecting);
      return false;
    }

    if (!jwt) {
      let rjwt = readJWT();
      // console.log("NO JWT, readJWT", rjwt);
    }
    if (jwt) {
      try {
        let jwtDecoded = jwtDecode<{ wallet, exp }>(jwt); //JSON.parse(jwt)?.publicKey;
        // console.log("jwtDecoded", jwtDecoded);

        if (publicKey?.toString() !== jwtDecoded.wallet) {
          // console.log("BAD saved jwt, clearing...", publicKey, jwtDecoded.wallet);s
          // signInAndSendSignedMessage();
          await clearJWT();
        } else if (jwtDecoded.exp < (Date.now() / 1000)) {
          console.log("JWT expired, clearing...", jwtDecoded.exp, Date.now() / 1000);
          await clearJWT();
        } else {
          return true;
        }
      } catch (e) {
        console.error("JWT decode error", e);
        await clearJWT();
        return checkKey(publicKey);
      }
    } else {
      // console.log("NO JWT");
    }


    try {
      let res = await walletSignIn(publicKey);
      if (res) {
        return true;
      }
    } catch (error: any) {
      // toast.error(error.message ? `${error.name}: ${error.message}` : error.name);
      disconnect();
      return false;
    }

  }

  useEffect(() => {
    if (disconnecting) {
      console.log("Disconnecting Wallet!", disconnecting);
      clearJWT();
    }
  }, [disconnecting]);

  const walletSignIn = async (publicKey) => {
    if (!signMessage) {
      console.error('No signMessage');
      toast.error("Sign message function not available! Probably your wallet doesn't support it. Please use another wallet or contact support.");
      clearJWT();
      return null;
    }

    const timestamp = Date.now();
    const message = `Sign in to vscn.bet: ${timestamp}`
    // const encodedMessage = new TextEncoder().encode(message);

    // let ref = document.cookie?.match(/referral=(\w+)/);
    let ref = document.cookie.match(/referral=([^;]+)/)?.[1];
    let res: any = await doSignMessage({ url: "auth/sign-in", data: message, toSendData: { ref }, successMessage: "Sign in successful!" });

    // console.log("doSignMessage", res);

    if (res && res.ok && res.token) {
      saveJWT(res.token);
      return true;
    } else {
      console.error('No res');
      // toast.error("Error while signing message! No response.");
      disconnect();
      clearJWT();
      return null;
    }

    return true;
  }

  useEffect(() => {
    // console.log("[Auth] useEffect called", publicKey, connected, connecting, disconnecting, jwt);
    if (!publicKey || !connected || connecting) return;
    checkKey(publicKey);

    // if (publicKey && jwt) {
    //   const jwtPublicKey = JSON.parse(jwt).publicKey;
    //   if (publicKey !== jwtPublicKey) {
    //     signInAndSendSignedMessage();
    //   }
    // }
  }, [connected, connecting, publicKey, jwt]);

  // const signInAndSendSignedMessage = async () => {
  //   const input = {
  //       domain: window.location.host,
  //       address: publicKey ? publicKey.toBase58() : undefined,
  //       statement: 'Please sign in.',
  //   };
  //   if(!signIn) {
  //       toast.error("Sign in function not available! Probably your wallet doesn't support it.");
  //       return false;
  //   }

  //   const signInResult = await signIn(input);

  //   if (!verifySignIn(input, signInResult)) throw new Error('Sign In verification failed!');

  //   if (signInResult) {
  //     await requestJWT(publicKey, signInResult);
  //   }
  // }

  // const requestJWT = async (walletId, signedMessage) => {
  //   try {
  //     let res = await LetsTalk.post("auth/request-jwt", { walletId, signedMessage });
  //     if (res?.ok && res?.jwt) {
  //       setJwt(res.jwt);
  //       await saveJWT(res.jwt);
  //     } else {
  //       console.error("[AuthSingleton] requestJWT error: ", res?.error);
  //     }
  //     return res;
  //   } catch (e) {
  //     console.error("[AuthSingleton] requestJWT error: ", e);
  //     return { ok: false, error: e };
  //   }
  // }

  const saveJWT = (jwt) => {
    // console.log("Saving JWT", jwt);
    // setJwt(JSON.stringify(jwt));
    setJwt(jwt);
    // await localStorage.setItem("VSCN_JWT", JSON.stringify(jwt));
    localStorage.setItem(JWT_STORAGE_KEY, jwt);
    let jwtDecoded = jwtDecode<{ wallet, exp, ref }>(jwt);

    dispatch(setUserData({ user: { ...jwtDecoded }, jwt, jwtLoaded: true }));
    return true;
  }

  const clearJWT = () => {
    // console.log("CLEARING JWT !!! CLEARING JWT !!!!");
    setJwt(null);
    dispatch(setUserData({ user: null, jwt: null, jwtLoaded: true }));
    localStorage.removeItem(JWT_STORAGE_KEY);
    return true;
  }

  // const checkJWT = async (jwt) => {
  //   //TODO: check if jwt is valid
  //   if (jwt) {
  //     return true;
  //   } else {
  //     await clearJWT();
  //     return false;
  //   }
  // }

  const readJWT = () => {
    let jwt = localStorage.getItem(JWT_STORAGE_KEY);
    setJwt(jwt);
    if (!jwt) {
      // console.log("no jwt found");
      dispatch(setUserData({ user: null, jwt: null, jwtLoaded: true }));
      return null;
    }
    let jwtDecoded = jwtDecode<{ wallet, exp }>(jwt);
    dispatch(setUserData({ user: { ...jwtDecoded }, jwt: jwt, jwtLoaded: true }));
    // console.log("Read JWT", jwt);

    // if (stored) {
    //   let valid = await checkJWT(jwt);
    //   if (valid) {
    //     setJwt(stored);
    //   } else {
    //     setJwt(null);
    //   }
    // }

    return jwt;
  }

  const getJWT = () => {
    return jwt;
  }

  const onWalletDisconnect = async () => {
    // console.log("[Auth] onWalletDisonnect called");
    await clearJWT();
    return true;
  }

  // const onWalletConnected = async (publicKey: PublicKey) => {
  //   console.log("[Auth] onWalletConnected called", publicKey);
  //   //check if jwt is valid
  //   if (!jwt) return false;

  //   let jwtPublicKey = JSON.parse(jwt).publicKey;
  //   if (publicKey !== jwtPublicKey) {
  //     // signInAndSendSignedMessage();
  //     await clearJWT();
  //     return false;
  //   }

  //   return true;
  // }

  // The value passed to AuthContext.Provider will be available to all components in the tree
  return (
    // requestJWT, onWalletConnected, onWalletDisonnect
    <AuthContext.Provider value={{ getJWT }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}