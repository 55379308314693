
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { AccountLayout, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicKey } from '@solana/web3.js';

import { useSelector } from 'react-redux';
import { Spinner } from '@nextui-org/react';

//REAL: 4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r
//DEV: 9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL
// const VSCN_MINT = new PublicKey('9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL');

// const TOKEN_ADDRESS = new PublicKey('9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL'); //👈 Replace with your wallet address
// const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
//     'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
// );

// function findAssociatedTokenAddress(
//     walletAddress: PublicKey,
//     tokenMintAddress: PublicKey
// ): PublicKey {
//     return PublicKey.findProgramAddressSync(
//         [
//             walletAddress.toBuffer(),
//             TOKEN_PROGRAM_ID.toBuffer(),
//             tokenMintAddress.toBuffer(),
//         ],
//         SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
//     )[0];
// }

// async function getTokenBalanceWeb3(connection, tokenAccount) {
//     const info = await connection.getTokenAccountBalance(tokenAccount);
//     if (info.value.uiAmount == null) throw new Error('No balance found');
//     console.log('Balance (using Solana-Web3.js): ', info.value.uiAmount);
//     return info.value.uiAmount;
// }

// export const WalletBalance: FC = () => {
//     const { connection } = useConnection();
//     const { publicKey, sendTransaction } = useWallet();

//     const [balanceVSCN, setBalanceVSCN] = useState<any>(0);
//     const [balanceSOL, setBalanceSOL] = useState<any>(0);

//     const fetchBalance = useMemo(() => async () => {
//         try {
//             if (publicKey) {
//                 const balance = await connection.getBalance(publicKey, "confirmed");
//                 setBalanceSOL( ( balance / 1e9 ).toFixed(3)  ); // Convert lamports to SOL

//                 let vscnBalance = 0;//BigInt(0);

//                 const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
//                     publicKey, { programId: TOKEN_PROGRAM_ID } //, mint: VSCN_MINT
//                 );
//                 // const token2022Accounts = await connection.getParsedTokenAccountsByOwner(
//                 //     publicKey, { programId: TOKEN_2022_PROGRAM_ID, mint: VSCN_MINT }
//                 // );


//                 // const accountsWithProgramId = [...tokenAccounts.value, ...token2022Accounts.value];
//                 //   .map(
//                 //     ({ account, pubkey }) =>
//                 //     // {
//                 //     //     console.log("Tokens", pubkey.toString(), AccountLayout.decode(account.data).amount.toString() );
//                 //     // }
//                 //       ({
//                 //         account,
//                 //         pubkey,
//                 //         data: AccountLayout.decode(account.data)
//                 //         // programId: AccountLayout.decode(account.data)?.program === 'spl-token' ? TOKEN_PROGRAM_ID : TOKEN_2022_PROGRAM_ID,
//                 //       })
//                 //   );
//                 console.log("tokenAccounts", tokenAccounts);

//                 tokenAccounts.value.map(acc => {
//                     console.log("found account ", acc);
//                     if (new PublicKey(acc.account.data.parsed.info.mint).equals(VSCN_MINT)) {
//                         vscnBalance = acc.account.data.parsed.info.tokenAmount.uiAmount
//                         console.log("VSCN found: ",vscnBalance );
//                         // vscnBalance = acc.account.data.parsed.info.tokenAmount.amount / acc.account.data.parsed.info.tokenAmount.decimals;
//                         // vscnBalance = Number.parseFloat(acc.data.amount.toString()) / 1e9;
//                         setBalanceVSCN( vscnBalance.toFixed(1) );
//                     }
//                     // console.log(new PublicKey(acc.data.mint).equals( VSCN_MINT ) , acc.pubkey.toString(), acc.data.amount.toString());
//                 });


//                 // let accoutns = await connection.getTokenAccountBalance(publicKey);
//                 // console.log(accoutns);
//                 // let bal = await getTokenBalanceWeb3(connection, TOKEN_ADDRESS);


//                 // const tokenAccounts = await connection.getTokenAccountsByOwner(
//                 //     VSCN_MINT,
//                 //     {
//                 //         programId: TOKEN_PROGRAM_ID,
//                 //     }
//                 // );

//                 // console.log("Token                                         Balance");
//                 // console.log("------------------------------------------------------------");
//                 // tokenAccounts.value.forEach((tokenAccount) => {
//                 //     const accountData = AccountLayout.decode(tokenAccount.account.data);
//                 //     console.log(`${new PublicKey(accountData.mint)}   ${accountData.amount}`);
//                 //     if(accountData.mint.toString() === VSCN_MINT.toString()) {
//                 //         console.log("VSCN found: ", accountData.amount.toString());
//                 //         vscnBalance = Number.parseFloat(accountData.amount.toString()) / 1e9;
//                 //         setBalanceVSCN(vscnBalance);
//                 //     }
//                 // })


//                 // const vscnAddress = findAssociatedTokenAddress(publicKey, new PublicKey(VSCN_MINT));

//                 // console.log("vscnAddress", vscnAddress);

//                 // const vscnBal = await connection.getTokenAccountBalance(vscnAddress);
//                 // console.log("vscnBal", vscnBal);
//                 // // SPL Token balance
//                 // const token = new Token(
//                 //     connection,
//                 //     new PublicKey(VSCN_MINT), // Replace with your token's mint address
//                 //     TOKEN_PROGRAM_ID,
//                 //     publicKey // payer
//                 // );
//                 // const tokenAccountInfo = await token.getAccountInfo(publicKey);
//                 // setBalanceVSCN(vscnBal / 1e9); // Convert to token decimals
//             }
//         } catch (error) {
//             console.error("ERROR", error);
//         }
//     }, [connection, publicKey]);

//     useEffect(() => {
//         fetchBalance();
//     }, [fetchBalance]);

//     return (<>
//         Balance: {balanceVSCN} VSCN, {balanceSOL} SOL
//     </>
//     );
// };


const WalletBallanceHOC: FC = () => {
    // @REDUXHELP
    // CIA PASIEMI. is reducers/index.js objekto, state.pavadinimas buna tavo reducerio values'ai
    const { balanceVSCN, balanceSOL, gettingBalance } = useSelector((state: any) => state.walletBalance);

    return (<>
        {gettingBalance && <Spinner size="sm" color='white' classNames={{wrapper: "w-3 h-3"}} />} Balance: {Intl.NumberFormat().format((balanceVSCN))} VSCN, {Intl.NumberFormat().format((balanceSOL))} SOL  
    </>
    );
};

export const WalletBalance = React.memo(WalletBallanceHOC);