import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function BetsPage() {

    const [betList, setBetList] = useState<any[]>([]);
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();

    const load = () => {
        setLoading(true);

        doGet("bets/get-bets").then((ret) => {
            if (ret.ok) {
                setBetList(ret.data);
            } else {
                setError(ret.error || "An error has occurred.");
            }
            // console.log("Got bets", data);
            setLoading(false);
        }
        ).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (betList.length > 0) return;
        load();
    }, [publicKey]);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Your bets</h2>

        <div>
            <TransparentTable
                headers={['Battle', "VSCN", "Side", "TX"]}
                label="Your bets"
                isLoading={loading}
                emptyContent={"No bets found."}
                error={error}
                load={load}
                data={
                    betList.map((bet, idx) => {
                        return ([
                            <><Link href={`/battle/${bet.Battle?.id}`} size="sm"  >#{bet.Battle?.id}</Link> &nbsp;
                                {bet.Battle?.name1} vs {bet.Battle?.name2}</>,
                            Intl.NumberFormat().format(bet.amount),
                            bet.side === 1 ? bet.Battle?.name1 : bet.Battle?.name2,
                            <Link href={`https://solscan.io/tx/${bet.transactionId}`} target="_blank" size="sm" className="after:content-['_↗']" >
                                Open
                            </Link>
                        ]);
                    })
                }
            />


            <div className="text-sm m-4">
                Showing up to 200 latest bets.
            </div>
        </div>
    </div >
    );
}