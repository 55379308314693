import ReactGA from "react-ga4";

const JWT_STORAGE_KEY = "VSCN_JWT"; //ALSO CHANGE IN useAuth.tsx and LetsTalk and AnalSingleton !!!!!


class AnalSingleton {
    _wallet: any;
    public set wallet(wallet: any) {
        this._wallet = wallet;
        ReactGA.set({ userId: wallet?.publicKey });
    }
    public get wallet() {
        return this._wallet;
    }

    constructor() {

        ReactGA.initialize([
            {
                trackingId: "G-E8LPJ3DLVJ",
                gaOptions: {}, // optional
                gtagOptions: {}, // optional
            },
        ]);

        let ref = document.cookie.match(/referral=([^;]+)/)?.[1];
        if(ref){
            ReactGA.set({ referrer: ref });
        }
    }

    public getUserBrowserInfo() {
        const info = {
            userAgent: navigator.userAgent,
            languages: navigator.languages.join("; "),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            wallet: this.wallet,
        };
        const jsonString = JSON.stringify(info);
        const base64String = btoa(unescape(encodeURIComponent(jsonString)));
        return base64String;
    }

    public async pageView(page: string, title: string) {
        let jwt = localStorage.getItem(JWT_STORAGE_KEY);

        try {
            ReactGA.send({ hitType: "pageview", page, title });

            let nfo = this.getUserBrowserInfo();

            fetch(`${process.env.REACT_APP_API_URL}anal/penetrate`, {
                method: "POST",
                body: JSON.stringify({ p: page, nfo }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...(jwt ? { 'Authorization': `Bearer ${jwt}` } : {})
                },
            }).then((response) => response.json())
                .catch((error) => { console.error(error) });

        } catch (e) {
            console.error("[ANAL] Error sending page view", page, title, e);
        }
    }

    public async event(e: { category: string, action: string, label?: string, value?: number, nonInteraction?: boolean }) {
        let jwt = localStorage.getItem(JWT_STORAGE_KEY);

        try {
            ReactGA.event({
                category: e.category,
                action: e.action,
                label: e.label, // optional
                value: e.value, // optional, must be a number
                nonInteraction: e.nonInteraction, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
            });

            let nfo = this.getUserBrowserInfo();

            fetch(`${process.env.REACT_APP_API_URL}anal/lick`, {
                method: "POST",
                body: JSON.stringify({ c: e.category, a: e.action, l: e.label, v: e.value, i: e.nonInteraction, nfo }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...(jwt ? { 'Authorization': `Bearer ${jwt}` } : {})
                },
            }).then((response) => response.json())
                .catch((error) => { console.error(error) });


        } catch (e) {
            console.error("[ANAL] Error sending event", e);
        }

    }


}

const anal = new AnalSingleton();
export default anal;