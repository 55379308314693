import { Adapter, WalletError } from "@solana/wallet-adapter-base";
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { type SolanaSignInInput } from '@solana/wallet-standard-features';
import { verifySignIn } from "@solana/wallet-standard-util";
import { ConnectionProvider, WalletProvider, useWallet } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { AuthProvider, useAuth } from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/actions/reducerActions";



export const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // const { autoConnect } = useAutoConnect();

    // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
    const network = process.env.REACT_APP_RPC_ENDPOINT!;
    const endpoint = network;

    // const {onWalletDisconnect} = useAuth();


    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/anza-xyz/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            // new UnsafeBurnerWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    const dispatch = useDispatch();


    const onError = useCallback(
        (error: WalletError, adapter?: Adapter) => {
            toast.error(error.message ? `${error.name}: ${error.message}` : error.name);
            console.error(error, adapter);
            if (error.name == "WalletDisconnectedError") {
                // onWalletDisconnect();
                // alert("Disconnect wallet now!");
                console.log("WalletDisconnectedError, clearing jwt" );
                localStorage.removeItem("VSCN_JWT");
                dispatch(setUserData({ user: null, jwt: null, jwtLoaded: true }));

            }
        },
        [toast]
    );

    // const { onWalletConnected, onWalletDisonnect, requestJWT } = useAuth();

    // const autoSignIn = useCallback(async (adapter: Adapter) => {
    //     console.log("autoSignIn called", adapter);
    //     //check if jwt is valid

    //     if (!adapter.publicKey) {
    //         // toast.error("An error has occured!");
    //         return false;
    //     }

    //     // if (!('signIn' in adapter)) return true;
    //     if (!('signIn' in adapter)) {
    //         toast.error("Sign in function not available! Probably your wallet doesn't support it. Please use another wallet or contact support.");
    //         return false;
    //     }
    //     // if (!('signMessage' in adapter)) {
    //     //     toast.error("Sign message function not available! Probably your wallet doesn't support it. Please use another wallet or contact support.");
    //     //     return false;
    //     // }


    //     adapter.on('disconnect', () => {
    //         console.log("autoSignIn disconnect");
    //         onWalletDisonnect();
    //     });

    //     // if (!adapter.publicKey) {
    //     //     toast.error("An error has occured!");
    //     //     return false;
    //     // }

    //     let hasSavedJWT = await onWalletConnected(adapter.publicKey);
    //     if (hasSavedJWT) {
    //         return true;
    //     }

    //     const input: SolanaSignInInput = {
    //         domain: window.location.host,
    //         address: adapter.publicKey ? adapter.publicKey.toBase58() : undefined,
    //         statement: 'Please sign in.',
    //     };
    //     const output = await adapter.signIn(input);
    //     console.log("autoSignIn output", output);

    //     if (!verifySignIn(input, output)) throw new Error('Sign In verification failed!');

    //     let res = await requestJWT(adapter.publicKey, output);
    //     console.log("autoSignIn requestJWT", res);

    //     return true;

    //     if (!res?.ok) {
    //         if ('disconnect' in adapter) {
    //             await adapter.disconnect();
    //         }
    //     }

    //     return false;
    // }, [onWalletConnected]);



    // const autoSignIn = useCallback(async (adapter: Adapter) => {
    //     console.log("autoSignIn called", adapter);
    //     //check if jwt is valid

    //     // if (!adapter.publicKey) {
    //     //     // toast.error("An error has occured!");
    //     //     return false;
    //     // }

    //     // if (!('signIn' in adapter)) return true;
    //     if (!('signIn' in adapter)) {
    //         toast.error("Sign in function not available! Probably your wallet doesn't support it. Please use another wallet or contact support.");
    //         return false;
    //     }
    //     // if (!('signMessage' in adapter)) {
    //     //     toast.error("Sign message function not available! Probably your wallet doesn't support it. Please use another wallet or contact support.");
    //     //     return false;
    //     // }


    //     adapter.on('disconnect', () => {
    //         console.log("autoSignIn disconnect");
    //         // onWalletDisonnect();
    //     });

    //     // if (!adapter.publicKey) {
    //     //     toast.error("An error has occured!");
    //     //     return false;
    //     // }

    //     // let hasSavedJWT = await onWalletConnected(adapter.publicKey);
    //     // if (hasSavedJWT) {
    //     //     return true;
    //     // }

    //     // const input: SolanaSignInInput = {
    //     //     domain: window.location.host,
    //     //     address: adapter.publicKey ? adapter.publicKey.toBase58() : undefined,
    //     //     statement: 'Please sign in.',
    //     // };
    //     // const output = await adapter.signIn(input);
    //     // console.log("autoSignIn output", output);

    //     // if (!verifySignIn(input, output)) throw new Error('Sign In verification failed!');

    //     // let res = await requestJWT(adapter.publicKey, output);
    //     // console.log("autoSignIn requestJWT", res);

    //     // return true;

    //     // if (!res?.ok) {
    //     //     if ('disconnect' in adapter) {
    //     //         await adapter.disconnect();
    //     //     }
    //     // }

    //     return true;
    // }, []);


    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} onError={onError} autoConnect >
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

