import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import DuelInside from "../DuelInside";
import useDoTransaction from "../../../hooks/useDoTransaction";

import { toast } from "react-toastify";
import DuelScore from "../DuelScore";
import sounds from "../../../services/sounds";
import MinefieldCurrentRound from "./MinefieldCurrentRound";
import MinefieldBoard from "./MinefieldBoard";
import { Card } from "@nextui-org/react";
import { MinefieldBoardHistory } from "./MinefieldBoardHistory";

function whichPlayer(duel, publicKey) {
    if (duel.player1 == publicKey) return 1;
    if (duel.player2 == publicKey) return 2;
    return 0;
}

export default function MinefieldDuel({ duel, small, className = "" }: any) {
    // const { id, type, name, player1, player2, typeName, scoreToWin, waitLength, img, amount, wallet, tokenWallet } = duel;

    const [finished, setFinished] = useState(duel.finished || false);
    // const [winner, setWinner] = useState(duel.winner || 0);

    const [currentAction, setCurrentAction] = useState<any>();
    const [currentRound, setCurrentRound] = useState<any[]>();
    const [groupedByRound, setGroupedByRound] = useState<any[]>();

    const [boards, setBoards] = useState<any[]>([]);

    const [lastRoundWasTie, setLastRoundWasTie] = useState(false);

    // const { publicKey } = useSelector((state: any) => state.walletBalance);
    const {user} = useSelector((state: any) => state.userData);

    const { doTransaction } = useDoTransaction();


    // useEffect(() => {
    //     if (!finished && duel.finished) {
    //         sounds.playMinefieldExplosion();
    //     }
    //     if (duel.finished) setFinished(duel.finished);
    // }, [duel.finished]);

    // useEffect(() => {
    //     if (duel.winner) setWinner(duel.winner);
    // }, [duel.winner]);

    const prevDuelFinishedRef = useRef(duel.finished);

    useEffect(() => {
        const prevDuelFinished = prevDuelFinishedRef.current;
        const currentDuelFinished = duel.finished;

        if (!prevDuelFinished && currentDuelFinished) {
            // Perform your action here
            console.log('Duel finished changed from false to true');
            sounds.playMinefieldExplosion();
        }

        // Update the ref to the current value for the next render
        prevDuelFinishedRef.current = currentDuelFinished;
    }, [duel.finished]);

    // useEffect(() => {
    //     console.log("MinefieldDuel duel changed", duel);
    // }, [duel]);


    const prevRoundRef = useRef(currentAction?.round);

    useEffect(() => {
        if (!currentAction?.round || !prevRoundRef?.current) return;
        // Check if the round has increased
        if (currentAction.round > prevRoundRef.current) {
            // Perform your action here
            // console.log('Round has increased!', currentAction?.round, prevRoundRef?.current);
            setLastRoundWasTie(true);
            sounds.playMinefieldExplosion();
        }

        // Update the ref with the current round for the next comparison
        prevRoundRef.current = currentAction.round;
    }, [currentAction?.round]); // Dependency array, re-run effect when currentAction.round changes
    useEffect(() => {
        // console.log("currentRound changed", currentRound);
        setLastRoundWasTie(false);
    }, [currentRound]);


    useEffect(() => {
        if (duel.winner) {
            if (!duel.MinefieldAction) {
                setCurrentRound(undefined);
            }
        }
        if (!duel.MinefieldAction) return;

        const groupedByRound = duel.MinefieldAction?.reduce((acc, action) => {
            (acc[action.round] = acc[action.round] || []).push(action);
            // (acc[action.round].squares1 = acc[action.round].squares1 || []).push(action.square1);
            // (acc[action.round].squares2 = acc[action.round].squares2 || []).push(action.square2);
            return acc;
        }, {});

        //history
        // console.log("groupedByRound?.length, boards.length", Object.keys(groupedByRound).length, boards.length);
        if (Object.keys(groupedByRound).length != (boards?.length + (duel.finished ? 0 : 1)) ) {
            // console.log("Object.keys(groupedByRound).length != boards.length", Object.keys(groupedByRound).length, boards.length);
            const boardsFromActions = duel.MinefieldAction.reduce((acc, curr) => {
                if (!acc[curr.round]) {
                    acc[curr.round] = { size: curr.size, round: curr.round, squares1: [], squares2: [] };
                }
                acc[curr.round].squares1.push(curr.square1);
                acc[curr.round].squares2.push(curr.square2);
                return acc;
            }, [])?.sort((a, b) => b.round - a.round);

            setBoards(boardsFromActions);
        }

        // console.log("groupedByRound", groupedByRound);
        setGroupedByRound(groupedByRound);

        if (!duel.finished) {
            const maxRound = Math.max(...Object.keys(groupedByRound).map(Number));
            const actionsFromMaxRound = groupedByRound[maxRound];

            const currAction = actionsFromMaxRound?.reduce((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? a : b);
            setCurrentAction(currAction);
            // console.log("setCurrentAction", currAction);
            setCurrentRound(actionsFromMaxRound);
        } else {
            setCurrentAction(undefined);
            setCurrentRound(undefined);
        }

    }, [duel.winner, duel.MinefieldAction, duel.finished]);

    const handleTimeEnd = () => {
        setFinished(true);
    }

    const checkBeforeTransaction = async () => {
        // console.log("checkBeforeTransaction", duel.player2);
        if (duel.player2) {
            // console.log("dont allow");
            toast.error("This duel already has an opponent! You were too slow.");
            return false;
        } else {
            return true;
        }
    }

    const participateInDuelCallback = async () => {
        if (!user) {
            toast.error("Please connect your wallet first!");
            return;
        }

        let transData = {
            amount: duel.amount,
            duelId: duel.id,
            wallet: user?.wallet,
            toWallet: duel.wallet
        }
        let res = await doTransaction({ url: "duel/join", transData, successMessage: "Duel joined!", checkBeforeTransaction });

        if (res) {
            return true;
        } else {

        }
    }

    return (
        <div className="w-full m-auto max-w-md">
            <DuelInside duel={duel} small={small} participateInDuelCallback={participateInDuelCallback} className={className} />

            {!duel.player2  && !duel.finished && <div className="text-center font-bold m-8">Waiting for an opponent...</div>}

            {!duel.winner && duel.player2 &&
                (currentRound && currentRound?.length > 0 && (user?.wallet == duel.player1 || user?.wallet == duel.player2) ?
                    <div>
                        {lastRoundWasTie &&
                            <div className="text-center font-bold m-8">Tie! You both stepped on each other's mines! New round has begun!</div>
                        }
                        <MinefieldCurrentRound
                            // action={currentRound.reduce((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? a : b)}
                            action={currentAction}
                            endsAt={duel.endsAt}
                            mines={currentRound.flatMap(x => (whichPlayer(duel, user?.wallet) === 1 ? x?.square1 : x?.square2)) || []}
                            // mines={whichPlayer(duel, publicKey) === 1 ? currentRound?.squares1 : currentRound?.squares2 || []}
                            whichPlayer={whichPlayer(duel, user?.wallet)} />
                    </div>
                    :
                    <div className="text-center font-bold m-8">Duel in progress...</div>
                )
            }

            {/* {!duel.winner && duel.MinefieldAction?.length > 0 && (publicKey == player1 || publicKey == player2) ?
                <MinefieldCurrentRound
                    action={duel.MinefieldAction.reduce((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? a : b)}
                    mines={duel.MinefieldAction?.flatMap(x => (whichPlayer(duel, publicKey) === 1 ? x?.square1 : x?.square2)) || []}
                    whichPlayer={whichPlayer(duel, publicKey)} />
                :
                <div className="text-center font-bold m-8">Duel in progress...</div>
            } */}


            {/* {duel.MinefieldAction?.length > 0 && <DuelScore score1={duel.MinefieldAction?.filter(x => x.winner === 1)?.length} score2={duel.MinefieldAction?.filter(x => x.winner === 2)?.length} scoreToWin={scoreToWin} />} */}

            {/* {duel.player2 && <MinefieldActionsHistory actions={duel.MinefieldAction} />} */}

            {boards && (duel.finished || boards.length > 1) &&
                <MinefieldBoardHistory boards={boards} currentAction={currentAction} duel={duel} whichPlayer={whichPlayer(duel, user?.wallet)} />}

            {/* {boards && (duel.finished || boards.length > 1) && <>
                <Card
                    isBlurred
                    className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10 p-1 `}
                    shadow="sm"
                >

                    <h4 className="text-center font-bold text-2xl ">History</h4>
                    {boards.map((board, i) => {

                        if (board?.round == currentAction?.round) return null; // skip current round

                        let mines = duel.player2 == duel?.winner ? board.squares1 : board.squares2;
                        let minesWinner = duel.player1 == duel?.winner ? board.squares1 : board.squares2;
                        if (!duel.winner) {
                            mines = (whichPlayer(duel, publicKey) === 1 ? board?.squares1 : board?.squares2);
                            minesWinner = (whichPlayer(duel, publicKey) === 1 ? board?.squares2 : board?.squares1);
                        }
                        return <div key={board.round}>
                            <div className="text-center text-lg font-bold mt-4 mb-1">Round {board.round + 1} </div>
                            
                            <MinefieldBoard key={i} size={board.size}
                                mines={mines}
                                minesWinner={minesWinner}
                                disabled={true} />
                        </div>
                    })}
                </Card>
            </>
            } 
            */}

            {
                // finished && groupedByRound &&
                //     Object.values(groupedByRound).sort((a, b) => b?.[0]?.round - a?.[0]?.round).map((round, i) => {
                //         console.log("round", round);
                //         return (<div key={i}>
                //             <div className="text-center text-lg font-bold mt-4 mb-2">Round {round.round + 1}</div>
                //              {JSON.stringify(round)}
                //             <MinefieldBoard key={i} size={round.size}
                //                 minesWinner={round.flatMap(x => (whichPlayer(duel, duel?.winner) === 1 ? x?.square1 : x?.square2)) || []}
                //                 // minesWinner={duel.player1 == duel?.winner ? round.squares1 : round.squares2}
                //                 mines={round.flatMap(x => (whichPlayer(duel, duel?.winner) === 2 ? x?.square2 : x?.square1)) || []}
                //                 // mines={duel.player2 == duel?.winner ? round.squares2 : round.squares1}
                //                 disabled={true} /> */

                //         </div>);
                //     })
            }


            {/* {finished && duel.MinefieldAction &&
                duel.MinefieldAction.reduce((acc, curr) => {
                    if (!acc[curr.round]) {
                        acc[curr.round] = { size: curr.size, round: curr.round, square1: [], square2: [] };
                    }
                    acc[curr.round].square1.push(curr.square1);
                    acc[curr.round].square2.push(curr.square2);
                    return acc;
                }, [])?.sort((a, b) => b.round - a.round)?.map((round, i) => {
                    console.log("round", round);
                    return <div key={round.round}>
                        <div className="text-center text-lg font-bold mt-4 mb-2">Round {round.round + 1}</div>
                        <MinefieldBoard key={i} size={round.size}
                            minesWinner={duel.player1 == duel?.winner ? round.square1 : round.square2}
                            mines={duel.player2 == duel?.winner ? round.square2 : round.square1}
                            disabled={true} />
                    </div>
                })
            } */}
        </div>
    );
}