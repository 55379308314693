import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import { Button, Card, CardBody, Link, Spinner } from "@nextui-org/react";
import TransparentTable from "../extensions/TransparentTable";


const CIPList = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const { doGet } = useLetsTalk();

    const [cipList, setCipList] = useState([]);

    function load() {
        if (loading) return;
        setLoading(true);
        doGet("cip/get").then((ret) => {
            setLoading(false);
            if (ret.ok) {
                setCipList(ret.data);

            } else {
                setError(ret?.error);

            }
        }).catch((err) => {
            setError(err.toString() || JSON.stringify(err));
            setLoading(false);
        });

    }

    useImperativeHandle(ref, () => ({
        load,
    }));

    useEffect(() => {
        load();
    }, []);

    return (<div className="m-auto mt-8">
        <h3 className="text-2xl text-center font-bold m-2">Your submissions</h3>



        <TransparentTable
            headers={['URLs', "Info", "Files", "Comment", "Reward"]}
            label="Your submissions"
            isLoading={loading}
            emptyContent={"No submissions found."}
            error={error}
            load={load}
            data={
                cipList.map((cip: any, idx) => {
                    if (!cip) return ["-", "-", "-", "-", "-"];
                    const urls = cip?.urls ? cip.urls.split(/[\s\n]+/) : [];

                    return ([
                        <div className="flex flex-col">
                            {urls?.length > 0 ? urls?.map((url, index) => {
                                if (!url) return "-";
                                return (
                                    <Link size="sm" key={index} href={url} target="_blank" className="text-blue-200 ">
                                        {window.innerWidth > 800 ? url : `[${index + 1}]` }
                                    </Link>);
                            }) : "-"}
                        </div>,
                        cip.info || "-",
                        <div className="flex flex-col">
                            {cip.files?.length > 0 ? cip.files?.map((file) => {
                                if (!file) return "-";
                                return (<Link key={file.id} size="sm" href={file.url} target="_blank" className="text-blue-200">
                                    {file?.name?.length > 12 ? file.name.substring(file.name.length - 12) : file?.name ?? 'Unknown'}
                                </Link>);
                            }) : "-"}
                        </div>,
                        cip.response || "-",
                        cip.reward || "-"
                    ]);
                })
            }
        />

        {cipList.length > 180 && <div>Showing last 200 submissions.</div>}

    </div>

    );
});


export default CIPList;