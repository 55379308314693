
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import CurrentDuels from "../../components/CurrentDuels";
import { socket } from "../../services/socket";




export default function DuelsPage() {
    useEffect(() => {
        function joinRoom() {
            socket.emit('join-room', "duels");
        }

        joinRoom();

        socket.io.on("reconnect", () => {
            // console.log("HOME RECONNECT EVENT TRIGGERED");
            socket.emit('join-room', 'duels')
        });


        return () => {
            socket.emit('leave-room', "duels");
            socket.io.off("reconnect", joinRoom);
        };
    }, []);

    return (<>
        <h2 className="text-2xl font-bold m-3">Duels</h2>
        <CurrentDuels limit={50} isPage={true} />

    </>
    );
}