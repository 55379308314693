import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { SocketSingleton } from '../services/socket';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// In your Redux middleware or main application file
const socketSingleton = SocketSingleton.getInstance();
socketSingleton.setDispatch(store.dispatch);
socketSingleton.setStore(store);
// socketSingleton.initializeCallbacks();

export default store;