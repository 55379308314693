const initialState = {
    betaAccess: false,
    currentFee: 5.0,
    betLimit: 1000000
}

export const BETA_REDUCER_TYPES = {
    SET_ACCESS: "SET_BETA__ACCESS",
    SET_DATA: "SET_BETA_DATA"
}

const betaReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case BETA_REDUCER_TYPES.SET_ACCESS:
            // Cia apacioj yra taspats kas reducer = { viskas ka paduodi, nepamirsk ...state, newValue: value}
            // @REDUXHELP

            // betLimit: Number.parseInt(process.env.BET_LIMIT),
            // currentFee: Number.parseFloat(process.env.BET_FEE)
            return {
                ...state,
                betaAccess: action.payload.betaAccess
            };
        case BETA_REDUCER_TYPES.SET_DATA:
            return {
                ...state,
                betaAccess: action.payload.betaAccess,
                betLimit: Number.parseInt(action.payload.betLimit),
                currentFee: Number.parseFloat(action.payload.currentFee),
                lamportsRequiredToBet: Number.parseInt(action.payload.lamportsRequiredToBet),
                lotteryDicePercent: Number.parseInt(action.payload.lotteryDicePercent)
            };
        //     return action.payload.SOL;
        case "CLEAR_ACCESS":
            return initialState;
        default:
            return state;
    }
}

export default betaReducer;