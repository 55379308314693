
import { Card, CardBody, Link } from "@nextui-org/react";

import { useEffect, useState } from "react";
import BattleInfo from "../components/battle/BattleInfo";
import Battle from "../components/battle/Battle";
import { redirect, useParams } from "react-router-dom";
import { socket } from "../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { addBattleAction, updateBattleAction } from "../store/actions/reducerActions";
import { useLetsTalk } from "../hooks/useLetsTalk";
import { CardNice } from "../components/small/CardNice";




export default function BattlePage() {
    // const [currentBattle, setCurrentBattle] = useState(null);\
    const { id } = useParams();
    const battleId = Number.parseInt(id || '');

    const dispatch = useDispatch();

    const { battles } = useSelector((state: any) => state.battlesData);
    const b = battles.find(x => x.id === battleId);
    const [currentBattle, setCurrentBattle] = useState(b);

    const { doGet } = useLetsTalk();


    //TODO: GET BATTLE INFO BY ID
    useEffect(() => {
        if (!battleId) redirect("/");
        if (!currentBattle) {
            doGet(`battle/get?id=${battleId}`).then((battle) => {
                // console.log("battle data", battle);
                // setCurrentBattle(battle);
                dispatch(addBattleAction(battle));
                let btl = battles.find(x => x.id === battleId);
                // console.log("battles", battles);
                // console.log("BTL", btl);
                setCurrentBattle(btl);
                // console.log("Got battle", battle);
                document.title = `Battle ${battle.name1} vs ${battle.name2} - VersusCoin Battles - vscn.bet`;
            }).catch((error) => console.error(error));

        }
    }, [battleId]);

    useEffect(() => {
        // console.log("USE EFFECT", battles);
        let btl = battles.find(x => x.id === battleId);
        // console.log("battles", battles);
        // console.log("BTL", btl);
        setCurrentBattle(btl);
    }, [battles, battleId]);

    useEffect(() => {
        if (currentBattle?.name1 && currentBattle?.name2) {
            document.title = `Battle ${currentBattle.name1} vs ${currentBattle.name2} - VersusCoin Battles - vscn.bet`;
        }

        function joinRoom() {
            socket.emit('join-room', `battle-${battleId}`);
        }
        joinRoom();

        socket.io.on("reconnect", joinRoom);
        return () => {
            socket.emit('leave-room', `battle-${battleId}`);
            socket.io.off("reconnect", joinRoom);
        };
    }, []);



    return (
        <>
            <Link href={"/info"} color="foreground" className="m-auto">
                <Card className="m-auto mt-4 mb-4 bg-red-600">
                    <CardBody>
                        <p>Warning! Click and read before betting!</p>
                    </CardBody>
                </Card>
            </Link>

            {/* <div id="exampleBattle" className="text-center  w-auto max-w-xl"> */}
            {/* {currentBattle ?  < BattleInfo battleData={currentBattle} /> : '' } */}

            <CardNice finished={currentBattle?.finished}>
                {currentBattle ? <Battle data={currentBattle} /> : <div className="m-4">Loading battle...</div>}
            </CardNice>
            {/* </div> */}

        </>
    )
}

// export default React.memo(BattlePage);