


import { useEffect, useState } from "react";
import { Button, Card, Link, Spinner } from "@nextui-org/react";
import { socket } from "../services/socket";
import BattleInside from "./battle/BattleInside";
import { useDispatch, useSelector } from "react-redux";
import { setBattlesAction } from "../store/actions/reducerActions";
import { useLetsTalk } from "../hooks/useLetsTalk";
import { CardNice } from "./small/CardNice";



export default function CurrentBattles({ limit = 20, showFinished = false, isPage = false }: { limit: number, showFinished: boolean, isPage: boolean }) {
    // const [runningBattles, setRunningBattles] = useState<any>(null);
    const dispatch = useDispatch();


    const [nextBattle, setNextBattle] = useState<any>(null);

    const [battlesLoading, setBattlesLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const [nextLoading, setNextLoading] = useState(false);

    const { battles } = useSelector((state: any) => state.battlesData);

    const { doGet } = useLetsTalk();

    async function loadBattles() {
        if (battlesLoading) return;
        setBattlesLoading(true);

        const url = showFinished ? "get-all" : "get-running";
        doGet(`battle/${url}?limit=${limit}`).then((data) => {
            dispatch(setBattlesAction(data));
            setBattlesLoading(false);
            setError(undefined);
        }).catch((error) => {
            console.error(error);
            setError(error?.toString() || JSON.stringify(error));
            setBattlesLoading(false);
        });


    }

    useEffect(() => {
        loadBattles();
        socket.io.on("reconnect", () => { loadBattles() });
        return () => {
            socket.io.off("reconnect", () => { loadBattles() })
        };
    }, []);


    useEffect(() => {
        if (nextLoading) return;
        if (!battlesLoading && (!battles || battles.length < 1)) {
            setNextLoading(true);

            doGet("battle/when-next").then((data) => {
                // console.log("Got next battle", data);
                if (data?.nextBattle) {
                    setNextBattle((new Date(data?.nextBattle)).toLocaleTimeString());
                }
                setNextLoading(false);
            }
            ).catch((error) => {
                console.error(error);
                setNextLoading(false);
            });

        }
    }, [battles, battlesLoading]);




    return (<>
        <div className={`m-auto `}>

            {battlesLoading && <Spinner size="lg">Loading...</Spinner>}
            {!battlesLoading && (!battles || battles?.length < 1) && !error && <div className="text-lg">No battles right now. Stay tuned!</div>}

            {!battlesLoading && error && <div>
                <div className="text-red-400">{error}</div>
                <br />
                <Button color="primary" onClick={() => loadBattles()}>Retry</Button>
            </div>}

            {((!battles || battles?.length < 1) && nextBattle && nextBattle.length > 1) && <div className="text-lg">Next battle starts {nextBattle}</div>}

            <div className={`m-auto grid grid-cols-1 ${battles?.length > 1 ? 'md:grid-cols-2' : ''} ${battles?.length > 2 ? 'lg:grid-cols-3' : ''} ${battles?.length > 4 && 'xl:grid-cols-4 2xl:grid-cols-5'} gap-4 md:gap-5 items-start`}>
                {battles?.length > 0 && battles?.map((battle) => {
                    return (
                        <Link key={battle?.id} href={`/battle/${battle.id}`} disableAnimation={true} className="no-hover-opacity hover:bg-background/10 rounded-xl"
                        >
                            <CardNice finished={battle?.finished}>
                                <BattleInside battle={battle} small={true} />
                            </CardNice>
                        </Link>
                    );
                })}
            </div >

            {!isPage && <Button as={Link} color="primary" className="mt-4" variant="solid" href="/battles">More battles</Button>}

        </div>
    </>
    );
}

