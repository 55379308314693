import { Accordion, AccordionItem, Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle, NextUIProvider, Spinner } from "@nextui-org/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";

import '../../css/walletAdapter.css';
import VSCNCoin from "../small/VSCNCoin";
import { useSelector } from "react-redux";
import MaintenanceMessage from "./MaintenanceMessage";
import { SoundSettings } from "./SoundSettings";
import { whiteGlowSmall, yellowGlow } from "../../helpers/glows";


export function AppHeader({ isConnected, menuItems }) {
    // const [isConnected, setIsConnected] = useState(connected);


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (<>
        {isConnected ? '' : <div className="fixed z-10 top-0 right-0 w-full h-6 bg-red-600">DISCONNECTED</div>}


        <Navbar
            isBordered
            isMenuOpen={isMenuOpen}
            isBlurred={false}
            classNames={{ base: `bg-transparent ${!isConnected ? 'pt-6' : ''}` }}
            onMenuOpenChange={setIsMenuOpen}
        >
            <NavbarContent className="sm:hidden" justify="start">
                <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
                <SoundSettings />

            </NavbarContent>

            <NavbarContent className="sm:hidden pr-3" justify="center">
                <NavbarBrand>
                    <Link href="/" >
                        <img src="/img/logo.png" alt="VersusCoin Logo" className="logoFlip m-auto max-h-10"
                            style={{ ...whiteGlowSmall }} />
                    </Link>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarBrand>
                    <Link href="/" >
                        <img src="/img/logo.png" alt="VersusCoin Logo" className="logoFlip m-auto max-h-10"
                            style={{ ...whiteGlowSmall }} />
                    </Link>
                </NavbarBrand>

                {menuItems.map((item, index) => (
                    item.d ? (
                        // item.children.map((ch, chi) => (
                        //     <Link key={chi} href={ch.href} className=" text-white"> {ch.t} </Link>
                        // ))
                        <Dropdown key={index}
                            className="bg-transparent/70"
                        >
                            <NavbarItem >
                                <DropdownTrigger>
                                    <Link href="#"
                                        style={{ ...(item.glow ? { ...yellowGlow } : {}) }}
                                        color="foreground"
                                        className="w-full text-white"
                                        size="lg"
                                    >
                                        {item.d}
                                    </Link>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu
                                aria-label={item.d}
                                variant="flat"

                            // className="bg-transparent/25"
                            >
                                {item.children.map((ch, chi) => (
                                    <DropdownItem as={Link} key={`${ch.t}-${chi}`} textValue={ch.t} href={ch.href}
                                        className="no-hover-opacity  text-white hover:text-white"
                                        style={{ color: "white" }}
                                    >
                                        {/* <Link href={ch.href} className=" text-white"> */}
                                        {ch.t}
                                        {/* </Link> */}
                                    </DropdownItem>

                                ))}
                            </DropdownMenu>
                        </Dropdown>

                    ) : (
                        <NavbarItem key={index}>
                            <Link
                                color="foreground"
                                // color={
                                //   index === 2 ? "primary" : index === menuItems.length - 1 ? "danger" : "foreground"
                                // }
                                href={item.href}
                                size="lg"
                                target={item.target ? "item.target" : "_self"}
                            >
                                {item.t}
                            </Link>
                        </NavbarItem>
                    )
                ))}

            </NavbarContent>

            <NavbarContent justify="end" className='headerWallet flex items-center'>
                <SoundSettings className="hidden sm:flex" />

                <WalletMultiButton />

            </NavbarContent>

            <NavbarMenu className='bg-black/70 ' onClick={() => { setIsMenuOpen(false) }}>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`${item.href}-${index}`}>
                        {item.d ? (
                            // item.children.map((ch, chi) => (
                            //     <Link key={chi} href={ch.href} className=" text-white"> {ch.t} </Link>
                            // ))
                            <Accordion className="p-0 m-0 text-white" isCompact
                                itemClasses={{
                                    title: `m-0 w-full font-bold ${item.glow ? " text-gold" :"text-white"} text-lg`,
                                    trigger: "p-0 m-0 ",
                                    content: "px-3"
                                }}
                                style={{ ...(item.glow ? { ...yellowGlow } : {}) }}

                            >
                                <AccordionItem key="1" aria-label={item.d} title={item.d}>
                                    {item.children.map((ch, chi) => (
                                        <Link
                                            key={`${ch.t}-${chi}`}
                                            className="w-full font-bold text-white"
                                            href={ch.href}
                                            size="lg"
                                            onClick={() => { setIsMenuOpen(false) }}
                                            target={item.target ? "item.target" : "_self"}

                                        >
                                            {ch.t}
                                        </Link>

                                    ))}
                                </AccordionItem>
                            </Accordion>



                        ) : (
                            <Link
                                // color="primary"
                                // color={
                                //   index === 2 ? "primary" : index === menuItems.length - 1 ? "danger" : "foreground"
                                // }
                                className="w-full font-bold text-white"
                                href={item.href}
                                size="lg"
                                onClick={() => { setIsMenuOpen(false) }}
                                target={item.target ? "item.target" : "_self"}

                            >
                                {item.t}
                            </Link>
                        )}
                    </NavbarMenuItem>
                ))}


                {/* <Button
                    href="https://raydium.io/swap/?outputCurrency=4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r"
                    target="_blank"
                    as={Link}
                    color="primary"
                    // showAnchorIcon
                    variant="shadow"
                    className="absolute z-10 mt-32" style={{ right: '1em' }}
                >
                    Get<VSCNCoin />
                </Button> */}

            </NavbarMenu>


        </Navbar>
        {/* } */}

        <MaintenanceMessage />

    </>);

}