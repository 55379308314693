const initialState = {
    lotteries: []
}

export const LOTTERIES_REDUCER_TYPES = {
    LOTTERY_ADD: "LOTTERY_ADD",
    LOTTERY_UPDATE: "LOTTERY_UPDATE",
    LOTTERY_REMOVE: "LOTTERY_REMOVE",
    LOTTERIES_SET: "LOTTERIES_SET"

}

const lotteriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOTTERIES_REDUCER_TYPES.LOTTERY_ADD: {
            // Cia apacioj yra taspats kas reducer = { viskas ka paduodi, nepamirsk ...state, newValue: value}
            // @REDUXHELP
            // console.log("LOTTERIES_REDUCER_TYPES.LOTTERY_ADD", action.payload.lottery, state);
            if (!action.payload.lottery) return state;
            let found = false;
            const ret = {
                lotteries: state.lotteries.map((lottery: any) => {
                    if (lottery.id === action.payload.lottery.id) {
                        found = true;
                        return {
                            ...lottery,
                            ...action.payload.lottery
                        };
                    }
                    return lottery;
                })
            };
            if (found) {
                return ret;
            } else {
                return {
                    lotteries: [action.payload.lottery, ...(state.lotteries || [])]
                };
            }
            break;
        }
        case LOTTERIES_REDUCER_TYPES.LOTTERY_UPDATE: {
            // console.log("lottery-update received", action.payload.lottery);
            // let found = false;
            const ret = {
                // ...state,
                lotteries: state.lotteries.map((lottery: any) => {
                    if (lottery.id === action.payload.lottery.id) {
                        // found = true;
                        return {
                            ...lottery,
                            ...action.payload.lottery
                        };
                    }
                    return lottery;
                })
            };
            // if(!found){
            //     ret.lotteries= [action.payload.lottery, ...(ret.lotteries || [])]
            // }
            // console.log("returning", ret);
            return ret;
            break;
        }
        case LOTTERIES_REDUCER_TYPES.LOTTERY_REMOVE:
            return {
                // ...state,
                lotteries: state.lotteries.filter((lottery: any) => lottery.id !== action.payload.lotteryId)
            };
        case LOTTERIES_REDUCER_TYPES.LOTTERIES_SET:
            // console.log("LOTTERIES_REDUCER_TYPES.LOTTERIES_SET", action.payload.lotteries);
            return {
                lotteries: action.payload.lotteries
            };
        default:
            return state;
    }
}

export default lotteriesReducer;