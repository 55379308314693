import InfoTooltip from "./InfoTooltip";

const duelTypeExplanations = {
    "rps": "Classic Rock Paper Scissors game. Rock beats Scissors, Scissors beats Paper, Paper beats Rock. If you win, you get 2x your bet (minus our small fee, ofcourse). If you lose, you lose your bet. Player who reaches the specified amount of wins first, wins the duel. Failing to make a move in time will result in a loss.",
    "coin": "Coin flip. Duel creator (player 1) is always heads. Coin flip result is always random. If you win, you get 2x your bet (minus our small fee, ofcourse). If you lose, you lose your bet.",
    "minefield": "Place a mine each turn. Player who places their mine on an already existing enemy mine - explodes and loses. If both players explode at the same time, the duel is a draw and retarts. If you win, you get 2x your bet (minus our small fee, ofcourse). If you lose, you lose your bet. Failing to make a move in time will result in a loss.",
    "battleships": "Classic game of Battleships! Place all 10 of your ships on the board, and try to sink enemy's ships before they sink yours! Player 1 goes first. Ships can't touch each other. If your shot was a hit, you get to shoot again"
};

const duelTypeHeaders = {
    "rps": "Rock Paper Scissors",
    "coin": "Coin Flip",
    "minefield": "Minefield",
    "battleships": "Battleships"
}

export default function InfoDuelTooltip({ className = '', classNames={trigger:'w-4 h-4 float-right'}, type }) {

    return (<InfoTooltip className={className} classNames={classNames}
        infoHeader={duelTypeHeaders[type]}
        infoText={duelTypeExplanations[type]}
    />);
}