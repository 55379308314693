import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { WalletBalance } from "../components/small/WalletBalance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import VSCNCoin from "../components/small/VSCNCoin";
import DiceRoll from "../components/lottery/DiceRoll";
import DiceSelect from "../components/lottery/DiceSelect";

import anal from "../services/anal";
import { yellowGlow } from "../helpers/glows";
import useWalletBalance from "./useWalletBalance";

const dicesStyle = {
    // transform: "scale(0.5, 0.5)"
}

const useDiceRollModal = ({ lottery, onCloseCallback, onSuccessCallback, modalData: _modalData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketsData, setModalData] = useState(_modalData);

    // const [ticketsAmount, setTicketsAmount] = useState(0);
    const [ticketsMessage, setTicketsMessage] = useState("");

    const [chosenDice, setChosenDice] = useState(0);

    const [rolled, setRolled] = useState<any>(null);

    const { ticketsLimit, lotteryDicePercent } = useSelector((state: any) => state.betaData);
    // console.log(" lotteryDicePercent", lotteryDicePercent);
    const lotteryDiceMultiplier = lotteryDicePercent / 100;

    const [showResult, setShowResult] = useState(false);

    const { refetchBalance } = useWalletBalance();


    // backdropo clickas sita iskviecia
    // ir visi kiti sita kviecia :>
    const closeModal = useCallback(() => {
        if (!isLoading) {
            setIsLoading(false);
            // setTicketsAmount(0);
            setChosenDice(0);
            setTicketsMessage("");
            setRolled(null);
            setShowResult(false);
            onCloseCallback();
            setIsOpen(false);

        }
    }, [isLoading])

    const openModal = (ticketsData) => {
        // console.log("TICKETS DATA", ticketsData);
        refetchBalance();
        setModalData(ticketsData);
        setIsOpen(true);
        anal.event({ category: "lottery", action: "dice_roll_modal_open", label: ticketsData?.name, value: ticketsData?.lotteryId });

    }

    const [shouldShake, setShouldShake] = useState(false);

    useEffect(() => {
        setShouldShake(true);
        const timer = setTimeout(() => setShouldShake(false), 300); // Reset after the duration of the shake animation
        return () => clearTimeout(timer); // Clean up timer on unmount
    }, [lottery.amount]);


    const rollCallback = (async (event) => {
        if (!chosenDice || chosenDice <= 0) {
            toast.error("Please choose dice eye count.");
            return;
        }

        // setRolled({ ok: true, diceRoll: 5, amount:lottery.amount, userRoll: 2, won: true });
        // return;


        setIsLoading(true);
        const res = await onSuccessCallback({
            ...ticketsData,
            amount: Math.ceil(lottery.amount * lotteryDiceMultiplier),
            dice: chosenDice,
            message: ticketsMessage

        });

        //res yra  { ok: true, won: true, diceRoll, userRoll, error? }
        if (res) {
            // console.log("Roll info", res);

            if (!res.ok || !res.data?.diceRoll || !res.data?.userRoll) {
                toast.error(res.error ? res.error : "Error rolling the dice.");
            } else {
                setRolled(res.data);
            }
            
            setIsLoading(false);

            anal.event({ category: "lottery", action: "dice_roll", label: ticketsData?.name, value: ticketsData?.lotteryId });


            //cia uzdarymui
            // setIsOpen(false);
            // setIsLoading(false);
            // setChosenDice(0);
            // setTicketsMessage("");
            // setRolled(null);
        } else {
            setIsLoading(false);
        }
    });

    const Component = useMemo(() => {
        return <Modal
            isOpen={isOpen}
            onOpenChange={closeModal}
            backdrop="blur"
            isDismissable={false}
            className="dark bg-default-100/50" classNames={{ closeButton: "z-10" }}
            scrollBehavior="outside"

        >
            <ModalContent>
                <Card className=" bg-default-100/50">
                    <ModalHeader className="flex flex-col gap-1">
                        <h2 className="text-center text-2xl" style={{ fontFamily: "Barmo" }}>Roll The Dice</h2>
                    </ModalHeader>
                    <ModalBody>

                        <div className="max-w-40 max-h-40 m-auto">


                            {/* <img src={ticketsData?.img} alt={ticketsData?.name} /> */}

                            <div className="flex flex-row justify-center items-center">
                                <div className={`flex flex-col justify-center items-center max-w-80 max-h-80  ${shouldShake ? 'shake' : ''}`}>
                                    <div className="">
                                        <img src={ticketsData?.img} alt={ticketsData?.name} className={`${shouldShake ? 'yellowGlow' : ''}`} />
                                    </div>
                                    <div className="absolute p-2 bg-background/80 rounded-lg text-center" style={{ fontFamily: "Barmo", marginTop: "0", zIndex: 5, }}>
                                        {/* <AmountChangeNotifier style={yellowGlow} amount={lottery.amount} className="text-2xl" /> */}

                                        <div className="text-xl" style={{ ...yellowGlow, zIndex: 0 }} >{Intl.NumberFormat().format(Math.round(lottery.amount))} </div>
                                        <div className="text-md " style={yellowGlow}>VSCN </div>
                                    </div>
                                </div>
                            </div>


                            {showResult &&
                                <div className="whitespace-nowrap"
                                    style={{
                                        zIndex: 10,
                                        // top: '25%',
                                        marginTop: "-20%",
                                        left: '50%',
                                        // transform: 'translate(-50%, -50%)',
                                        transform: 'translateX(-50%)',
                                        position: 'absolute'
                                    }}>


                                    <div className={`slam-down-front  rounded-md p-2 m-auto  ${rolled?.won ? ' bg-green-500/80' : 'bg-red-500/80'}`} style={{ fontFamily: "Barmo" }}>

                                        <h1 className="font-bold text-3xl md:text-4xl" >{rolled?.won ? 'You Won!' : 'You Lost!'}</h1>
                                        {rolled?.won && <h2 className="text-lg md:text-xl text-center">{rolled?.amount || lottery.amount}<VSCNCoin /></h2>}
                                    </div>

                                </div>
                            }
                        </div>



                        {!rolled && <>
                            <div className="mt-8 text-center w-full flex flex-col justify-center justify-items-center items-center">
                                <Input
                                    label="Message (optional)"
                                    type="text"
                                    className="max-w-xs"
                                    maxLength={128}
                                    onChange={(e) => { setTicketsMessage?.(e.target.value) }}
                                    value={ticketsMessage}
                                />
                                <br />
                            </div>

                        </>}


                        {rolled && <DiceRoll diceRoll={rolled?.diceRoll} userRoll={rolled?.userRoll} won={rolled?.won}
                            finishedCallback={(roll) => {
                                setShowResult(true);
                            }}
                        />}

                        <div className="mt-2 text-center w-full flex flex-col justify-center justify-items-center items-center">
                            <h3 className=" text-small">{rolled ? 'You picked' : 'Pick the dice side'}</h3>

                            <DiceSelect disabled={isLoading || rolled} onSelect={(dice) => {
                                !isLoading && setChosenDice(dice);
                            }} />
                        </div>


                        <div className="text-center text-sm">
                            <WalletBalance />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {!rolled && <Button variant="light" isDisabled={isLoading} onPress={() => {
                            closeModal();
                        }}>
                            Cancel
                        </Button>
                        }

                        {!rolled && <Button color="primary" isDisabled={isLoading || !chosenDice || chosenDice <= 0 || !lottery?.amount} isLoading={isLoading}
                            className={`${!isLoading && shouldShake ? 'yellowGlow' : ''}`}
                            onPress={async (e) => {
                                rollCallback(e);

                            }}>
                            {isLoading ? "Rolling..." : (<span>Roll for {Intl.NumberFormat().format(Math.ceil(lottery.amount * lotteryDiceMultiplier))}<VSCNCoin /></span>)}
                        </Button>}

                        {rolled && <Button color="primary" isDisabled={isLoading || !showResult} onPress={() => {
                            closeModal();
                        }}>
                            Close
                        </Button>}
                    </ModalFooter>
                </Card>
            </ModalContent>
        </Modal >
    }, [onCloseCallback, onSuccessCallback, ticketsData])

    return {
        ModalComponent: Component, openModal, closeModal
    }
}


export default useDiceRollModal;