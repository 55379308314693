
import { MessageModel } from "../../components/messages/Message";

const initialState = {
    messages: Array<MessageModel>(),
    newMessagesCounter: 0
}

export const CHAT_REDUCER_TYPES = {
    MESSAGE_ADD: "MESSAGE_ADD",
    MESSAGES_PREPEND: "MESSAGES_PREPEND",
    MESSAGE_UPDATE: "MESSAGE_UPDATE",
    MESSAGE_REMOVE: "MESSAGE_REMOVE",
    MESSAGES_SET: "MESSAGE_SET",
    RESET_NEW_MESSAGES_COUNTER: "RESET_NEW_MESSAGES_COUNTER"
}

const chatReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CHAT_REDUCER_TYPES.MESSAGE_ADD: {
            if (!action.payload.message) return state;
            let found = false;
            const ret = {
                messages: state.messages.map((msg: MessageModel) => {
                    if (msg.id === action.payload.message.id) {
                        found = true;
                        return {
                            ...msg,
                            ...action.payload.message
                        };
                    }
                    return msg;
                })
            };
            if (found) {
                return ret;
            } else {
                return {
                    ...state,
                    newMessagesCounter: state.newMessagesCounter + 1,
                    messages: [...(state.messages || []), action.payload.message]
                };
            }
            break;
        }
        case CHAT_REDUCER_TYPES.MESSAGES_PREPEND: {
            return {
                ...state,
                // newMessagesCounter: state.newMessagesCounter + action.payload.messages?.length,
                messages: [...action.payload.messages, ...(state.messages || [])]
            };
            break;
        }
        case CHAT_REDUCER_TYPES.MESSAGE_UPDATE: {
            const ret = {
                message: state.messages.map((message: MessageModel) => {
                    if (message.id === action.payload.message.id) {
                        return {
                            ...message,
                            ...action.payload.message
                        };
                    }
                    return message;
                })
            };
            return ret;
            break;
        }
        case CHAT_REDUCER_TYPES.MESSAGE_REMOVE:
            return {
                ...state,
                messages: state.messages.filter((message: MessageModel) => message.id !== action.payload.messageId)
            };
        case CHAT_REDUCER_TYPES.MESSAGES_SET:
            return {
                ...state,
                messages: action.payload.messages
            };
        case CHAT_REDUCER_TYPES.RESET_NEW_MESSAGES_COUNTER:
            return {
                ...state,
                newMessagesCounter: 0
            };
        default:
            return state;
    }
}

export default chatReducer;