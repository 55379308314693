import React, { useEffect, useMemo, useState } from "react";
import Cell from "./Cell";
import { BattleshipsShotSerialized, ICell, IShip } from "../../shared/types";
import { useDroppable } from "@dnd-kit/core";
import Ship from "./Ship";

import "../../../../../css/duel-turn-timer.css";


interface IProps {
  cells: Map<string, ICell>;
  // hidden?: boolean;
  ships?: IShip[];
  onCellClick?: Function;
  isDroppable?: boolean;
  isDraggable?: boolean;
  shots?: BattleshipsShotSerialized[];
}

const Battlefield = ({ cells, ships, shots, onCellClick, isDroppable = false, isDraggable = false }: IProps) => {
  const size = 10;
  // console.log("passed cells to battlefield", cells);

  const [lastShot, setLastShot] = useState<BattleshipsShotSerialized | undefined>();

  useEffect(() => {
    const newestShot = shots?.reduce((newest, current) => {
      return current?.createdAt > newest?.createdAt ? current : newest;
    }, shots[0]);
    setLastShot(newestShot);
  }, [shots]);

  return (
    <>
      <div className="board grid w-full min-w-sm gap-0 aspect-square p-2 scale-in-center bg-sky-200/100"
        style={{
          gridTemplateColumns: `repeat(${size}, 1fr)`,
          gridTemplateRows: `repeat(${size}, 1fr)`,
          // opacity: disabled ? 0.8 : 1
        }}>
        {Array.from(cells.values()).map((cell: ICell, index: number) => {
          let ship;
          if (ships && cell.shipId > -1) {
            ship = ships?.find(s => s && s.id === cell.shipId);
            if (ship && (ship.x != cell.position?.x || ship.y != cell.position?.y)) {
              ship = null;
            }
          }
          let shot;
          if (shots) {
            shot = shots.find(s => s.x == cell.position?.x && s.y == cell.position?.y);
            // if (shot) {
            //   console.log("found shot", shot, cell.position?.x, cell.position?.y);
            // }
          }
          return <Cell
            id={`${cell.position.x}:${cell.position.y}`}
            key={cell.key}
            className={`cell relative flex justify-center items-center p-1 aspect-square select-none ${ship || shot?.hit ? "bg-white" : ''}`}
            // hidden={hidden}
            x={cell.position.x}
            y={cell.position.y}
            shipId={cell.shipId}
            onClick={onCellClick}
            isDamaged={cell.isDamaged()}
            isInactive={cell.isInactive()}
            isOpen={cell.isOpen()}
            isDroppable={isDroppable}
          // onCellDragStart={onCellDragStart}
          >
            {ship && <Ship id={ship.id} key={`ship-${ship.id}`} ship={ship} isDraggable={isDraggable}
              className={``}
              style={{
                position: "absolute",
                // pointerEvents: "none",
                top: 0,
                left: 0,
                // margin:0,
                zIndex: 5,
                // border: 0
              }} />}
            {/* {cell.shipEl && cell.shipEl} */}

            {shot && <ShotComponent hit={shot?.hit} isLast={shot === lastShot} isShip={cell.shipId > -1} />}
          </Cell>
        })}
      </div>
      <br />
      {/* shots:{JSON.stringify(shots)} */}
    </>
  );
};

function ShotComponent({ hit, isLast, isShip }) {

  const fireStart = useMemo(() => {
    return Math.random() * 2000;
  }, [hit]);

  return (
    <div>
      {isShip && hit &&
        <div className={`fireContainer absolute z-10 `}
          style={{ bottom: "60%", fontSize: isLast ? "20px" : "14px" , left: isLast ? "20%" : "25%", animationDuration: "2000ms", animationDelay: `${fireStart}ms`, opacity:0.9 }}>
          <div className="flames">
            <div className="red flame"></div>
            <div className="orange flame"></div>
            <div className="yellow flame"></div>
            <div className="white flame"></div>
          </div>
        </div>}

      {isLast && <span className={isLast ? "animate-ping scale-[2] absolute z-10" : ""}>
        {hit ? "⦿" : "●"}
      </span>}
      <span >
        {hit ? "⦿" : "●"}
      </span>

      {/* <span className={`${isLast ? "animate-ping scale-[2] " : ""}`} >{hit ? "⦿" : "●"}</span>
      {hit ? "⦿" : "●"} */}
    </div>

  );



  // return <span className={`${isLast ? "animate-ping absolute z-10" : ""}`}>{hit ? "⦿" : "●"}</span>;
}


export default Battlefield;
