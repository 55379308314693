import { Card } from "@nextui-org/react";
import { CardNice } from "../small/CardNice";


export default function DuelScore({ score1, score2, scoreToWin }: any) {
    return (
        <CardNice className="w-full bg-background/10 dark:bg-default-100/10"
        // isBlurred className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10  p-2 `}
        //     shadow="sm" 
        >
            <h3 className="text-2xl m-2 font-bold">Score</h3>
            <div className="flex flex-col gap-2 justify-center items-center">

                <div className="flex justify-center items-center text-4xl font-bold ">
                    <div className="mr-2">{score1}</div>
                    <div className="">:</div>
                    <div className=" ml-2">{score2}</div>
                </div>
                <div className="text-sm">Out of {scoreToWin}</div>
            </div>
        </CardNice>
    )
}