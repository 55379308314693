const initialState = {
    enabled: false,
    starts: null,
    message: null
}

export const MAINTENANCE_REDUCER_TYPES = {
    MAINTENANCE_SET_DATA: "MAINTENANCE_SET_DATA"
}

const maintenanceReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MAINTENANCE_REDUCER_TYPES.MAINTENANCE_SET_DATA:
            // console.log("setting data", action.payload);
            return {
                // ...state,
                enabled: action.payload.enabled,
                starts: action.payload.starts ? new Date(action.payload.starts) : null,
                message: action.payload.message
            };
        default:
            return state;
    }
}

export default maintenanceReducer;