import { Button, Popover, PopoverContent, PopoverTrigger, Tooltip } from "@nextui-org/react";
import React, { forwardRef, useRef, useState } from "react";
import { Children, cloneElement } from "react";

import { useButton, Ripple, Spinner, ButtonProps as BaseButtonProps } from "@nextui-org/react";
import ReactDOM from "react-dom";



// export interface ButtonProps extends BaseButtonProps { }

// const TooltipHoverableButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
//     const {
//         domRef,
//         children,
//         spinnerSize,
//         spinner = <Spinner color="current" size={spinnerSize} />,
//         spinnerPlacement,
//         startContent,
//         endContent,
//         isLoading,
//         disableRipple,
//         getButtonProps,
//         getRippleProps,
//     } = useButton({
//         ref,
//         ...props,
//     });
//     const { ripples } = getRippleProps();

//     return (
//         <button ref={domRef}
//             className={props.className || ''}
//             {...getButtonProps()}
//         >
//             {/* {startContent} */}
//             {/* {isLoading && spinnerPlacement === "start" && spinner} */}
//             {children}
//             {/* {isLoading && spinnerPlacement === "end" && spinner} */}
//             {/* {endContent} */}
//             {!disableRipple && <Ripple ripples={ripples} onClear={() => { }} />}
//         </button>
//     );
// });



export default function ToolTipFix(props) {
    const [isOpen, setIsOpen] = useState(false);

    // const triggerRef = useRef<any>(null);
    // const triggerRef = useRef<any>(props.content.ref);
    // const portalRef = useRef<any>(null);

    const triggerClassName = props.triggerClassName || '';



    const propsModed = { ...props };
    propsModed.triggerClassName = undefined;
    delete propsModed.triggerClassName;
    // const childrenWithProps = Children.map(props.children, (child, index) => {
    //     // Checking if this is the first child
    //     if (index === 0 && React.isValidElement(child)) {
    //         console.log("child", child);
    //         return cloneElement(child, {
    //             // Attach your event here
    //             // onMouseOver: () => setIsOpen(true),
    //             onMouseOver: () => setIsOpen(true),
    //             onMouseLeave: () => setIsOpen(false),
    //             onClick: (e) => {
    //                 if (e.preventDefault) e.preventDefault();
    //                 if (e.stopPropagation) e.stopPropagation();
    //                 setIsOpen(!isOpen)
    //             },
    //         } as any);
    //     }
    //     return child;
    // });
    // if (React.isValidElement(props.children?.[0])) {
    //     props.children[0] = cloneElement(props.children[0], {
    //         // Attach your event here
    //         // onMouseOver: () => setIsOpen(true),
    //         onMouseOver: () => setIsOpen(true),
    //         onMouseLeave: () => setIsOpen(false),
    //         onClick: (e) => {
    //             if (e.preventDefault) e.preventDefault();
    //             if (e.stopPropagation) e.stopPropagation();
    //             setIsOpen(!isOpen)
    //         },
    //     } as any);

    // }

    // return (
    //     <Popover >
    //         <PopoverTrigger>
    //             <div className="p-0 w-auto h-auto" >{propsModed.children}</div>
    //         </PopoverTrigger>
    //         <PopoverContent>
    //             {propsModed.content}
    //         </PopoverContent>
    //     </Popover>


    // );

    // return (
    //     <Tooltip {...propsModed} isOpen={isOpen}>
    //         {/* {childrenWithProps} */}
    //         <Button  
    //         // as="div"
    //         // variant="light" 
    //         className={`${triggerClassName}`}
    //             onMouseOver={(e) => {
    //                 // console.log("over", e.currentTarget);
    //                 // if (e.currentTarget.contains(e.relatedTarget as Node)) {
    //                 //     setIsOpen(true);
    //                 // }
    //                 setIsOpen(true)
    //             }}
    //             onMouseLeave={(e) => {
    //                 console.log("leave", e.currentTarget);
    //                 if (!e.currentTarget.contains(e.relatedTarget as Node)) {
    //                     setIsOpen(false);
    //                 }
    //                 // setIsOpen(false)
    //             }}
    //             onClick={(e) => {
    //                 if (e.preventDefault) e.preventDefault();
    //                 if (e.stopPropagation) e.stopPropagation();
    //                 setIsOpen(!isOpen)
    //             }} >
    //             {propsModed.children}

    //         </Button>
    //         {/* {props.children[0]} */}
    //         {/* {childrenWithProps} */}
    //         {/* {props.children}lol */}
    //         {/* <div className={childrenWithProps[0].className}>
    //             {childrenWithProps}
    //         </div > */}
    //     </Tooltip>
    //     // <Tooltip {...props} isOpen={isOpen}>
    //     //     <span className="">
    //     //         {childrenWithProps}
    //     //     </span >
    //     // </Tooltip>
    //     // <Tooltip {...props}  isOpen={isOpen}>
    //     //   {childrenWithProps}
    //     // </Tooltip>
    // );

    return (<>
        {/* <div ref={portalRef} ></div> */}
        <Tooltip {...propsModed} isOpen={isOpen}
            shouldCloseOnBlur={false}
            content={<div 
                // ref={portalRef}
                onMouseLeave={(e) => {
                    // console.log("left container");
                    setIsOpen(false);

                }}>
                {propsModed.content}
            </div>}
        // offset={30}
        // portalContainer={portalRef?.current}
        >
            {/* {childrenWithProps} */}
            <Button 
            variant="light"
            className={`w-auto min-w-0 min-h-0 h-auto p-0 ${triggerClassName}`}
                onMouseOver={() =>
                    setIsOpen(true)

                    // requestAnimationFrame(() => {
                    // })
                }
                onMouseLeave={(e) => {
                    setIsOpen(false);
                    // console.log("leave",
                    //     portalRef?.current?.contains(e.relatedTarget as Node) || 
                    //     e.currentTarget.contains(e.relatedTarget as Node),
                    //     e.relatedTarget, e.currentTarget, portalRef?.current);
                    // // let contains = true;

                    // // if (e.currentTarget?.contains(e.relatedTarget as Node)   || 
                    // if (portalRef?.current?.contains(e.relatedTarget as Node) || e.currentTarget.contains(e.relatedTarget as Node)) {
                    //     setIsOpen(true)
                    // } else {
                    //     setIsOpen(false)
                    // }
                    // if (!triggerRef?.current?.contains(e.relatedTarget as Node)
                    //     && !e.currentTarget.contains(e.relatedTarget as Node)) {
                    //     setIsOpen(false);
                    // }

                    // React.Children.forEach(props.content, (child) => {
                    //     console.log(child);
                    //     child.ref = (child as any).ref || React.createRef();
                    //     const ref = (child as any).ref;
                    //     if (ref.contains(e.relatedTarget as Node)){
                    //         contains = true;
                    //     }
                    // });
                    // const checkContains = (node: React.ReactNode) => {
                    //     // Attempt to find the DOM node associated with this ReactNode
                    //     const domNode = ReactDOM.findDOMNode(node as any);

                    //     // Check if the domNode contains the relatedTarget
                    //     if (domNode && domNode.contains(e.relatedTarget as Node)) {
                    //         contains = true;
                    //     }
                    // };

                    // // If props.content is a single element, check directly
                    // if (React.isValidElement(props.content)) {
                    //     checkContains(props.content);
                    // } else if (Array.isArray(props.content)) {
                    //     // If props.content is an array of elements, check each one
                    //     props.content.forEach(checkContains);
                    // } else {
                    //     // Handle other types of ReactNode (e.g., strings, numbers) if necessary
                    //     // These types don't have a DOM representation and likely don't need handling
                    // }


                    // setIsOpen(contains);
                    // if (!triggerRef?.current?.contains(e.relatedTarget as Node)
                    //     && !e.currentTarget.contains(e.relatedTarget as Node)) {
                    //     setIsOpen(false);
                    // }
                    // setIsOpen(false)
                }}
                onClick={(e) => {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    setIsOpen(!isOpen)
                }} >
                {propsModed.children}

            </Button>
            {/* {props.children[0]} */}
            {/* {childrenWithProps} */}
            {/* {props.children}lol */}
            {/* <div className={childrenWithProps[0].className}>
                {childrenWithProps}
            </div > */}
            {/* </Tooltip >
        // <Tooltip {...props} isOpen={isOpen}>
        //     <span className="">
        //         {childrenWithProps}
        //     </span >
        // </Tooltip>
        // <Tooltip {...props}  isOpen={isOpen}>
        //   {childrenWithProps}
        // </Tooltip> */}

        </Tooltip>
    </>);

    // return (
    //     <Tooltip {...propsModed} isOpen={isOpen}>
    //         {/* {childrenWithProps} */}
    //         <div  className={triggerClassName}
    //             onMouseOver={() => setIsOpen(true)}
    //             onMouseLeave={() => setIsOpen(false)}
    //             onClick={(e) => {
    //                 if (e.preventDefault) e.preventDefault();
    //                 if (e.stopPropagation) e.stopPropagation();
    //                 setIsOpen(!isOpen)
    //             }} >
    //             {propsModed.children}

    //         </div> 
    //         {/* {props.children[0]} */}
    //         {/* {childrenWithProps} */}
    //         {/* {props.children}lol */}
    //         {/* <div className={childrenWithProps[0].className}>
    //             {childrenWithProps}
    //         </div > */}
    //     </Tooltip>
    //     // <Tooltip {...props} isOpen={isOpen}>
    //     //     <span className="">
    //     //         {childrenWithProps}
    //     //     </span >
    //     // </Tooltip>
    //     // <Tooltip {...props}  isOpen={isOpen}>
    //     //   {childrenWithProps}
    //     // </Tooltip>
    // );
}