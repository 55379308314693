
import { Spinner } from "@nextui-org/react";
import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Navigate, Outlet, redirect, useNavigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
    // const { publicKey } = useSelector((state: any) => state.walletBalance);
    const { user, jwt, checked } = useSelector((state: any) => state.userData);
    const navigate = useNavigate();


    // console.log("PROTECTED ROUTES", user, jwt, checked);

    useEffect(() => {
        setTimeout(() => {
            if (checked && (!user || !jwt)) {
                // console.log("navigate to login", navigate);
                navigate("/login", { replace: true });
            }
        });
    }, [user, jwt, checked, navigate]);

    // const [checkTimeout, setCheckTimeout] = useState<any>();
    // const [checked, setChecked] = useState(false);


    // const { betaAccess } = useSelector((state: any) => state.betaData);
    // console.log("HAVE BETA ACCESS", betaAccess);

    // useEffect(() => {
    //     if(!publicKey){
    //         setCheckTimeout(setTimeout(() => {
    //             setChecked(true);
    //         }, 2000));
    //     }else{
    //         setChecked(true);
    //         clearTimeout(checkTimeout);
    //     }
    // }, [publicKey]);

    // return (<>
    //     {checked ? ( publicKey ? children : <Navigate to="/login" replace />) : <Spinner size="lg" />}
    // </>);
    return (<>
        {/* {JSON.stringify({ checked, user, jwt })} */}
        {checked ? (user && jwt ? children :
            <Navigate to="/login" />
            // <>sudas</>
        ) : <Spinner size="lg" />}
    </>);
};

export default ProtectedRoutes;