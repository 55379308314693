import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function LotteriesHistoryPage() {

    const [lotteriesList, setLotteriesList] = useState<any[]>([]);
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();

    const load = () => {
        setLoading(true);

        doGet("lottery/get-history").then((ret) => {
            if (ret.ok) {
                setLotteriesList(ret.data);
            } else {
                setError(ret.error || "An error has occurred.");
            }
            // console.log("Got lotteries", data);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (lotteriesList.length > 0) return;
        load();
    }, [publicKey]);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Finished lotteries</h2>
        <div>
            <TransparentTable
                headers={['ID', "Name", "VSCN"]}
                label="Lottery history"
                isLoading={loading}
                emptyContent={"No lottery history found."}
                error={error}
                load={load}
                data={
                    lotteriesList.map((lottery, idx) => {
                        return ([<Link href={`/lottery/${lottery.id}`} size="sm"  >#{lottery.id}</Link>,
                        lottery?.name,
                        Intl.NumberFormat().format(lottery?.amount)
                        ]);
                    })
                }
            />


            <div className="text-sm m-4">
                Showing up to 200 latest lotteries.
            </div>
        </div>
    </div >
    );
}