
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';

import { useState } from 'react';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';
import anal from '../services/anal';
import bs58 from 'bs58';
import { useLetsTalk } from './useLetsTalk';

//REAL: 4GCJwmLfuk1F6s5Z6TxeLoBYQocaDh9yNLFxPokDHV7r
//DEV: 9k2eYN1VzaQhnZBx1F49rhSKwCeSomF3u12PjAFK1ikL

const VSCN_MINT = new PublicKey(process.env.REACT_APP_VSCN_MINT!);

const useSignMessage = () => {
    const { publicKey, signMessage } = useWallet();
    const {doPost} = useLetsTalk();


    async function notifyServer({ url, toSend, toSendData = null, successMessage }): Promise<{ data } | { error }> {
        const resolveWithSomeData = new Promise<{ data } | { error }>((resolve, reject) => {

            doPost(url, toSend).then((ret) => {
                if (ret.ok) {
                    resolve(ret);
                    anal.event({ category: "signing", action: "doSignMessage_notify_success" });
                } else {
                    console.error("DO SIGN MESSAGE RESPONSE ERROR", ret);
                    anal.event({ category: "signing", action: "doSignMessage_notify_error" });
                    reject({ error: ret.error ? ret.error : JSON.stringify(ret) });
                }
            }).catch((error) => {
                console.error("DO SIGN MESSAGE ERROR (while notifying server)", error);
                reject({ error });
            });

           
        }
        );
        toast.promise(
            resolveWithSomeData,
            {
                pending: "Server is processing the signed message...",
                success: successMessage ? successMessage : "Confirmed!",
                error: {
                    render({ data }: any) {
                        console.error("DO SIGN MESSAGE ERROR (while notifying server) (toast)", data);
                        return `Error while notifying server! ${data?.error ? data?.error.toString() : data?.toString()}`
                    }
                }
            }
        )

        return resolveWithSomeData;
    };



    const doSignMessage = async ({ url, data, toSendData, successMessage }) => {
        try {
            /*
            returns response from server after sending signed message {
            walletId: string,
            signedMessage: {
                ENCODED: data: {
                    walletId: string,
                    duelId: number,
                    rpsActionId: number,
                    hand: string,
                    message: string?
                    nonce: number???
                }
                sig: string
            }

            accepts:
            data: {
                walletId: string,
                duelId: number,
                rpsActionId: number,
                hand: string,
                message: string?
                nonce: number???
            }
            */
            // console.log("doTransaction called", url, transData);
            // const placeBet = useCallback(async (transData) => {
            // toWallet = publicKey;

            let message;
            if (typeof data === 'string') {
                message = data;
            } else {
                data.walletId = publicKey?.toString();
                message = JSON.stringify(data);
            }


            const encodedMessage = new TextEncoder().encode(message);

            const signature = await signMessage?.(encodedMessage);
            if (!signature) {
                console.error('No signature');
                return null;
            }
            let sigHexed = Buffer.from(signature).toString('hex');
            // console.log('Signature:', sigHexed);

            anal.event({ category: "signing", action: "doSignMessage_success" });

            let toSend = {
                walletId: publicKey?.toString(),
                signedMessage: {
                    data: bs58.encode(encodedMessage),
                    sig: bs58.encode(signature)
                },
                data: toSendData
            }

            // console.log("Notifying server about the signed message", toSend);
            return await notifyServer({ url, toSend, toSendData, successMessage });;

        } catch (err: any) {
            console.error('Signing error', err);
            toast.error(`Error while signing message! ${err?.error ? err?.error?.toString() : err?.toString()}`);

            anal.event({ category: "signing", action: "doSignMessage_failed" });

            return null;
            // window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true });
            // setState(`Transaction error ${error}`);
        }
        // }, [publicKey, sendTransaction, connection]);
    };


    return { doSignMessage };
};

export default useSignMessage;