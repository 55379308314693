import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";

export default function MessagePicutrePreview({ img, onClose }: any) {

    const [galleryOpen, setgalleryOpen] = useState(true);

    return (
        <Lightbox  //fullscreen
            open={galleryOpen}
            // index={bigIndex}
            close={
                () => {
                    setgalleryOpen(false);
                    if (onClose) onClose();
                }
            }
            // zoom={{ ref: zoomRef, scrollToZoom: true }}
            // plugins={[Zoom]}
            // slides={ slides }
            // slides={slides.map((i: any) => ({ src: `${baseDir}${i.src}` }))}
            slides={[{ src: img }]}
            carousel={{ 
                finite: true,

            }}
            render={{
                buttonPrev: () => null,
                buttonNext: () => null,
              }}
            // slides={ slides.map((i: any) => ({ src: "/images/"+i.src, alt: title }))}
            controller={{ closeOnBackdropClick: true }}
            styles={{ container: { backgroundColor: "rgba(0, 0, 0, .7)", backdropFilter: "blur(8px)" } }}
            // render={{ slide: SlideImage }}
            // render={{
            //     slide: NextUIImage
            // }}
            on={{
                // view: ({ index: currentIndex }) => {
                //     setBigIndex(currentIndex);
                //     setMiniIndex(currentIndex);
                // }
            }}
        />
    )
}