import { useEffect, useState } from "react";

import '../../css/battle.css';

// import usePlaceBet from "../../hooks/usePlaceBet";

import { socket } from "../../services/socket";
import useBetModal from "../../hooks/useBetModal";

import BattleInsinde from "./BattleInside";
import useDoTransaction from "../../hooks/useDoTransaction";
import MessagesBox from "../messages/MessagesBox";
import { MessageModel } from "../messages/Message";

export default function Battle({ data, animationDelay = "0.0", noMessages = false }: any) {
    const { name1, img1, name2, img2, amount1, amount2, wallet1, tokenWallet1, wallet2, tokenWallet2 } = data;
    const battleId = data.id;

    // const [finished, setFinished] = useState(data.finished || false);
    // const [winner, setWinner] = useState(data.winner || 0);

    // const { publicKey, sendTransaction } = useWallet();


    const [score1, setScore1] = useState(amount1);
    const [score2, setScore2] = useState(amount2);



    // const { state, placeBet } = usePlaceBet();
    const { doTransaction } = useDoTransaction();


    const { ModalComponent, openModal, closeModal } = useBetModal({
        onCloseCallback: () => null,
        onSuccessCallback: async (betData) => {
            // console.log("PLACING BET", betData);
            // let res = await placeBet(betData);
            let res = await doTransaction({ url: "bets/place-bet", transData: betData, successMessage: "Bet confirmed!" });

            if (res) {
                return true;
            } else {

            }
        },
        modalData: {
            betData: {},
        }
    })

    const betButtonClick = async (betData) => {

        openModal(betData);
    };

    // useEffect(() => {
    //     function battleUpdate(data) {
    //         // console.log("battle-update", data);
    //         if (data?.battleId === battleId) {
    //             if (data?.amount1) setScore1(data.amount1);
    //             if (data?.amount2) setScore2(data.amount2);
    //         }
    //     }

    //     socket.on('battle-update', battleUpdate);
    //     // socket.on('battle-finished', battleFinished);

    //     return () => {
    //         socket.off('battle-update', battleUpdate);
    //         // socket.off('battle-finished', battleFinished);

    //     };
    // }, []);



    return (<>
        <BattleInsinde battle={data} betButtonCallback={betButtonClick} />

        {/* {noMessages ? '' : <BattleMessages battleId={battleId} /> } */}
        {!noMessages &&
            <MessagesBox url={`battle/get-messages?battleId=${battleId}`}
                title={"Fighters' Lounge"}
                noMessages={`Place a bet and engrave your words on the chain!`}
                sideFunc={(message: MessageModel) => (message.data.side === 2)}
                socketChannel={"battle-message"}
            />}
        {ModalComponent}
    </>);

}