import { Slider, Tooltip } from "@nextui-org/react";
import { useState } from "react";


export default function EditableSlider(props) {
    const [value, setValue] = useState(props.defaultValue || 0);
    const [inputValue, setInputValue] = useState(props.defaultValue?.toString() || "0");

    const handleChange = (value) => {
        if (isNaN(Number(value))) return;
        if(value > props.maxValue) value = props.maxValue;
        if(value < props.minValue) value = props.minValue;

        setValue(value);
        setInputValue(value.toString());
        props?.onChange(value);
    };

    return (
        <Slider
            {...props}
            renderValue={({ children, ...props }) => (
                <output {...props}>
                    <Tooltip
                        className="text-tiny text-default-500 rounded-md"
                        content="Press Enter to confirm"
                        placement="left"
                    >
                        <input
                            className="px-1 py-0.5 w-20 text-right text-small text-default-700 font-medium bg-default-100 outline-none transition-colors rounded-small border-medium border-transparent hover:border-primary focus:border-primary"
                            type="text"
                            aria-label="Temperature value"
                            value={inputValue}
                            onChange={(e) => {
                                const v = e.target.value;
                                setInputValue(v);
                                // handleChange(Number(v));
                            }}
                            onBlur={(e) => {
                                const v = e.target.value;
                                // setValue(Number(inputValue));
                                handleChange(Number(v));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !isNaN(Number(inputValue))) {
                                    // setValue(Number(inputValue));
                                    handleChange(Number(inputValue));
                                }
                            }}
                        />
                    </Tooltip>
                </output>
            )}
            value={value}
            onChange={handleChange}
        />
    )
}