
import { Dispatch, Store } from 'redux';
import io from 'socket.io-client';
import store from '../store/store';
import { addBattleAction, addDuelAction, addLotteryAction, addMessageAction, removeBattleAction, removeDuelAction, removeLotteryAction, setMaintenanceData, updateBattleAction, updateDuelAction, updateLotteryAction } from '../store/actions/reducerActions';
import { toast } from 'react-toastify';

import sounds from './sounds';
import { useSelector } from 'react-redux';
import { MessageModel } from '../components/messages/Message';



export class SocketSingleton {
    private static instance: SocketSingleton;
    private dispatch?: Dispatch;
    private store?: Store;
    public socket;

    initializedCallbacks = false;




    private constructor() {

        // "undefined" means the URL will be computed from the `window.location` object
        // const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

        this.socket = io(process.env.REACT_APP_API_URL!, {
            transports: ["websocket"],
            reconnection: true
        });

        this.socket.on('connect', () => {
            // this.socket.onAny((event, ...args) => {
            //     console.log(`[SOCKET] Got ${event}`, args);
            // });

            this.initializeCallbacks();
        });



        // socket.on('time', (time)=> {
        //     console.log("Time", time);
        // });

    }

    public static getInstance(): SocketSingleton {
        if (!SocketSingleton.instance) {
            SocketSingleton.instance = new SocketSingleton();
        }
        return SocketSingleton.instance;
    }

    public setDispatch(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }
    public setStore(store: Store) {
        this.store = store
    }

    public initializeCallbacks() {

        if (this.initializedCallbacks) return;
        this.initializedCallbacks = true;

        this.socket.on('reconnect', () => {
            const { user, jwt } = useSelector((state: any) => state.userData);
            if (user && jwt) {
                // console.log("Reconnecting socket, emitting user and jwt", user);
                socket.emit('wallet-connect', user, jwt);
            }
        });
        // this.socket.on('message', (data) => {
        //     if (this.dispatch) {
        //         this.dispatch({ type: 'SOCKET_MESSAGE', payload: data });
        //     }
        // });
        // console.log("initializing socket callbacks");

        //----------------- BATTLE -----------------
        this.socket.on('battle-add', (battle) => {
            // console.log("battle-add received", battle);
            if (this.dispatch) {
                this.dispatch(addBattleAction(battle));
            }
            toast.info(`A new ${battle?.name1} vs ${battle?.name2} battle has begun!`);
            sounds.playNewBattle();
        });

        this.socket.on('battle-update', (battle) => {
            // console.log("socket battle-update received", battle);
            if (this.dispatch) {
                this.dispatch(updateBattleAction(battle));
            }
            if (battle?.finished && battle?.winnerName) {
                toast.info(`Battle #${battle?.id} finished! ${battle?.winnerName} won!`);
            }
        });

        this.socket.on('battle-remove', (data) => {
            if (this.dispatch) {
                this.dispatch(removeBattleAction(data.battleId));
            }
        });


        //----------------- LOTTERY -----------------
        this.socket.on('lottery-add', (lottery) => {
            toast.info(`A new lottery ${lottery?.name} has started!`);
            // console.log("lottery-add received", lottery);
            if (this.dispatch) {
                this.dispatch(addLotteryAction(lottery));
            }
        });

        this.socket.on('lottery-update', (lottery) => {
            // console.log("lottery-update received", lottery);
            if (this.dispatch) {
                this.dispatch(updateLotteryAction(lottery));
            }
            if (lottery?.finished && lottery?.winner) {
                toast.info(`Lottery #${lottery?.id} ended! The winner is ${lottery?.winner}!`);
            }
        });

        this.socket.on('lottery-remove', (data) => {
            if (this.dispatch) {
                this.dispatch(removeLotteryAction(data.lotteryId));
            }
        });

        //----------------- DUEL -----------------
        this.socket.on('duel-add', (duel) => {
            toast.info(`A new duel "${duel?.name}" has been created!`);
            // console.log("duel-add received", duel);
            if (this.dispatch) {
                this.dispatch(addDuelAction(duel));
            }
        });

        this.socket.on('duel-update', (duel) => {
            // console.log("duel-update received", duel);

            if (this.store) {
                const state = this.store.getState();
                const duelFound = state.duelsData?.duels?.find((d: any) => d.id === duel?.id);
                const thisUser = state.userData?.user?.wallet;
                if (duelFound && !duelFound.player2 && duel.player1 == thisUser) {
                    // console.log("Duel found without player2 and p1 is our user", duelFound);
                    // if(duel.player2){
                    //     console.log("Will udpate duel with player2, lets play the sound", duel.player2);
                    //     sounds.playOpponentJoined();
                    // }
                }
            }

            if (this.dispatch) {
                this.dispatch(updateDuelAction(duel));
            }
            // if (duel?.finished && duel?.winner) {
            //     toast.info(`Duel #${duel?.id} ended! The winner is ${duel?.winner}!`);
            // }
        });

        this.socket.on('duel-remove', (data) => {
            if (this.dispatch) {
                this.dispatch(removeDuelAction(data.duelId));
            }
        });



        //----------------- PAYOUTS -----------------
        // function payoutSent(data) {
        //   console.log("Payout sent", data);
        // }
        this.socket.on('payout-sent', (data) => {
            // console.log("payout-sent received", data);
            if (data.amount) {
                toast.success(`A payout of ${Intl.NumberFormat().format(data.amount)} VSCN has been sent to your wallet!`);
            }
            sounds.playPayoutSent();
        });


        //----------------- MAINTENANCE -----------------
        this.socket.on('maintenance', (data) => {
            // console.log("Maintenance", data);
            if (this.dispatch) {
                this.dispatch(setMaintenanceData(data || { enabled: false, starts: undefined, message: undefined }));
            }
        });

        // console.log("SOCKET CALLBACKS", this.initializedCallbacks, this.socket.listeners("battle-update"));

        //----------------- CHAT -----------------
        this.socket.on('chat-message-add', (msg: MessageModel) => {
            // console.log("chat-message-add received", msg);
            if (this.dispatch) {
                this.dispatch(addMessageAction(msg));
                sounds.playChatMessage();
            }
        });

        this.socket.on('chat-message-update', (msg: MessageModel) => {
            // console.log("chat-message-update received", msg);
            if (this.dispatch) {
                this.dispatch(updateDuelAction(msg));
            }
        });

        this.socket.on('chat-message-remove', (data) => {
            if (this.dispatch) {
                this.dispatch(removeDuelAction(data.messageId));
            }
        });
    }
}



// export const socketSingleton = SocketSingleton.getInstance();

export const socket = SocketSingleton.getInstance().socket;