
const makeYourMove = [
    'make_your_move.mp3', 'hey_make_your_move_times_running_out.mp3', "hey_make_your_move.mp3"
];



const swings = Array(7).fill(0).map((_, i) => `swing${i + 1}.mp3`);
const watterSplashes = Array(3).fill(0).map((_, i) => `splash${i + 1}.mp3`);
const explosions = Array(3).fill(0).map((_, i) => `explosion${i + 1}.mp3`);


class SoundsSingleton {
    settings: any = {};
    constructor() {
        this.readSettings();
    }

    //------BATTLES----
    async playNewBattle() {
        if (this.settings?.battles === false) return;
        return this.playSound('new_battle.mp3');
    }

    async playSwing() {
        if (this.settings?.battlesSwings === false) return;
        return this.playSound(swings[Math.floor(Math.random() * swings.length)]);
    }

    //------DUELS-----
    async playMakeYourMove() {
        if (this.settings?.duels === false) return;
        return this.playSound(makeYourMove[Math.floor(Math.random() * makeYourMove.length)]);
    }

    async playOpponentJoined() {
        if (this.settings?.duels === false) return;
        return this.playSound('opponent_joined.mp3');
    }

    async playYouJoinedDuel() {
        if (this.settings?.duels === false) return;
        return this.playSound('you_joined_duel.mp3');
    }

    //Minefield
    async playMinefieldExplosion() {
        if (this.settings?.duels === false) return;
        return this.playSound('minefield_explosion.mp3');
    }

    //Battleships
    async playBattleshipsFire(){
        if (this.settings?.duels === false) return;
        return this.playSound('cannon-fire.mp3');
    }

    async playBattleshipsSplash(){
        if (this.settings?.duels === false) return;
        return this.playSound(watterSplashes[Math.floor(Math.random() * watterSplashes.length)]);
    }

    async playBattleshipsExplosion(){
        if (this.settings?.duels === false) return;
        return this.playSound(explosions[Math.floor(Math.random() * explosions.length)]);
    }

    //-------CHAT------
    async playChatMessage() {
        if (this.settings?.chat === false) return;
        return this.playSound('chat.mp3');
    }

    //------PAYOUT----
    async playPayoutSent() {
        if (this.settings?.payoutSent === false) return;
        return this.playSound('payout_sent.mp3');
    }

    async playSound(sound: string) {
        if (this.settings?.disabled) return;

        try {
            let audio = new Audio(`/sounds/${sound}`);
            if (this.settings?.volume) {
                audio.volume = this.settings.volume;
            }
            audio.play();

            setTimeout(() => {
                audio.remove();
            }, 30 * 1000);
            return audio;
        } catch (e) {
            console.error("Error playing sound", sound, e);
        }
    }

    readSettings() {
        let stored = localStorage.getItem("VSCN_SoundSettings");
        if (stored) {
            this.settings = JSON.parse(stored);
        }
    }
    saveSettings() {
        localStorage.setItem("VSCN_SoundSettings", JSON.stringify(this.settings));
    }
    saveSetting(key: string, value: any) {
        this.settings[key] = value;
        this.saveSettings();
    }
}

const sounds = new SoundsSingleton();
export default sounds;