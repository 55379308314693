import { useEffect, useState } from "react";
import BattleTimer from "../../battle/BattleTimer";
import { Button, Card } from "@nextui-org/react";
// import AmountChangeNotifier from "../AmountChangeNotifier";
import InfoDuelTooltip from "../../info-tooltip/InfoDuelTooltip";
import VSCNCoin from "../../small/VSCNCoin";
import { useSelector } from "react-redux";
import DuelInside from "../DuelInside";
import useDoTransaction from "../../../hooks/useDoTransaction";
import RPSHandsHistory from "./RPSHandsHistory";
import RPSActionsHistory from "./RPSHandsHistory";
import RPSCurrentHand from "./RPSCurrentHand";
import { toast } from "react-toastify";
import DuelScore from "../DuelScore";
import DuelTurnTimer from "../DuelTurnTimer";



function whichPlayer(duel, publicKey) {
    if (duel.player1 == publicKey) return 1;
    if (duel.player2 == publicKey) return 2;
    return 0;
}

export default function RPSDuel({ duel, small, className = "" }: any) {
    // const { id, type, name, player1, player2, typeName, scoreToWin, waitLength, img, amount, wallet, tokenWallet } = duel;

    // const [shouldShake, setShouldShake] = useState(false);

    const [finished, setFinished] = useState(duel.finished || false);
    const [winner, setWinner] = useState(duel.winner || 0);

    // const { publicKey } = useSelector((state: any) => state.walletBalance);
    const {user} = useSelector((state: any) => state.userData);


    const { doTransaction } = useDoTransaction();


    useEffect(() => {
        if (duel.finished) setFinished(duel.finished);
    }, [duel.finished]);
    useEffect(() => {
        if (duel.winner) setWinner(duel.winner);
    }, [duel.winner]);

    // useEffect(() => {
    //     setShouldShake(true);
    //     const timer = setTimeout(() => setShouldShake(false), 300); 
    //     return () => clearTimeout(timer); 
    // }, [duel.amount]); 

    const handleTimeEnd = () => {
        setFinished(true);
    }


    const checkBeforeTransaction = async () => {
        // console.log("checkBeforeTransaction", duel.player2);
        if (duel.player2) {
            // console.log("dont allow");
            toast.error("This duel already has an opponent! You were too slow.");
            return false;
        } else {
            return true;
        }
    }

    const participateInDuelCallback = async () => {
        if (!user) {
            toast.error("Please connect your wallet first!");
            return;
        }

        let transData = {
            amount: duel.amount,
            duelId: duel.id,
            wallet: user?.wallet,
            toWallet: duel.wallet
        }
        let res = await doTransaction({ url: "duel/join", transData, successMessage: "Duel joined!", checkBeforeTransaction });

        if (res) {
            return true;
        } else {

        }
    }

    return (
        <div className="w-full">
            <DuelInside duel={duel} small={small} participateInDuelCallback={participateInDuelCallback} className={className} />

            {!duel.player2 && !duel.finished && <div className="text-center font-bold m-8">Waiting for an opponent...</div>}

            {/* <DuelTurnTimer 
            // endsAt={duel.endsAt} 
            endsAt={ new Date(new Date().getTime() + 1000 * 10)}
            /> */}


            {!duel.winner && !duel.finished && duel.RPSAction?.length > 0 &&
                <>
                    
                    <RPSCurrentHand
                        action={duel.RPSAction.reduce((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? a : b)}
                        endsAt={duel.endsAt}
                        whichPlayer={whichPlayer(duel, user?.wallet)} />
                </>
            }

            {duel.RPSAction?.length > 0 && <DuelScore score1={duel.RPSAction?.filter(x => x.winner === 1)?.length} score2={duel.RPSAction?.filter(x => x.winner === 2)?.length} scoreToWin={duel.scoreToWin} />}

            {duel.player2 && <RPSActionsHistory actions={duel.RPSAction} finished={duel.finished} />}
        </div>
    );
}