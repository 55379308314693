import { Button, Card, Spinner, Tooltip } from "@nextui-org/react";
import { handImages } from "./hands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faGhost, faMessage } from "@fortawesome/free-solid-svg-icons";
import { greenGlow, redGlow } from "../../../helpers/glows";
import ToolTipFix from "../../extensions/TooltipFix";
import { CardNice } from "../../small/CardNice";


function HandInside({ hand, message = undefined, winner = false, finished = false, right = false }) {
    return (
        <div className="flex flex-col max-w-32 max-h-16 text-center items-center">
            {!hand && !finished &&
                <Spinner size="md" color="white" label="Waiting..."></Spinner>
            }

            {!hand && finished &&
                <><FontAwesomeIcon className="max-w-16 max-h-8 w-full h-full m-1" icon={faGhost} />
                    Timed out
                </>
            }

            {hand && hand === "x" &&

                <><FontAwesomeIcon className="max-w-16 max-h-8 w-full h-full m-1" icon={faCircleQuestion} />
                    Hidden
                </>
            }

            {hand && hand !== "x" &&
                <div className="relative">
                    <img src={handImages[hand]?.img} alt={handImages[hand]?.name} className="w-12 h-12"
                        style={{
                            ...(winner ? greenGlow : redGlow),
                            ...(right ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" } : {})
                        }} />

                    {message &&
                        <FontAwesomeIcon className="absolute text-sm text-primary-500" icon={faMessage}
                            style={{ bottom: '-0.15em', right: '0' }} />
                    }
                </div>
            }
        </div>
    );
}

/*              <div className="flex flex-col  max-w-16 max-h-16 text-center items-center m-auto">
            {!props.hand && <Spinner size="md" color="white" label="Waiting..."></Spinner>}
            {props.hand && props.hand === "x" && <><FontAwesomeIcon className="max-w-8 max-h-8 w-full h-full m-1" icon={faCircleQuestion} />Hidden</>}
            {props.hand && props.hand !== "x" && <img src={handImages[props.hand]?.img} alt={handImages[props.hand]?.name} className="w-12 h-12"
                style={{ ...(props.winner ? greenGlow : redGlow) }} />}
        </div>
        */
function Hand(props) {
    return (<>

        {
            props?.message ?
                <ToolTipFix triggerClassName="m-auto" showArrow={true} content={<div className="text-md">{props?.message}</div>}>
                    <HandInside {...props} />
                    {/* <div className="m-auto"><HandInside {...props} /></div> */}
                    {/* <Button className="m-auto" isIconOnly  variant="flat"><HandInside {...props} /></Button> */}
                </ToolTipFix >
                :
                <HandInside {...props} />

        } </>);
}

export default function RPSActionsHistory({ actions, finished = false }: any) {
    return (
        <CardNice
            className="mt-4 w-full max-w-xl"
        // isBlurred
        // className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10  p-2 `}
        // shadow="sm"
        >

            <h3 className="text-xl m-2 font-bold">Hand history</h3>
            <div className="text-sm">Total {actions?.length} rounds</div>
            {(!actions || actions.length < 1) && <div className="text-sm m-1">No hands played yet.</div>}
            {actions && actions.map((action: any, i: number) => {
                return (
                    <div key={action.id || i} className="flex flex-col items-center my-3">
                        {/* <div className="text-tiny opacity-70 m-0">{action.id}</div> */}
                        <div className="flex flex-row gap-4 w-full  max-w-64  justify-around  grid grid-cols-2 justify-evenly">
                            <Hand hand={action.hand1} winner={action.winner === 1} message={action.message1} finished={finished} />
                            <Hand hand={action.hand2} winner={action.winner === 2} message={action.message2} finished={finished} right={true} />

                        </div>
                    </div>
                );
            })}
        </CardNice>
    );
}