import Position from "./position";
import { BATTLEFIELD_SIZE } from "./ship";
// import { BATTLEFIELD_SIZE } from "../constants";
import { ICell, IShip } from "./types";


export enum CellStates {
  Empty = 0,
  Filled = 1,
  Injured = 2,
  Destroyed = 3,
  Inactive = 4,
  Open = 5
}

class Cell extends Position implements ICell {
  currentShipId = -1;
  currentState = CellStates.Empty;

  get state() {
    return this.currentState;
  }

  get shipId() {
    return this.currentShipId;
  }

  set state(value: CellStates) {
    this.currentState = value;
  }

  set shipId(value: number) {
    this.currentShipId = value;
  }

  isFilled(): boolean {
    return this.shipId !== -1;
  }

  isInactive(): boolean {
    return this.state === CellStates.Inactive;
  }

  isOpen(): boolean {
    return this.state === CellStates.Open;
  }

  isDamaged(): boolean {
    return (
      this.state === CellStates.Injured || this.state === CellStates.Destroyed
    );
  }

  static generate = ():Map<string, Cell> => {
    const result = new Map();

    for (let y = 1; y < BATTLEFIELD_SIZE + 1; ++y) {
      for (let x = 1; x < BATTLEFIELD_SIZE + 1; ++x) {
        result.set(`${x}:${y}`, new Cell(x, y));
      }
    }

    return result;
  };

  static resetCells = (cells: Map<string, ICell>) => {
    cells.forEach((cell: ICell) => {
      cell.shipId = -1;
      cell.state = CellStates.Empty;
    });
  };

  static updateCells = (cells: Map<string, ICell>, ships: IShip[]) => {
    // console.log("updating cells", cells, ships);
    for (let i = 0; i < ships.length; ++i) {
      for (const position of ships[i].positions()) {
        const cell = cells.get(`${position.x}:${position.y}`);

        if (cell) {
          cell.shipId = ships[i].id;
        }
      }

      if (ships[i].isDragging) {

      }else{
        for (const position of ships[i].borders()) {
          const cell = cells.get(position.key);

          if (cell) {
            cell.state = CellStates.Inactive;
          }
        }
      }
      
    }
    // console.log("done updating cells", cells, ships);
  };

  static updateCellsRemoveShips = (cells: Map<string, ICell>, ships: IShip[]) => {
    // console.log("updating cells", cells, ships);
    for (let i = 0; i < ships.length; ++i) {
      for (const position of ships[i].positions()) {
        const cell = cells.get(`${position.x}:${position.y}`);

        if (cell) {
          cell.shipId = -1;
        }
      }

      if (ships[i].isDragging) {

      }else{
        for (const position of ships[i].borders()) {
          const cell = cells.get(position.key);

          if (cell) {
            cell.state = CellStates.Empty;
          }
        }
      }
      
    }
    // console.log("done updating cells", cells, ships);
  };
}

export default Cell;
