

export const tableClassNames = {
    wrapper: "bg-background/50 dark:bg-default-100/50",
    table: "bg-transparent",
    tbody: "bg-transparent",
    tr: "bg-transparent",
    thead: "bg-transparent",
    // base: "bg-background/50 dark:bg-default-100/30 text-white",
    td: "bg-transparent",
    th: "bg-background/50 dark:bg-default-100/30  text-white",
    emptyWrapper: " text-white",
}