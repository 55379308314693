
import {
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';


import '../../css/walletAdapter.css';


// const RPC_ENDPOINT =  "https://mainnet.helius-rpc.com/?api-key=d8db01ac-c4a2-4f8e-9fac-e085fdb9f52c";

// "https://devapi.vscn.bet/"




// const ConnectWalletHOC: FC = () => {
//     // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
//     // const network = WalletAdapterNetwork.Devnet;
//     const network = process.env.REACT_APP_RPC_ENDPOINT!; // process.env.REACT_APP_RPC_ENDPOINT;

//     // "https://rpc.ankr.com/solana_devnet"; 
//     // You can also provide a custom RPC endpoint.
//     const endpoint = network; //clusterApiUrl(network);// RPC_ENDPOINT;
//     // "https://rpc.ankr.com/solana_devnet/5b9c0d4b44dd3051f514198aed2a5f1f14f9be44eb224ad8aa9f632eb485eb19";
//     // "https://lb.drpc.org/ogrpc?network=solana&dkey=AsvekokgpEsencwB14CZTSstfTKV8lkR7q5xpjfDdkV6"; // useMemo(() => clusterApiUrl(network), [network]);

//     console.log("endpoint", endpoint);
//     const wallets = useMemo(
//         () => [
//             /**
//              * Wallets that implement either of these standards will be available automatically.
//              *
//              *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
//              *     (https://github.com/solana-mobile/mobile-wallet-adapter)
//              *   - Solana Wallet Standard
//              *     (https://github.com/anza-xyz/wallet-standard)
//              *
//              * If you wish to support a wallet that supports neither of those standards,
//              * instantiate its legacy wallet adapter here. Common legacy adapters can be found
//              * in the npm package `@solana/wallet-adapter-wallets`.
//              */
//             // new UnsafeBurnerWalletAdapter(),
//         ],
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//         [network]
//     );

//     return (

//         <ConnectionProvider endpoint={endpoint}>
//             <WalletProvider wallets={wallets} autoConnect>
//                 <WalletModalProvider>
//                     <Card isBlurred
//                         className="m-auto mt-4 border-none bg-background/50 dark:bg-default-100/20 max-w-md"
//                         shadow="sm">
//                         <div className="App-header">
//                             <img src="/logo.png" className="App-logo" alt="logo" />
//                             <p>
//                                 Hello!
//                             </p>

//                             <WalletMultiButton />
//                             <WalletDisconnectButton />
//                             { /* Your app's components go here, nested within the context providers. */}
//                             <br /><br />
//                             {/* <SendSOLToRandomAddress /> */}
//                             <br />
//                             <WalletBalance />

//                         </div>

//                     </Card>




//                 </WalletModalProvider>
//             </WalletProvider>
//         </ConnectionProvider>
//     );
// };

// export const ConnectWallet = React.memo(ConnectWalletHOC);


export const ConnectWalletBox = () => {

    // const { publicKey } = useWallet();

    return (
        // <Card isBlurred
        //     className="m-auto mt-4 border-none bg-transparent max-w-md"
        //     shadow="none">
        <div className="mt-8">

            <WalletMultiButton 
            
            
           
             />

          
                {/* <WalletDisconnectButton  />
                <br /><br />
                <br />
                <WalletBalance />
                <br /> */}
            
        </div>

    );
}