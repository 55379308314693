import { Card, Divider, Input } from "@nextui-org/react";
import Battle from "../components/battle/Battle";

import { useState } from "react";


export default function CreateBattlePage() {
    const [battle, setBattle] = useState<any>({
        amount1: 50,
        amount2: 50,
    });

    const handleFileChange = (file: Blob, key: 'img1' | 'img2') => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setBattle({ ...battle, [key]: reader.result });
        };
        reader.readAsDataURL(file);
    };


    return (<>
        <div className="mb-12">This is a helper to test image and name sizes. That's all.</div>
        <Card isBlurred
            className="text-center m-auto w-auto max-w-xl border-none bg-background/50 dark:bg-default-100/20 p-2 "
            shadow="sm">
            <Battle data={battle} noMessages={true} />
        </Card>

        <br />
        <Divider className="mt-12" />

        <Card isBlurred className="mt-8 text-center m-auto w-auto max-w-xl border-none bg-background/50 dark:bg-default-100/20 p-2 ">
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                <Input type="text" label="Name 1"
                    classNames={{
                        label: "text-black/50 dark:text-white/90",
                        input: [
                            "bg-transparent",
                            "text-black/90 dark:text-white/90",
                            "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                        ],
                        innerWrapper: "bg-transparent",
                        inputWrapper: [
                            "shadow-xl",
                            "bg-default-200/50",
                            "dark:bg-default/60",
                            "backdrop-blur-xl",
                            "backdrop-saturate-200",
                            "hover:bg-default-200/70",
                            "dark:hover:bg-default/70",
                            "group-data-[focused=true]:bg-default-200/50",
                            "dark:group-data-[focused=true]:bg-default/60",
                            "!cursor-text",
                        ],
                    }}
                    onChange={(e) => setBattle({ ...battle, name1: e.target.value })} />
                <Input type="text" label="Name 2"
                    onChange={(e) => setBattle({ ...battle, name2: e.target.value })} />
            </div>
            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                <input type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            handleFileChange(e.target.files[0], 'img1');
                        }
                    }} />
                <input type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            handleFileChange(e.target.files[0], 'img2');
                        }
                    }} />
            </div>
            <Input type="text" label="BattleId"
                onChange={(e) => setBattle({ ...battle, id: e.target.value })} />

            <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                <Input type="text" label="Amount 1"
                    onChange={(e) => setBattle({ ...battle, amount1: Number.parseInt(e.target.value) })} />
                <Input type="text" label="Amount 2"
                    onChange={(e) => setBattle({ ...battle, amount2: Number.parseInt(e.target.value) })} />
            </div>
        </Card>
    </>);

}