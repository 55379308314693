import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Slider, Switch } from "@nextui-org/react";
import { useState } from "react";
import sounds from "../../services/sounds";


export function SoundSettings({ className = "" }) {
    const [muted, setMuted] = useState(sounds.settings?.disabled || false);
    const [volume, setVolume] = useState(sounds.settings?.volume || 1.0);
    const [chat, setChat] = useState(sounds.settings?.chat || true);
    const [battles, setBattles] = useState(sounds.settings?.battles || true);
    const [battlesSwings, setBattlesSwings] = useState(sounds.settings?.battlesSwings || true);
    const [duels, setDuels] = useState(sounds.settings?.duels || true);
    const [payouts, setPayouts] = useState(sounds.settings?.payouts || true);

    return (
        <div className={className}>
            <Dropdown placement="bottom" showArrow={true} closeOnSelect={false} className="bg-transparent/80 " >
                <DropdownTrigger>
                    <FontAwesomeIcon className="cursor-pointer" icon={muted ? faVolumeXmark : faVolumeHigh} />
                </DropdownTrigger>
                <DropdownMenu aria-label="Sound Settings" variant="flat">
                    <DropdownSection >
                        <DropdownItem key="disabled" textValue="Disable all sounds" >
                            <Switch size="sm" defaultSelected isSelected={muted} onValueChange={(isSelected) => {
                                setMuted(isSelected);
                                sounds.saveSetting("disabled", isSelected);
                            }}>Mute everything</Switch >
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection showDivider>
                        <DropdownItem key="volume" textValue="Volume">
                            <Slider
                                label="Volume"
                                size="sm"
                                step={0.01}
                                maxValue={1}
                                minValue={0}
                                defaultValue={volume}
                                onChange={(value) => {
                                    setVolume(value);
                                    sounds.saveSetting("volume", value);
                                }}
                            //   className="max-w-md"
                            />
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection >
                        <DropdownItem key="chat" textValue="Chat sounds" >
                            <Switch size="sm" defaultSelected isSelected={chat} onValueChange={(isSelected) => {
                                setChat(isSelected);
                                sounds.saveSetting("chat", isSelected);
                            }}>New chat message</Switch >
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection >
                        <DropdownItem key="battles" textValue="New battle" >
                            <Switch size="sm" defaultSelected isSelected={chat} onValueChange={(isSelected) => {
                                setBattles(isSelected);
                                sounds.saveSetting("battles", isSelected);
                            }}>New battle</Switch >
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection >
                        <DropdownItem key="battlesSwings" textValue="Battle attacks" >
                            <Switch size="sm" defaultSelected isSelected={chat} onValueChange={(isSelected) => {
                                setBattlesSwings(isSelected);
                                sounds.saveSetting("battlesSwings", isSelected);
                            }}>Battle attacks</Switch >
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection >
                        <DropdownItem key="duels" textValue="Duel sounds" >
                            <Switch size="sm" defaultSelected isSelected={duels} onValueChange={(isSelected) => {
                                setDuels(isSelected);
                                sounds.saveSetting("duels", isSelected);
                            }}>Duel notifications</Switch >
                        </DropdownItem>
                    </DropdownSection>

                    <DropdownSection >
                        <DropdownItem key="payouts" textValue="Payout notification" >
                            <Switch size="sm" defaultSelected isSelected={payouts} onValueChange={(isSelected) => {
                                setPayouts(isSelected);
                                sounds.saveSetting("payouts", isSelected);
                            }}>Payouts</Switch >
                        </DropdownItem>
                    </DropdownSection>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}