import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { tableClassNames } from "../../helpers/table-classnames";



export default function TransparentTable({ headers, data, label, isLoading, emptyContent, load = () => { }, error = undefined, }) {
    return (
        <Table className="m-auto min-w-64 max-w-fit" aria-label={label} classNames={tableClassNames}>
            <TableHeader>
                {headers.map((header, idx) => (
                    <TableColumn key={idx}>{header}</TableColumn>
                ))}
            </TableHeader>
            <TableBody
                className="text-justify"
                emptyContent={
                    error ? <div>
                        <div className="text-red-400">{error}</div>
                        <br />
                        {load && <Button color="primary" onClick={() => load()}>Retry</Button>}
                    </div>
                        : emptyContent
                }
                isLoading={isLoading}
                loadingContent={<Spinner color="primary" size="lg" label="Loading..." />}
            >
                {
                    data.map((row, idx) => {
                        return (
                            <TableRow key={idx} className="text-left">
                                {row.map((cell, idy) => (
                                    <TableCell key={idy}>{cell}</TableCell>
                                ))}
                            </TableRow>);
                    })
                }
            </TableBody>
        </Table>
    );
}