import React, { useEffect, useMemo, useState } from 'react';

import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle, NextUIProvider, Spinner } from "@nextui-org/react";
import { socket } from './services/socket';
import { Bounce, ToastContainer, toast } from 'react-toastify';
// import useWalletBalance from './hooks/useWalletBalance';
// import { Helmet } from "react-helmet";


import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";


import BattlePage from './routes/battle';
import ProtectedRoutes from './components/layout/ProtectedRoutes';
import BetsPage from './routes/data/bets';
import PayoutsPage from './routes/data/payouts';
import InfoPage from './routes/info';
import HomePage from './routes/home';
import { AppHeader } from './components/layout/Header';

// require('./App.css');
import './App.css';
import LoginPage from './routes/login';
import { AppFooter } from './components/layout/Footer';
import LotteryPage from './routes/lottery';
import VSCNCoin from './components/small/VSCNCoin';
import BattleHistoryPage from './routes/data/battle-history';
import TicketsPage from './routes/data/tickets';
import LotteriesHistoryPage from './routes/data/lotteries-history';
import anal from './services/anal';
import GetVSCNPage from './routes/get-vscn';
import CreateBattlePage from './routes/create-battle';
import YourDuelsPage from './routes/data/your-duels';
import DuelsPage from './routes/duels/duels';
import { WalletContextProvider } from './components/auth/WalletContextProvider';
import { AuthProvider } from './hooks/useAuth';
import ChatBox from './components/messages/ChatBox';
import DuelPage from './routes/duels/duel';
import EarnPage from './routes/earn/cip';
import StatsPage from './routes/data/stats';
import AdminPage from './routes/admin';
import CIPPage from './routes/earn/cip';
import StakePage from './routes/earn/stake';
import BattlesPage from './routes/battles';
import BattleshipsDuel from './components/duel/battleships/BattleshipsDuel';

import "yet-another-react-lightbox/styles.css";



// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');
require("react-toastify/dist/ReactToastify.css");



const menuItems = [
  { t: "Home", href: "/" },
  {
    d: "Play", children: [
      { t: "Duels", href: "/duels" },
      { t: "Battles", href: "/battles" },
    ]
  },
  {
    d: "Data", loggedIn: true, children: [
      { t: "Bets", loggedIn: true, href: "/bets" },
      { t: "Duels", loggedIn: true, href: "/your-duels" },
      { t: "Lottery Tickets", loggedIn: true, href: "/tickets" },
      { t: "Payouts", loggedIn: true, href: "/payouts" },
      { t: "Battles history", loggedIn: true, href: "/battles-history" },
      { t: "Lotteries history", loggedIn: true, href: "/lotteries-history" },
      // { t: "Stats", href: "/stats" },
    ]
  },
  { t: "Info", href: "/info" },
  {
    d: "Earn", glow: true, children: [
      { t: "Promote", href: "/earn/cip" },
      { t: "Stake VSCN", href: "/earn/stake" },
    ]
  },
  { t: "Coin", href: "https://vscn.bet", target: "_blank" },
  { t: <>Get VSCN <VSCNCoin /></>, href: "/get-vscn", },

];
const otherRouteNames = [
  { t: "Battle", href: "/battle/" },
  { t: "Lottery", href: "/lottery/" },
];



function App() {
  // const [fooEvents, setFooEvents] = useState([]);

  const navigate = useNavigate();

  const [currentTitle, setCurrentTitle] = useState("Events");
  const location = useLocation();
  useEffect(() => {
    // console.log("LOCATION ", location, location.pathname);
    const regex = /\/([^\/]+)/;
    let itm;
    for (let x of menuItems) {
      if (x.href && location?.pathname?.match(regex)?.[1] === x.href?.match(regex)?.[1]) {
        itm = x;
        break;
      }
      if (x.children) {
        let child = x.children.find(y => y.href === location.pathname);
        if (child) {
          itm = child;
          break;
        }
      }
    }
    let t = itm?.t;
    // console.log("itm", itm);

    let title = (t && typeof t == "string") ? t : "VersusCoin Battles";
    setCurrentTitle(title);

    let fromOther = otherRouteNames.find(x => location.pathname.startsWith(x.href));
    if (title && !fromOther) {
      document.title = `${title} - VersusCoin Battles - vscn.bet`;
    }

    if (location.pathname) {
      anal.pageView(location.pathname, t);
    }

  }, [location]);

  useEffect(() => {
    // 1. Check URL for Query Parameter
    const params = new URLSearchParams(window.location.search);
    const referral = params.get('r');

    if (referral) {
      // 2. Create a Cookie
      const cookieValue = encodeURIComponent(referral);
      const domain = '.vscn.bet'; //'.vscn.bet';
      const maxAge = 60 * 60 * 24 * 14; // 14 days in seconds

      // 3. Set Cookie Attributes
      document.cookie = `referral=${cookieValue}; max-age=${maxAge}; domain=${domain}; path=/; SameSite=Lax; Secure`;

      // Note: 'Secure' flag ensures the cookie is only sent over HTTPS. 'SameSite=Lax' is a good default to prevent CSRF attacks.
    }
  }, []);


  const [isConnected, setIsConnected] = useState(false);


  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      console.log("Sockect disconnected!", socket.connected);
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);


  // const { pathname } = useLocation();



  return (
    <NextUIProvider navigate={navigate}>
      <div className="fixed top-0 w-full h-full gradientBg"></div>
      {/* dark text-foreground bg-background */}
      <main className="dark text-foreground bg-background min-h-screen min-w-screen  text-center overflow-x-hidden">
        <WalletContextProvider>
          <AuthProvider>


            <AppHeader isConnected={isConnected} menuItems={menuItems} />

            <Card
              className="m-auto mt-4 border-none bg-transparent p-2 "
              shadow="none">


              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/battles" element={<BattlesPage />} />
                <Route path="/duels" element={<DuelsPage />} />
                <Route path="/login" element={<LoginPage />} />

                <Route path="/battle/:id" element={
                  <BattlePage />
                } />
                <Route path="/lottery/:id" element={
                  <LotteryPage />
                } />
                {/* <Route path="duel/rps/:id" element={
                  <RPSDuelPage />
                } />
                <Route path="duel/coin/:id" element={
                  <CoinFlipDuelPage />
                } />
                <Route path="duel/minefield/:id" element={
                  <MinefieldDuelPage />
                } /> */}
                <Route path="duel/rps/:id" element={
                  <DuelPage type="rps" />
                } />
                <Route path="duel/coin/:id" element={
                  <DuelPage type="coin" />
                } />
                <Route path="duel/minefield/:id" element={
                  <DuelPage type="minefield" />
                } />
                <Route path="duel/battleships/:id" element={
                  <DuelPage type="battleships" />
                } />


                <Route path="/bets" element={
                  <ProtectedRoutes>
                    <BetsPage />
                  </ProtectedRoutes>
                } />
                <Route path="/your-duels" element={
                  <ProtectedRoutes>
                    <YourDuelsPage />
                  </ProtectedRoutes>
                } />
                <Route path="/tickets" element={
                  <ProtectedRoutes>
                    <TicketsPage />
                  </ProtectedRoutes>
                } />
                <Route path="/payouts" element={
                  <ProtectedRoutes>
                    <PayoutsPage />
                  </ProtectedRoutes>
                } />
                <Route path="/battles-history" element={
                  <ProtectedRoutes>
                    <BattleHistoryPage />
                  </ProtectedRoutes>
                } />
                <Route path="/lotteries-history" element={
                  <ProtectedRoutes>
                    <LotteriesHistoryPage />
                  </ProtectedRoutes>
                } />
                <Route path="/stats" element={
                  <StatsPage />
                } />

                <Route path="/info" element={<InfoPage />} />
                <Route path="/earn/cip" element={<CIPPage />} />
                <Route path="/earn/stake" element={<StakePage />} />

                <Route path="/get-vscn" element={<GetVSCNPage />} />


                {/* dev shit */}
                <Route path="/create-battle" element={
                  <ProtectedRoutes>
                    <CreateBattlePage />
                  </ProtectedRoutes>
                } />

                <Route path="/admin" element={
                  <ProtectedRoutes>
                    <AdminPage />
                  </ProtectedRoutes>
                } />

              </Routes>
            </Card>

            <AppFooter />

            <ChatBox />

            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              transition={Bounce}
            />

          </AuthProvider>
        </WalletContextProvider>
      </main >
    </NextUIProvider >
  );
}

export default App;
