import anal from "../../services/anal";

const initialState = {
    balanceSOL: 0,
    balanceVSCN: 0
}

export const WALLET_REDUCER_TYPES = {
    SET_BALANCE: "SET_BALANCE",
    SET_BALANCE_GETTING: "SET_BALANCE_GETTING"
}

const walletReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case WALLET_REDUCER_TYPES.SET_BALANCE:
            // Cia apacioj yra taspats kas reducer = { viskas ka paduodi, nepamirsk ...state, newValue: value}
            // @REDUXHELP
            anal.wallet = action.payload.publicKey;
            return { ...state, publicKey: action.payload.publicKey, balanceSOL: action.payload.balanceSOL, balanceVSCN: action.payload.balanceVSCN, gettingBalance: action.payload.gettingBalance || false };
        // case "GET_STUFF":
        //     return action.payload.SOL;
        // case "CLEAR_BATTLES":
        //     return initialState;
        case WALLET_REDUCER_TYPES.SET_BALANCE_GETTING:
            return { ...state, gettingBalance: action.payload.gettingBalance };
        default:
            return state;
    }
}

export default walletReducer;