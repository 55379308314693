
import { useEffect, useState } from "react";
import "../../css/duel-turn-timer.css";


/* Fire anim: https://codepen.io/dazulu/pen/nPeEeG */

// function DuelTurnTimerFire() {
//     return (
//         <div className="fireContainer ">
//             <div className="red flame"></div>
//             <div className="orange flame"></div>
//             <div className="yellow flame"></div>
//             <div className="white flame"></div>
//             <div className="blue circle"></div>
//             {/* <div className="black circle"></div> */}
//         </div>
//     );
// }

export default function DuelTurnTimer({ endsAt }) {

    const [anim, setAnim] = useState("");
    const [fireAnim, setFireAnim] = useState("");

    const [start, setStart] = useState(new Date().getTime());
    const [animKey, setAnimKey] = useState(0);

    const [duration, setDuration] = useState(0);

    useEffect(() => {
        setStart(Date.now()); //.getTime());
        // console.log("endsAt changed", endsAt, start);
        // console.log(new Date(endsAt), new Date(start));
        calcAnim();
    }, [endsAt]);

    function calcAnim() {
        const duration = (new Date(endsAt).getTime() - start);
        // console.log("duration", duration);
        // setDuration(duration);
        setAnim(`roundtime ${duration}ms linear forwards`);
        setFireAnim(`fireAnim ${duration}ms linear forwards`);
        setAnimKey(prevKey => prevKey + 1);
    }



    return (
        <div className="w-full my-2" >
            {/* {new Date(endsAt).getTime()}
            <br />
            st:{start}
            <br />
            dur:{(new Date(endsAt).getTime() - start)} */}

            <div className="w-full flex rounded-md bg-white/20 "  >
                <div
                    key={animKey}
                    className="block h-2 rounded-md align-right"
                    style={{
                        animation: anim,
                        transformOrigin: "left center",

                    }}>

                    <div className=" relative float-right"
                        style={{
                            marginTop: "-0.75em",
                            marginRight: "-0.5em",
                            animation: fireAnim,
                            transformOrigin: "bottom",
                        }}
                    >
                        {/* <DuelTurnTimerFire /> */}
                        <div className="fireContainer"

                        >
                            <div className="red flame"></div>
                            <div className="orange flame"></div>
                            <div className="yellow flame"></div>
                            <div className="white flame"></div>
                            <div className="blue circle"></div>
                            {/* <div className="black circle"></div> */}
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}