import { useState, useEffect, useMemo } from "react";
import Ship from "./shared/ship";
import Cell from "./shared/cell";

import Battlefield from "./components/common/Battlefield";
import ShipList from "./components/common/ShipList";
import { IShip, ICell, ShipSerialized } from "./shared/types";
import { Button } from "@nextui-org/react";
import { DndContext, DragEndEvent, DragStartEvent, useDndContext } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import DuelTurnTimer from "../DuelTurnTimer";

const CLICK_TIME = 250;

export async function randomizeShipPlacement() {
    let updatedShips = Ship.generate(); //[...ships];
    let updatedCells = Cell.generate(); //new Map(cells);
    for (let i = 0; i < updatedShips.length; ++i) {
        Ship.setPositionRandomly(updatedShips[i], updatedCells);
        Cell.updateCells(updatedCells, updatedShips);
    }

    return { updatedShips, updatedCells };
};

export default function BattleshipsSetupPage({ duel, passedShips, deployed = false, loading = false, deployCallback }) {
  const preShips = Ship.generate();

  //take passedShips and update ships. Only on first render, don't overwrite user ships!
  if (passedShips) {
    passedShips.forEach((s: ShipSerialized) => {
      const ship = preShips.find((item: IShip) => item.id === s.id);
      if (ship) {
        ship.orientation = s.orient;
        ship.move(s.x, s.y);
      }
    });
  }

  const [ships, setShips] = useState(preShips);



  const [cells, setCells] = useState(Cell.generate());
  const [currentShip, setCurrentShip] = useState<IShip | null>(null);
  const [randomizing, setRandomizing] = useState(false);

  const dndContext = useDndContext();


  useEffect(() => {
    updateCells();
  }, [currentShip]);

  const isReadyToPlay = useMemo(
    // () => ships.filter((i: IShip) => i.isOnBoard() && !i.invalid).length === 10,
    () => Ship.checkAllPositions(ships, cells),
    [ships, cells]
  );

  const updateCells = () => {
    const updatedCells = new Map(cells);
    Cell.resetCells(updatedCells);
    Cell.updateCells(updatedCells, ships);
    Ship.checkAllPositions(ships, cells);
    setCells(updatedCells);
  };


  function onDragStart(event: DragStartEvent) {
    const { active } = event;

    // console.log("drag start", event);
    // console.log(event.active.data);
    // document.addEventListener('touchmove', dragStopper, { passive: false });

    const updatedShips = [...ships];
    let ship = updatedShips.find(x => x.id == active.id);
    if (ship) {
      ship.isDragging = true;
      // console.log("found ship", ship);
      ship.dragStart = Date.now();
      setCurrentShip(ship);
      updateCells();
    }

  }

  function onDragEnd(event: DragEndEvent) {
    // console.log("Drag end event", event);
    const { over } = event;

    let shipId = event?.active?.id;
    let overId = over?.id.toString() || null;
    let cell;
    if (overId) cell = cells.get(overId);

    //remove ship from list
    // const updatedShips = ships.filter((i: IShip) => i.id != shipId);
    // setShips(updatedShips);

    const updatedShips = [...ships];
    let ship = updatedShips.find(x => x.id == shipId);
    if (ship) {
      // console.log(ship.dragStart, (Date.now() - CLICK_TIME), ship.dragStart - Date.now())
      if (ship.dragStart > (Date.now() - CLICK_TIME) && Math.max(event?.delta.x, event?.delta.y) < 20) {
        // console.log("was CLICK, not drag");
        ship.rotate();
        ship.invalid = !Ship.isPositionValid(ship, cells);
        // setShips(updatedShips);
        // setCurrentShip(null);
      } else {
        if (cell) {
          ship.move(cell.x, cell.y);
          if (Ship.isPositionValid(ship, cells)) {
            // setShips(updatedShips);
            // setCurrentShip(null);
            // console.log("nudraginom zjbs");
            ship.invalid = false;
          } else {
            // console.log("nudraginom chujowai");
            ship.reset();
          }
          // ship.invalid = !Ship.isPositionValid(ship, cells);

        } else {
          ship.move(0, 0);
          ship.invalid = false;
        }
        // setShips(updatedShips);


      }
      ship.isDragging = false;
      setCurrentShip(null);

      setShips(updatedShips);
    }

  }

  const handlePlayClick = () => {
    const simplifiedShips = ships.map((item: IShip) => item.simplify());
    console.log("CIA SIUST I SERVA KA SUDEJO USERIS");
    console.log("PLAYER SHIPS", simplifiedShips);
    // updateDisposition(simplifiedShips);
    deployCallback(simplifiedShips);
  };

  async function randomizeShips() {
    if (randomizing) {
      console.log("Already randomizing ships!!");
      return;
    }
    // console.log("Randomizing ships", ships);
    setRandomizing(true);

    const { updatedShips, updatedCells } = await randomizeShipPlacement();

    setShips(updatedShips);
    setCells(updatedCells);
    // console.log("Done randomizing", updatedShips);
    setRandomizing(false);
  };


  return (

    <div className=" m-auto">
      <DuelTurnTimer endsAt={duel.endsAt} />

      <div className="text-lg my-4 mt-6">Admiral, set up your flotilla!</div>

      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} modifiers={[restrictToWindowEdges]}>

        {/* flex flex-row flex-wrap sm:flex-nowrap  */}
        <div className="flex flex-row flex-wrap justify-center gap-6 md:flex-none md:grid md:grid-cols-2 md:gap-3 sm:p-2">
          <div className="shrink-0">
            <Battlefield cells={cells} isDroppable={!deployed} isDraggable={!deployed} ships={ships} />
          </div>

          <div className="shrink-0 text-center transition-all">
            <ShipList ships={ships} isDraggable={!deployed} />

            <div className="mx-auto my-4 flex flex-row items-center justify-center gap-4 justify-items-center">
              <ul className="text-white">
                <li>
                  <b>Move</b> - Drag & drop
                </li>
                <li>
                  <b>Rotate</b> - Press
                </li>
              </ul>

              <Button color="secondary" size="sm" onPress={randomizeShips} isDisabled={randomizing || loading || deployed} isLoading={randomizing}>Randomize</Button>
            </div>
          </div>

        </div>

      </DndContext>


      <div className="mt-4">
        <Button color="primary" onPress={handlePlayClick} isDisabled={!isReadyToPlay || randomizing || loading || deployed} isLoading={randomizing || loading}>Confirm placement</Button>
      </div>

      {/* <DragAndDropCursor
          currentShip={currentShip}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onRotateShip={handleRotateShip}
        /> */}
    </div>

  );
};


