import { useEffect, useState } from "react";
import BattleTimer from "../battle/BattleTimer";
import { Button, Card, Link } from "@nextui-org/react";
import AmountChangeNotifier from "../small/AmountChangeNotifier";
import InfoLotteryTooltip from "../info-tooltip/InfoLotteryTooltip";
import VSCNCoin from "../small/VSCNCoin";
import { useSelector } from "react-redux";
import { yellowGlow } from "../../helpers/glows";

// import "../../css/lottery.css";

//lottery types: normal, dice


export default function LotteryInsinde({ lottery, small, buyTicketsButtonCallback, className = "" }: any) {
    // const { id, type, name, img, amount, wallet, tokenWallet } = lottery;
    // const amountNice = `${Intl.NumberFormat().format(Math.round(amount / 1000))}k`;

    const [shouldShake, setShouldShake] = useState(false);

    const [finished, setFinished] = useState(lottery.finished || false);
    const [winner, setWinner] = useState(lottery.winner || 0);

    const { lotteryDicePercent } = useSelector((state: any) => state.betaData);
    const lotteryDiceMultiplier = lotteryDicePercent / 100;

    const { publicKey } = useSelector((state: any) => state.walletBalance);

    useEffect(() => {
        if (lottery.finished) setFinished(lottery.finished);
    }, [lottery.finished]);
    useEffect(() => {
        if (lottery.winner) setWinner(lottery.winner);
    }, [lottery.winner]);

    useEffect(() => {
        setShouldShake(true);
        const timer = setTimeout(() => setShouldShake(false), 300); // Reset after the duration of the shake animation
        return () => clearTimeout(timer); // Clean up timer on unmount
    }, [lottery.amount]);



    const handleTimeEnd = () => {
        setFinished(true);
    }

    return (

        <div className="w-full">
            {/* <div className="text-tiny opacity-70 m-0 float-left">#{lottery?.id}</div> */}
            {small && <div className="text-tiny opacity-70 m-0 float-left">#{lottery?.id}</div>}
            {!small && <Link showAnchorIcon className="text-tiny opacity-70 m-0 float-left cursor-pointer text-white"
                onPress={() => {
                    let url = window.location.href;
                    let title = `Try your luck on ${lottery?.name} on vscn.bet!`;
                    if (navigator.share) {
                        navigator.share({ url, title });
                    } else {
                        navigator.clipboard.writeText(`${title} ${url}`);
                    }
                }}>#{lottery?.id}</Link>
            }

            {/* {!small && <div className="text-tiny opacity-70 m-0 float-left cursor-pointer"
                onClick={() => {
                    navigator.share({
                        url: window.location.href,
                        title: `Try your luck on ${lottery?.name} on vscn.bet!`,
                    });
                }}>#{lottery?.id}</div>
            } */}

            {!small && <InfoLotteryTooltip type={lottery?.type} />}
            {finished ?
                <div className={`${small ? 'text-md' : 'text-xl'} mx-4  text-center p-2`}>Lottery finished! {!winner && <><br />Calculating outcome...</>}</div>
                :
                (lottery.endsAt && <BattleTimer battleData={lottery} onTimeEnd={handleTimeEnd} small={small} />)
            }

            <div className="relative animWrp m-2 gap-4 " >
                <div className="flex flex-row justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <div className="mr-4 ml-4 text-xl font-bold" style={{ fontFamily: "Barmo", ...yellowGlow }}>{lottery?.name}</div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <div className={`flex flex-col justify-center items-center max-w-80 max-h-80  ${shouldShake ? 'shake' : ''}`}>
                        <div className="max-w-100 max-h-100 m-6">
                            <img src={lottery?.img} alt={lottery?.name} className={`${shouldShake ? 'yellowGlow' : ''}`} />
                        </div>
                        <div className="absolute p-2 bg-background/80 rounded-lg text-center" style={{ fontFamily: "Barmo", marginTop: "0", zIndex: 5, }}>
                            <AmountChangeNotifier style={yellowGlow} amount={lottery.amount} className="text-2xl" />
                            {/* {changes.map((change) => (
                                    <div key={change.id}
                                        className="disappear m-auto text-center w-full text-nowrap amountChangeNotifier "
                                        style={{ position: 'absolute', zIndex: 1, top: "-10px", transform: "translateX(-50%)", ...yellowGlow }}>
                                        +{change.value}<img src="/img/logo.png" alt="VersusCoin" className="max-h-6 max-w-6 aspect-square inline-block"  />
                                    </div>
                                ))} */}
                            <div className="text-3xl lg:text-4xl " style={{ ...yellowGlow, zIndex: 0 }} >{Intl.NumberFormat().format(Math.round(lottery?.amount))} </div>
                            {/* <img src="/img/logo.png" alt="VersusCoin" className="max-h-6 max-w-6 aspect-square inline-block"  /> */}
                            <div className="text-xl lg:text-2xl" style={yellowGlow}>VSCN </div>
                        </div>
                    </div>
                </div>
            </div>

            {!small && !finished && lottery?.type == "normal" &&
                <Button
                    className={`name mb-4 text-xl w-auto`}
                    // ${small ? "text-xl" : "text-xl md:text-3xl"}
                    isDisabled={lottery.finished || finished}
                    style={{ fontFamily: "Barmo" }}
                    variant="solid" color="primary"
                    onClick={(ev) => {
                        ev.preventDefault();
                        if (!buyTicketsButtonCallback) return;
                        buyTicketsButtonCallback({ name: lottery?.name, img: lottery?.img, type: lottery?.type, toWallet: lottery?.wallet, toTokenWallet: lottery?.tokenWallet });
                    }}>
                    Get Tickets
                </Button>
            }

            {!small && !finished && lottery?.type == "dice" &&
                <Button
                    className={`name mb-4 text-md w-auto gap-0 `}
                    // ${small ? "text-xl" : "text-xl md:text-3xl"}
                    isDisabled={lottery.finished || finished}
                    style={{ fontFamily: "Barmo" }}
                    variant="solid" color="primary"
                    onClick={(ev) => {
                        ev.preventDefault();
                        if (!buyTicketsButtonCallback) return;
                        buyTicketsButtonCallback({ name: lottery?.name, img: lottery?.img, type: lottery?.type, toWallet: lottery?.wallet, toTokenWallet: lottery?.tokenWallet });
                    }}>
                    <span>Roll for {Intl.NumberFormat().format(Math.ceil(lottery.amount * lotteryDiceMultiplier))}<VSCNCoin /></span>
                </Button>
            }

            {!small && finished && winner &&
                <div className={`text-center rounded-lg  p-2 ${winner == publicKey ? 'bg-success/60' : 'bg-primary/100'} `}>
                    <div style={{ fontFamily: "Barmo" }}>{winner == publicKey ? "You're The Winner!" : 'Winner'}</div>
                    <div className="text-tiny">{winner}</div>
                </div>
            }

            {/* {!small && <div className="text-center text-small ">{lotteryTypeExplanations[type]}</div>} */}
        </div>
        // </Card>
    );

}