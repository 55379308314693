import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function TicketsPage() {

    const [ticketsList, setTicketsList] = useState<any[]>([]);
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();

    function load() {
        // if (ticketsList.length > 0) return;
        if(loading) return;
        setLoading(true);

        doGet("lottery/get-tickets").then((ret) => {
            if (ret.ok) {
                setTicketsList(ret.data);
                console.log("Got tickets", ret);

            } else {
                console.error(ret.error);
                setError(ret.error || "An error has occurred.");
            }
            // setTicketsList(data);
            setLoading(false);
        }
        ).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();

    }, [publicKey]);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Your lottery tickets</h2>

        <div>
            <TransparentTable
                headers={['Lottery', "VSCN", "TX"]}
                label="Your lottery tickets"
                isLoading={loading}
                emptyContent={"No tickets found."}
                error={error}
                load={load}
                data={
                    ticketsList.map((ticket, idx) => {
                        return ([<Link href={`/lottery/${ticket.Lottery?.id}`} size="sm"  >#{ticket.Lottery?.id}</Link>,
                        ticket.amount,
                        <Link href={`https://solscan.io/tx/${ticket.transactionId}`} target="_blank" size="sm" className="after:content-['_↗']" >
                            Open
                        </Link>
                        ]);
                    })
                }
            />

            {/* <Table className="m-auto max-w-xl" aria-label="Bets" classNames={tableClassNames} >
                <TableHeader>
                    <TableColumn>Lottery</TableColumn>
                    <TableColumn>Amount</TableColumn>
                    <TableColumn>TX</TableColumn>
                </TableHeader>
                <TableBody
                    className="text-justify"
                    emptyContent={"No tickets found."}
                    loadingContent={<Spinner color="primary" size="lg" label="Loading..." />}
                >
                    {
                        ticketsList.map((ticket, idx) => {
                            return (<TableRow key={idx}
                                className="text-left"
                            >
                                <TableCell>
                                    <Link href={`/lottery/${ticket.Lottery?.id}`} size="sm"  >#{ticket.Lottery?.id}</Link>&nbsp;
                                    {ticket.Lottery?.name}
                                </TableCell>
                                <TableCell>{ticket.amount}</TableCell>
                                <TableCell>
                                    <Link href={`https://solscan.io/tx/${ticket.transactionId}`} target="_blank" size="sm" className="after:content-['_↗']" >
                                        Open
                                    </Link>
                                </TableCell>
                            </TableRow>);
                        })
                    }
                </TableBody>

            </Table> */}

            <div className="text-sm m-4">
                Showing up to 200 latest lottery tickets.
            </div>
        </div>
    </div >
    );
}