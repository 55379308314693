import { CellStates } from "./cell";
import { Orientation } from "./ship";

export enum BattleshipsDuelState {
  DEPLOYING = 1,
  P1 = 2,
  P2 = 3,
  FINISHED = 4
}

// export type PublicBattelshipsDuelShot = {
//   x: number;
//   y: number;
//   hit: boolean;
//   p: number;
// }

export type BattleshipsShotSerialized = {
  id: number;
  x: number;
  y: number;
  hit: boolean;
  p: number;
  createdAt: Date;
}


export type ShipSerialized = {
  id: number;
  x: number;
  y: number;
  orient: Orientation;
  destroyed: boolean;
};

export type Cursor = {
  shipId: Function;
  move: Function;
};

export interface IPosition {
  position: { x: number; y: number };
  key: string;
  move: Function;
  reset: Function;
  isOnBoard: Function;
}

export interface ICell extends IPosition {
  shipId: number;
  state: CellStates;
  isDamaged: Function;
  isInactive: Function;
  isOpen: Function;
  isFilled: Function;
  shipEl?: any;
}

export interface IShip extends IPosition {
  id: number;
  length: number;
  orient: Orientation;
  positions: Function;
  borders: Function;
  rotate: Function;
  addDamage: Function;
  isDestroyed: Function;
  simplify: Function;
  isDragging: boolean;
  invalid: boolean;
}
