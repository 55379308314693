import { Button, Card, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function StatsPage() {

    const [statsData, setStatsData] = useState<any>();
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();

    const load = () => {
        setLoading(true);

        doGet("stats/all").then((data) => {
            setStatsData(data);
            // console.log("Got stats", data);
            //totalUsers, totalBattles, totalBattleBets, totalVSCNBets, totalDuels, totalPayouts, totalVSCNPaidOut

            setLoading(false);
        }
        ).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (statsData) return;
        load();
    }, []);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Stats</h2>

        <TransparentTable
            headers={['Item', "Value"]}
            label="Stats"
            isLoading={loading}
            emptyContent={"No stats found."}
            error={error}
            load={load}
            data={
                [
                    ["Total Users", statsData?.totalUsers],
                    ["Total Battles", statsData?.totalBattles],
                    ["Total Battle Bets", statsData?.totalBattleBets],
                    ["Total VSCN Bets", Intl.NumberFormat().format(Math.round(statsData?.totalVSCNBets))],
                    ["Total Duels", statsData?.totalDuels],
                    ["Total Payouts", statsData?.totalPayouts],
                    ["Total VSCN Paid Out", Intl.NumberFormat().format(Math.round(statsData?.totalVSCNPaidOut))]
                ]
            }
        />


    </div >
    );
}