import React, { useState } from 'react';
import { Button, Input, Textarea, Card, CardBody } from '@nextui-org/react';
import { useLetsTalk } from '../../hooks/useLetsTalk';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CardNice } from '../small/CardNice';



export default function CIPSubmissionForm(props: { onSubmit?: any }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [urls, setUrls] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const [info, setInfo] = useState<string>('');
    const [success, setSuccess] = useState(false);

    const { user } = useSelector((state: any) => state.userData);
    const navigate = useNavigate();

    const { doPostForm } = useLetsTalk();


    const toggleFormVisibility = () => {
        // console.log("USER", user);
        if (!user) {
            return navigate('/login');
        }
        setSuccess(false);
        // setError('');
        setShowForm(!showForm);
    }



    const handleInfoChange = (e) => setInfo(e.target.value);

    const handleUrlsChange = (e) => {
        setUrls(e.target.value);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFiles = event.target.files ? Array.from(event.target.files) : [];
        // Append new files to the existing files
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const formData = new FormData();
            formData.append('urls', urls);
            // formData.append('files', files);
            files.forEach(file => formData.append('files[]', file, file.name));
            formData.append('info', info);
            // console.log("formData", formData);

            let res = await doPostForm("cip/submit", formData);
            // console.log(res);
            // Clear the form
            if (res.ok) {
                setUrls('');
                setFiles([]);
                setInfo('');
                document.querySelector("#fileUploadInput")?.setAttribute("value", "");
                if (props.onSubmit) props.onSubmit();
            } else {
                setError(res.error);
            }
            setSuccess(res.ok);
        } catch (err: any) {
            console.error("CATCH ERROR", err);
            setError(err.toString ? err.toString() : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className=" mt-6">
            <Button color="primary" onClick={toggleFormVisibility}>
                {showForm ? 'Hide Form' : 'Submit content'}
            </Button>
            {showForm && (
                <CardNice
                    className={` w-full max-w-xl`}
                // isBlurred
                // className={`m-auto text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10  p-2 `}
                // shadow="sm"
                >
                    <CardBody>
                        <h3 className="text-xl font-bold">Content submission form</h3>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <Textarea
                                isRequired
                                type="text"
                                label="Enter URLs (one per line)"
                                placeholder="https://example.com"
                                onChange={handleUrlsChange}
                                fullWidth
                                className="mt-2 w-full"
                                value={urls}
                            />
                            <Input
                                id="fileUploadInput"
                                // @ts-expect-error -- Known issue with NextUI's Input component
                                defaultValue={null}
                                multiple={true}
                                onChange={handleFileChange}
                                // label="Attach files"
                                labelPlacement="inside"
                                type="file"
                                className="mt-2 w-full pt-2"
                                classNames={{
                                    inputWrapper: "h-8 ",
                                    // innerWrapper:"mt-16",
                                    // label:"mb-4",
                                    input: "mt-2",
                                    // input:"h-16 mt-16",
                                    // helperWrapper:"mt-4",

                                }}
                                // @ts-expect-error -- Known issue with NextUI's Input component
                                value={null}
                            />
                            <div className="text-tiny text-right">Max upload limit: 80MB.</div>

                            <div className="mb-6">
                                {files.map((file, index) => (
                                    <div key={index}>{file.name}</div>
                                ))}
                            </div>

                            <Textarea
                                label="Additional Information"
                                placeholder="Write something..."
                                onChange={handleInfoChange}
                                fullWidth
                                className="mt-2 w-full"
                                value={info}
                            />
                            {error && <p className="text-red-400">{error}</p>}
                            {success && <p className="text-green-400">Submission successful!</p>}
                            <Button type="submit" color="primary" isDisabled={loading || !urls || urls?.length < 3} isLoading={loading}>Submit</Button>
                        </form>
                    </CardBody>
                </CardNice>
            )}
        </div>
    );
};

