import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { BattleshipsDuelState, ShipSerialized } from "./shared/types";
import "./battleships.css";
import { CardNice } from "../../small/CardNice";
import useDoTransaction from "../../../hooks/useDoTransaction";
import { toast } from "react-toastify";
import DuelInside from "../DuelInside";
import BattleshipsSetupPage from "./BattleshipsSetupPage";
import { useLetsTalk } from "../../../hooks/useLetsTalk";
import BattleshipsGamePage from "./BattleshipsGamePage";

export default function BattleshipsDuel({ duel, small, className = "" }: any) {
  // duel = { finished: false, id: 1, name: "nigger", player1: 'sdfdf', player2: 0, amount: 0, wallet: 0, img: "", scoreToWin: 0, waitLength: 0, type: "battleships", typeName: "Battleships" };
  // const [currentPage, setCurrentPage] = useState(Pages.Settings);
  // const disposition: ShipSerialized[] = useSelector((state: any) => state.disposition);

  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state: any) => state.userData);
  const [finished, setFinished] = useState(duel.finished || false);

  const [playerShips, setPlayerShips] = useState<ShipSerialized[] | undefined>();

  console.log("BATTLESHIPS DUEL", duel);

  const { doTransaction } = useDoTransaction();
  const { doPost } = useLetsTalk();

  
  const whichPlayer = useMemo(() => {
    console.log("whichPlayer", user?.id, duel.player1id, duel.player2id);
    if (duel.player1id != null && duel.player1id == user?.id) return 1;
    if (duel.player2id != null && duel.player2id == user?.id) return 2;
    return 0;
  }, [duel, user?.id]);


  useEffect(() => {
    setFinished(duel.finished);
  }, [duel.finished]);

  const checkBeforeTransaction = async () => {
    // console.log("checkBeforeTransaction", duel.player2);
    if (duel.player2) {
      // console.log("dont allow");
      toast.error("This duel already has an opponent! You were too slow.");
      return false;
    } else {
      return true;
    }
  }

  const participateInDuelCallback = async () => {
    if (!user) {
      toast.error("Please connect your wallet first!");
      return;
    }

    let transData = {
      amount: duel.amount,
      duelId: duel.id,
      wallet: user?.wallet,
      toWallet: duel.wallet
    }
    let res = await doTransaction({ url: "duel/join", transData, successMessage: "Duel joined!", checkBeforeTransaction });

    if (res) {
      return true;
    } else {

    }
  }

  async function deployCallback(ships: any) {
    if (!user?.wallet) return;
    if (!ships) return;
    if (loading) return;

    console.log("ships", ships);
    setLoading(true);

    let data = {
      duelId: duel.id,
      walletId: user.wallet,
      ships,
    };
    // console.log("sending", data);
    try {
      let res = await doPost("duel/battleships/deploy-ships", data);
      console.log("Battleships deploy", res);
      if (res?.error) {
        toast.error(res.error);
        // return false;
      }
    } catch (err: any) {
      console.error("Error in BattleshipsDeploy", err);
      toast.error(`Error submitting ships placement! ${err?.error || JSON.stringify(err) || ''}`);
    }
    setLoading(false);
  }


  async function shootCallback(x: number, y: number) {
    if (!user) return;
    if (!x || !y) return;
    if (loading) return;

    console.log("Shooting (play shooting sound)", x, y);
    // setLoading(true);

    let data = {
      duelId: duel.id,
      x, y,
    };
    try {
      let res = await doPost("duel/battleships/shoot", data);
      console.log("Shoot ", res);
      if (res?.error) {
        toast.error(res.error);
        // return false;
      }
      if (res?.hit) {
        console.log("PLAY HIT SOUND!");
      } else {
        console.log("PLAY SPLASH SOUND!");
      }

      return res;
    } catch (err: any) {
      console.error("Error in BattleshipsShoot", err);
      toast.error(`Error while shooting! ${err?.error || JSON.stringify(err) || ''}`);
    }
    return { ok: false, error: "Error while shooting!" };

  }

  useEffect(() => {
    // setPlayerShips(duel.BattleshipsShips?.find(ships => ships.userId === user?.id)?.ships);
    if(whichPlayer === 1) return setPlayerShips(duel.P1Ships);
    if(whichPlayer === 2) return setPlayerShips(duel.P2Ships);
  }, [duel.P1Ships, duel.P2Ships, whichPlayer]);

  return (<>
    <div className="w-full m-auto ">
      <DuelInside duel={duel} small={small} participateInDuelCallback={participateInDuelCallback} className={className} />

      {!duel.player2 && !duel.finished && <div className="text-center font-bold m-8">Waiting for an opponent...</div>}

      {duel.player2 &&
        <CardNice className={`w-auto bg-background/10 dark:bg-default-100/10 scale-in-center max-w-auto`}
        >
          {duel.state === BattleshipsDuelState.DEPLOYING && (!playerShips || playerShips?.length < 1) && (
            ((user?.id === duel.player1id || user?.id === duel.player2id) ?
              (
                <BattleshipsSetupPage
                  duel={duel}
                  deployed={!!playerShips && playerShips.length > 0}
                  deployCallback={deployCallback} loading={loading}
                  passedShips={playerShips}
                />
                // duel.duel.BattleshipsShips?.find(ships => ships.wallet === user?.wallet) 
                // ? <div className="text-center font-bold m-8">Waiting for opponent...</div>
                // : <BattleshipsSetupPage duel={duel} deployed={} />
              )
              : <div className="text-center font-bold m-8">Players are deploying ships...</div>)
          )}

          { (duel.state === BattleshipsDuelState.P1 || duel.state === BattleshipsDuelState.P2 ||
            (playerShips && playerShips?.length > 0 && duel.state === BattleshipsDuelState.DEPLOYING) ||
            duel.state === BattleshipsDuelState.FINISHED
          ) && (
              <BattleshipsGamePage
                duel={duel}
                user={user}
                whichPlayer={whichPlayer}
                shootCallback={shootCallback}
              />
            )}

        </CardNice>
      }


    </div>
  </>);
};
