import { Card, Chip } from "@nextui-org/react";
import { useMemo } from "react";
import MinefieldBoard from "./MinefieldBoard";
import UserChip from "../../small/UserChip";
import ToolTipFix from "../../extensions/TooltipFix";
import { CardNice } from "../../small/CardNice";

export function MinefieldBoardHistory({ boards, currentAction, duel, whichPlayer }) {
    let maxRound = Math.max(...boards.map(b => b.round));

    const memoedComp = useMemo(() => (
        <CardNice
        className="w-full max-w-xl bg-background/10 dark:bg-default-100/10 p-1"
            // isBlurred
            // className={`m-auto mt-6 text-center w-full max-w-xl border-none bg-background/10 dark:bg-default-100/10 p-1 `}
            // shadow="sm"
        >

            <h4 className="text-center font-bold text-2xl ">History</h4>
            {boards.map((board, i) => {

                if (board?.round == currentAction?.round) return null; // skip current round

                let mines = duel.player2 == duel?.winner ? board.squares1 : board.squares2;
                let minesWinner = duel.player1 == duel?.winner ? board.squares1 : board.squares2;
                if (!duel.winner) {
                    mines = (whichPlayer === 1 ? board?.squares1 : board?.squares2);
                    minesWinner = (whichPlayer === 1 ? board?.squares2 : board?.squares1);
                }
                return <div key={board.round}>
                    <div className="text-center text-lg font-bold mt-4 mb-1">Round {board.round + 1} </div>
                    <div className="text-center text-md mb-2">{board.round < maxRound ?
                        "Tie!" :
                        // (<>Winner <UserChip wallet={duel.winner} noColor={true} className="bg-success" /></>)
                        <><span className="mr-2">Winner</span>
                            <ToolTipFix showArrow={true} content={duel.player2 ? duel.player2 : "Waiting for an opponent"}>
                                <Chip color={"success"} size="md" className="text-white">{duel.player2 ? duel.player2?.substring(0, 6) : "Waiting..."}</Chip>
                            </ToolTipFix>
                        </>
                    }</div>

                    <MinefieldBoard key={i} size={board.size}
                        mines={mines}
                        minesWinner={minesWinner}
                        disabled={true} />
                </div>
            })}
        </CardNice>
    ), [boards]);
    return memoedComp;

}