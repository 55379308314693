import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function PayoutsPage() {

    const [payoutsList, setPayoutsList] = useState<any[]>([]);
    // const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const [reset, setReset] = useState(false);
    const { doGet, doPost } = useLetsTalk();

    const { user } = useSelector((state: any) => state.userData);

    const maintenance = useSelector((state: any) => state.maintenanceData);


    async function load() {
        if (loading) return
        setLoading(true);

        doGet("payouts/get-payouts").then((ret) => {
            if (ret.ok) {
                setPayoutsList(ret.data);
                // console.log("Got payouts", data);
            } else {
                setError(ret.error || "An error has occurred.");
            }
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setError(error?.toString() || JSON.stringify(error));
            setLoading(false);
        });

    }

    useEffect(() => {
        if (payoutsList.length > 0) return;
        load();
    }, [user, reset]);

    function changePayout(payout, x) {
        const payoutsUpd = [...payoutsList];
        // const p = payoutsUpd.find(
        //     a => a.id === payout.id
        // );
        const index = payoutsUpd.findIndex(a => a.id === payout.id);
        if (index !== -1) {
            const updatedPayout = { ...payoutsUpd[index], ...x };
            payoutsUpd[index] = updatedPayout;
        }
        // p.loading = x?.loading;
        // p.sending = x?.sending;
        setPayoutsList(payoutsUpd);
    }

    const requestPayout = (payout: any) => {
        if (payout.loading) return false;

        if (maintenance && maintenance?.starts < Date.now() && maintenance?.enabled) {
            toast.error("Transactions disabled because of the maintenance. Please wait until the maintenance is over.");
            return { ok: false, error: "Maintenance" };
        }

        changePayout(payout, { loading: true });

        doPost("payouts/request-payout", { payoutId: payout.id, walletId: user.wallet }).then((data) => {
            console.log("Payout response", data);
            if (data.sending) {
                changePayout(payout, { sending: true });
            }
            if (data.ok) {
                toast.success("Payout sent!");
                changePayout(payout, data.payout);
                setReset(!reset);
            } else {
                toast.error(data.error);
            }
        }).catch((error) => {
            console.error("Payout error", error)
            changePayout(payout, { loading: false });
            toast.error(error);
        });

    }

    return (<div>
        <h2 className="text-3xl font-bold m-3">Your payouts</h2>


        <div>

            <TransparentTable
                headers={['Event', "VSCN", "TX"]}
                label="Your payouts"
                isLoading={loading}
                emptyContent={"No payouts found, loser. Go bet and win!"}
                error={error}
                data={
                    payoutsList.map((payout, idx) => {
                        let ev;
                        if (payout.Battle) {
                            ev = <>
                                <Link href={`/battle/${payout.Battle?.id}`} size="sm"  >#{payout.Battle?.id}</Link>
                                &nbsp;{payout.Battle?.name1} vs {payout.Battle?.name2}
                            </>;
                        } else if (payout.Lottery) {
                            ev = <>
                                <Link href={`/lottery/${payout.Lottery?.id}`} size="sm"  >#{payout.Lottery?.id}</Link>
                                &nbsp;{payout.Lottery?.name}
                            </>;
                        } else if (payout.Duel) {
                            ev = <>
                                <Link href={`/duel/${payout.Duel?.type}/${payout.Duel?.id}`} size="sm"  >#{payout.Duel?.id}</Link>
                                &nbsp;[{payout.Duel?.type?.toUpperCase()}] {payout.Duel?.name}
                            </>;
                        } else if (payout.CommunitySubmissions?.length > 0) {
                            ev = <>
                                <Link href={`/earn/cip`} size="sm"  >CIP {payout.CommunitySubmissions?.map(cip => `[${cip.id}] `)}</Link>
                            </>;
                        }

                        return ([ev, Intl.NumberFormat().format(payout.amount),
                            payout.transactionId ?
                                <Link href={`https://solscan.io/tx/${payout.transactionId}`} target="_blank" size="sm" className="after:content-['_↗']" >
                                    Open
                                </Link> :
                                <Button size="sm" color="primary" isLoading={payout.loading || payout.sending} isDisabled={payout.loading || payout.sending} onClick={() => requestPayout(payout)} >Request</Button>
                        ]);
                    })
                }
            />

            {/* <Table className="m-auto max-w-xl" aria-label="Bets" classNames={tableClassNames} >
                <TableHeader>
                    <TableColumn>Event</TableColumn>
                    <TableColumn>Amount</TableColumn>
                    <TableColumn>TX</TableColumn>
                </TableHeader>
                <TableBody
                    className="text-justify"
                    emptyContent={"No payouts found, loser. Go bet and win!"}
                    loadingContent={<Spinner color="primary" size="lg" label="Loading..." />}
                >
                    {
                        payoutsList.map((payout, idx) => {
                            let ev;
                            if (payout.Battle) {
                                ev = <>
                                    <Link href={`/battle/${payout.Battle?.id}`} size="sm"  >#{payout.Battle?.id}</Link>
                                    &nbsp;{payout.Battle?.name1} vs {payout.Battle?.name2}
                                </>;
                            } else if (payout.Lottery) {
                                ev = <>
                                    <Link href={`/lottery/${payout.Lottery?.id}`} size="sm"  >#{payout.Lottery?.id}</Link>
                                    &nbsp;{payout.Lottery?.name}
                                </>;
                            } else if (payout.Duel) {
                                ev = <>
                                    <Link href={`/duel/${payout.Duel?.type}/${payout.Duel?.id}`} size="sm"  >#{payout.Duel?.id}</Link>
                                    &nbsp;[{payout.Duel?.type?.toUpperCase()}] {payout.Duel?.name}
                                </>;
                            }
                            return (<TableRow key={idx}
                                className="text-left"
                            >
                                <TableCell>{ev}
                                
                                </TableCell>
                                <TableCell>{payout.amount}</TableCell>

                                <TableCell>
                                    {payout.transactionId ?
                                        <Link href={`https://solscan.io/tx/${payout.transactionId}`} target="_blank" size="sm" className="after:content-['_↗']" >
                                            Open
                                        </Link> :
                                        <Button size="sm" color="primary" isLoading={payout.loading || payout.sending} isDisabled={payout.loading || payout.sending} onClick={() => requestPayout(payout)} >Request</Button>
                                    }
                                </TableCell>
                            </TableRow>);
                        })
                    }
                </TableBody>

            </Table> */}

            <div className="text-sm m-4">
                Showing up to 200 latest payouts.
            </div>
        </div>
    </div >
    );
}