import { BETA_REDUCER_TYPES } from "../reducers/betaReducer";
import { WALLET_REDUCER_TYPES } from "../reducers/walletReducer";
import { BATTLES_REDUCER_TYPES } from "../reducers/battlesReducer";
import { LOTTERIES_REDUCER_TYPES } from "../reducers/lotteriesReducer";
import { MAINTENANCE_REDUCER_TYPES } from "../reducers/maintenanceReducer";
import { DUELS_REDUCER_TYPES } from "../reducers/duelsReducer";
import { USER_REDUCER_TYPES } from "../reducers/userReducer";
import { CHAT_REDUCER_TYPES } from "../reducers/chatReducer";
import { MessageModel } from "../../components/messages/Message";



//--------------------WALLET--------------------
export const setWalletBalanceAction = ({ publicKey, balanceSOL, balanceVSCN, gettingBalance = false }) => ({
    type: WALLET_REDUCER_TYPES.SET_BALANCE,
    payload: { publicKey, balanceSOL, balanceVSCN, gettingBalance }
});

export const setWalletBalanceGettingAction = ({ gettingBalance }) => ({
    type: WALLET_REDUCER_TYPES.SET_BALANCE_GETTING,
    payload: { gettingBalance }
});

//--------------------USER--------------------
export const setUserData = ({ user, jwt, jwtLoaded }) => ({
    type: USER_REDUCER_TYPES.SET_USER,
    payload: { user, jwt, jwtLoaded }
});

//--------------------BETA--------------------
export const setBetaData = ({ betaAccess, betLimit, currentFee, lamportsRequiredToBet, lotteryDicePercent }) => ({
    type: BETA_REDUCER_TYPES.SET_DATA,
    payload: { betaAccess, betLimit, currentFee, lamportsRequiredToBet, lotteryDicePercent }
});
export const setBetaAccess = ({ betaAccess }) => ({
    type: BETA_REDUCER_TYPES.SET_ACCESS,
    payload: { betaAccess }
});


//--------------------BATTLES--------------------
export const addBattleAction = (battle) => ({
    type: BATTLES_REDUCER_TYPES.BATTLE_ADD,
    payload: { battle }
});

export const updateBattleAction = (battle) => ({
    type: BATTLES_REDUCER_TYPES.BATTLE_UPDATE,
    payload: { battle }
});

export const removeBattleAction = (battleId) => ({
    type: BATTLES_REDUCER_TYPES.BATTLE_REMOVE,
    payload: { battleId }
});

export const setBattlesAction = (battles) => ({
    type: BATTLES_REDUCER_TYPES.BATTLES_SET,
    payload: { battles }
});


//--------------------LOTTERIES--------------------
export const addLotteryAction = (lottery) => ({
    type: LOTTERIES_REDUCER_TYPES.LOTTERY_ADD,
    payload: { lottery }
});

export const updateLotteryAction = (lottery) => ({
    type: LOTTERIES_REDUCER_TYPES.LOTTERY_UPDATE,
    payload: { lottery }
});

export const removeLotteryAction = (lotteryId) => ({
    type: LOTTERIES_REDUCER_TYPES.LOTTERY_REMOVE,
    payload: { lotteryId }
});

export const setLotteriesAction = (lotteries) => ({
    type: LOTTERIES_REDUCER_TYPES.LOTTERIES_SET,
    payload: { lotteries }
});

//--------------------DUELS--------------------
export const addDuelAction = (duel) => ({
    type: DUELS_REDUCER_TYPES.DUEL_ADD,
    payload: { duel }
});

export const updateDuelAction = (duel) => ({
    type: DUELS_REDUCER_TYPES.DUEL_UPDATE,
    payload: { duel }
});

export const removeDuelAction = (duelId) => ({
    type: DUELS_REDUCER_TYPES.DUEL_REMOVE,
    payload: { duelId }
});

export const setDuelsAction = (duels) => ({
    type: DUELS_REDUCER_TYPES.DUELS_SET,
    payload: { duels }
});


//--------------------MAINTENANCE--------------------
export const setMaintenanceData = ({ enabled, starts, message }) => ({
    type: MAINTENANCE_REDUCER_TYPES.MAINTENANCE_SET_DATA,
    payload: { enabled, starts, message }
});


//--------------------CHAT MESSAGES--------------------
export const addMessageAction = (message: MessageModel) => ({
    type: CHAT_REDUCER_TYPES.MESSAGE_ADD,
    payload: { message }
});

export const prependMessagesAction = (messages: MessageModel[]) => ({
    type: CHAT_REDUCER_TYPES.MESSAGES_PREPEND,
    payload: { messages }
});

export const updateMessageAction = (message: MessageModel) => ({
    type: CHAT_REDUCER_TYPES.MESSAGE_UPDATE,
    payload: { message }
});

export const removeMessageAction = (messageId: number) => ({
    type: CHAT_REDUCER_TYPES.MESSAGE_REMOVE,
    payload: { messageId }
});

export const setMessagesAction = (messages: MessageModel[]) => ({
    type: CHAT_REDUCER_TYPES.MESSAGES_SET,
    payload: { messages }
});

export const resetNewMessagesCounterAction = () => ({
    type: CHAT_REDUCER_TYPES.RESET_NEW_MESSAGES_COUNTER,
    payload: {}
});

// @REDUXHELP
// export const setBattlesAction = ({ balanceSOL, balanceVSCN }) => ({
//     type: "SET_BATTLES",
//     payload: { battles: [] }
// });