

export const redGlow = {
    WebkitFilter: "drop-shadow(0 0 12px rgba(255, 32, 32, 0.65))",
    color: "rgb(255, 32, 32)"
};
export const yellowGlow = {
    WebkitFilter: "drop-shadow(0 0 12px rgba(255, 238, 106, 0.65))",
    color: "rgb(255, 238, 106)"
};
export const greenGlow = {
    WebkitFilter: "drop-shadow(0 0 12px rgba(32, 255, 32, 0.65))",
    color: "rgb(32, 255, 32)"
};
export const whiteGlow = {
    WebkitFilter: "drop-shadow(0 0 16px rgba(255, 255, 255, 0.5))",
    // color: "rgb(255, 255, 255)"
};
export const whiteGlowSmall = {
    WebkitFilter: "drop-shadow(0 0 6px rgba(255, 255, 255, 0.5))",
    // color: "rgb(255, 255, 255)"
};