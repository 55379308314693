import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLetsTalk } from "../hooks/useLetsTalk";
import TransparentTable from "../components/extensions/TransparentTable";
import { Button, Input, Link, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Textarea } from "@nextui-org/react";


export default function AdminPage() {

    const { user } = useSelector((state: any) => state.userData);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);

    const { doGet, doPost } = useLetsTalk();

    const [cipList, setCipList] = useState<any>([]);

    const [cipRewards, setCipRewards] = useState<any[]>([]);
    const [totalReward, setTotalReward] = useState(0);

    const [sendingCIPupdates, setSendingCIPupdates] = useState(false);

    function addCIPupd(id: number, type: string, value: string) {
        let upd: any = cipRewards.find((c: any) => c.id === id);
        console.log("upd", upd, id, type, value);
        if (!upd) {
            upd = { id: id };
            let cip = cipList.find((c: any) => c.id === id);
            if (cip) {
                upd.user = cip.userId;
                upd.wallet = cip.wallet;
            }
            cipRewards.push(upd);
        }

        if (type === 'reward') {
            upd.reward = Number.parseFloat(value);
            if (isNaN(upd.reward)) {
                upd.reward = 0;
            }
        }
        if (type === 'response') {
            upd.response = value;
        }
        setTotalReward(cipRewards.reduce((acc, c) => acc + c.reward, 0));
        // setCipRewards([...cipRewards, upd]);
        setCipRewards([...cipRewards]);
    }

    function loadCIP() {
        if (loading) return;
        setLoading(true);
        doPost("admin/cip/get", {
            // where: Prisma.CommunitySubmissionWhereInput,
            // skip: number,
            // take: number
            skip: cipList?.length || 0,
            take: 50
        }).then((ret) => {
            console.log("CIPs", ret);
            ret = ret.map((c: any) => {
                if (c.reward) { c.hasReward = true; }
                return c;
            });
            setCipList([...cipList, ...ret]);
            if (ret.error) {
                setError(ret.error);
            }
            setLoading(false);

        }).catch((err) => {
            setError(JSON.stringify(err));
            setLoading(false);
        });

    }

    function sendCIPupdates() {
        if (sendingCIPupdates) return;
        // eslint-disable-next-line no-restricted-globals
        let cont = confirm("Are you sure you want to submit these rewards?: " + JSON.stringify(cipRewards));
        if (!cont) {
            return;
        }

        setSendingCIPupdates(true);

        doPost("admin/cip/add-payout", {
            cipUpdates: cipRewards,
            reward: totalReward
        }).then((ret) => {
            console.log("CIPs add-payout ret", ret);
            alert(JSON.stringify(ret));

            if (ret?.error) {
                setError(JSON.stringify(ret?.error));
            }
            setSendingCIPupdates(false);

        }).catch((err) => {
            alert(JSON.stringify(err));

            setError(JSON.stringify(err));
            setSendingCIPupdates(false);
        });
    }


    useEffect(() => {
        loadCIP();
    }, []);



    if (!user || !user.admin) {
        toast.error("You are not authorized to view this page");
        return <Navigate to="/" />;
    }


    return (
        <div>
            <h1>Admin Page</h1>
            <div className="text-red-400">{error}</div>

            <Table
                aria-label="CIP submissions"

            >
                <TableHeader>
                    {["ID", "userId", "wallet", 'URLs', "Info", "Files", "Response", "Reward"].map((header, idx) => (
                        <TableColumn key={idx}>{header}</TableColumn>
                    ))}

                </TableHeader>
                <TableBody items={cipList}
                    isLoading={loading}
                    emptyContent={
                        error ? <div>
                            <div className="text-red-400">{error}</div>
                            <br />
                            {<Button color="primary" onClick={() => loadCIP()}>Retry</Button>}
                        </div>
                            : "nothing"
                    }


                >
                    {cipList?.map((cip: any, idx) => {
                        if (!cip) return ["-", "-", "-", "-", "-", "-", "-", "-"];
                        const urls = cip?.urls ? cip.urls.split(/[\s\n]+/) : [];

                        return ([
                            cip.id,
                            cip.userId,
                            cip.wallet,
                            <div className="flex flex-col">
                                {urls?.length > 0 ? urls?.map((url, index) => {
                                    if (!url) return "-";
                                    return (
                                        <Link size="sm" key={index} href={url} target="_blank" className="text-blue-200">
                                            {url}
                                        </Link>);
                                }) : "-"}
                            </div>,
                            cip.info || "-",
                            <div className="flex flex-col">
                                {cip.files?.length > 0 ? cip.files?.map((file) => {
                                    if (!file) return "-";
                                    return (<Link key={file.id} size="sm" href={file.url} target="_blank" className="text-blue-200">
                                        {file?.name?.length > 12 ? file.name.substring(file.name.length - 12) : file?.name ?? 'Unknown'}
                                    </Link>);
                                }) : "-"}
                            </div>,
                            <Textarea
                                type="text" defaultValue={cip.response}
                                disabled={cip.hasReward ||  (cipRewards?.[0] && cipRewards?.[0]?.wallet && cipRewards?.[0]?.wallet != cip.wallet)}
                                onChange={(e) => {
                                    cip.response = e.target.value;
                                    addCIPupd(cip.id, 'response', e.target.value);
                                }} />,
                            // cip.response || "-",
                            <Input type="number" className="max-w-24" defaultValue={cip.reward}
                                disabled={cip.hasReward || (cipRewards?.[0] && cipRewards?.[0]?.wallet && cipRewards?.[0]?.wallet != cip.wallet)}

                                onChange={(e) => {
                                    cip.reward = e.target.value;
                                    addCIPupd(cip.id, 'reward', e.target.value);
                                }} />,
                        ]);
                    }).map((row, idx) => {
                        return (
                            <TableRow key={idx} className="text-left">
                                {row.map((cell, idy) => (
                                    <TableCell key={idy}>{cell}</TableCell>
                                ))}
                            </TableRow>);
                    })}
                </TableBody>
            </Table>
            <Button color="secondary" disabled={loading} isLoading={loading} onClick={() => { loadCIP(); }}>Load More</Button>

            {<div>
                {cipRewards.map((cip) => (
                    <div key={cip.id}>
                        {cip.id} - {cip.user} - {cip.wallet} - {cip.reward} - {cip.response} <br />
                    </div>
                ))}
                <br />Total rewards: {totalReward}
            </div>}
            <br />
            <Button color="success" disabled={sendingCIPupdates} isLoading={sendingCIPupdates} onClick={() => { sendCIPupdates(); }}>Submit rewards</Button>


        </div>
    )
}