import { base58ToRGBA } from "../../helpers/base58ToHex";


export default function UserChip({ wallet, className = '', size = "md", noColor = false }:
    { wallet: string, className?: string, size?: "md" | "sm", noColor?: boolean }) {

    return (
        <span
            style={!noColor ? {
                backgroundColor: base58ToRGBA(wallet, 0.85),
            } : {}}
            className={`text-white cursor-pointer flex-1 text-inherit font-normal relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-2 
                ${size === "md" && 'py-1 px-2 text-md'} 
                ${size === "sm" && 'py-0.5 text-sm'}
                rounded-full bg-primary text-white ${className}`} 
        >
            {wallet.substring(0, 6)}
        </span>
    );
}