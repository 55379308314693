import React, { useEffect, useState } from 'react';
import VSCNCoin from './VSCNCoin';

const AmountChangeNotifier = ({ amount, style={}, className='' }) => {
  const [prevAmount, setPrevAmount] = useState(amount);
  const [changes, setChanges] = useState<any[]>([]);

  useEffect(() => {
    if (prevAmount !== amount) {
      const change = amount - prevAmount;
      setPrevAmount(amount);
      setChanges((prevChanges) => [...prevChanges, { id: `${Date.now()}${Math.random()}`, value: change }]);
    }
  }, [amount]);

  useEffect(() => {
    const timers = changes.map((change) =>
      setTimeout(() => {
        setChanges((prevChanges) => prevChanges.filter((c) => c.id !== change.id));
      }, 1000)
    );
    return () => timers.forEach((timer) => clearTimeout(timer)); // Clean up timers on unmount
  }, [changes]);

  return (<>
     {/* <div className=" " > */}
      {changes.map((change) => (
        <div key={change.id} className={`disappear m-auto text-center w-full text-nowrap amountChangeNotifier ${className}`} style={{ position: 'absolute', top: "-10px", transform: "translateX(-50%)",  ...style }}>
          +{change.value}<VSCNCoin />
          </div>
      ))}
    {/* /</div> */}
  </>);
};

export default AmountChangeNotifier;