const initialState = {
    duels: []
}

export const DUELS_REDUCER_TYPES = {
    DUEL_ADD: "DUEL_ADD",
    DUEL_UPDATE: "DUEL_UPDATE",
    DUEL_REMOVE: "DUEL_REMOVE",
    DUELS_SET: "DUELS_SET"
}

const duelsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DUELS_REDUCER_TYPES.DUEL_ADD: {
            if (!action.payload.duel) return state;
            let found = false;
            const ret = {
                duels: state.duels.map((duel: any) => {
                    if (duel.id === action.payload.duel.id) {
                        found = true;
                        return {
                            ...duel,
                            ...action.payload.duel
                        };
                    }
                    return duel;
                })
            };
            if (found) {
                return ret;
            } else {
                return {
                    duels: [action.payload.duel, ...(state.duels || [])].sort((a: any, b: any) => {
                        if (a.finished && !b.finished) {
                            return 1;
                        }
                        if (b.finished && !a.finished) {
                            return -1;
                        }
                        return new Date(a.endsAt).getTime() - new Date(b.endsAt).getTime();
                    })
                };
            }
            break;
        }
        case DUELS_REDUCER_TYPES.DUEL_UPDATE: {
            const ret = {
                duels: state.duels.map((duel: any) => {
                    if (duel.id === action.payload.duel.id) {
                        if (action.payload.duel.timestamp && action.payload.duel.timestamp < (duel.timestamp || 0)) {
                            return duel;
                        }
                        return {
                            ...duel,
                            ...action.payload.duel
                        };
                    }
                    return duel;
                }).sort((a: any, b: any) => {
                    if (a.finished && !b.finished) {
                        return 1;
                    }
                    if (b.finished && !a.finished) {
                        return -1;
                    }
                    return new Date(a.endsAt).getTime() - new Date(b.endsAt).getTime();
                })
            };
            return ret;
            break;
        }
        case DUELS_REDUCER_TYPES.DUEL_REMOVE:
            return {
                duels: state.duels.filter((duel: any) => duel.id !== action.payload.duelId)
            };
        case DUELS_REDUCER_TYPES.DUELS_SET:
            return {
                duels: action.payload.duels
            };
        default:
            return state;
    }
}

export default duelsReducer;