import { Chip } from "@nextui-org/react";
import RotatingCoin from "../RotatingCoin";
import { whiteGlow } from "../../helpers/glows";



export default function AppLogo({ className }: { className: string }) {
    return (<div className={`flex flex-col  ${className}`} >
        <div className="relative text-center m-auto w-auto">
            {/* <img src="/img/logo.png" alt="VersusCoin Logo" className="logoFlip m-auto max-w-32 lg:max-w-32"
                style={{ WebkitFilter: "drop-shadow(0 0 12px rgba(255, 255, 255, 0.65))" }} /> */}
            <div className="block relative  mx-auto my-2 "
                style={whiteGlow}
            >
                <RotatingCoin size="12px" img="/img/logo.png" />
            </div>

        </div>
        <div className={`text-2xl md:text-3xl lg:text-4xl slide-in-bck-center block m-auto w-auto relative ${className}`} >
            <Chip className="m-0 float-right bg-red-500/80" style={{ transform: "rotate(23deg)", marginRight: "-1.5em", marginBottom: "-1.5em" }} size="sm" color="danger">BETA</Chip>

            <div className="text-shadow-drop-center m-auto w-auto" style={{ fontFamily: "Barmo", WebkitFilter: "drop-shadow(0 0 12px rgba(255, 255, 255, 0.65))" }} >VersusCoin</div>

            {/* <div className=" font-bold m-auto w-auto text-red-600" style={{ fontSize: "0.6em",  marginTop: "-0.75em" }} >BETA</div> */}
        </div >

    </div>
    );

}