import Position from "./position";
// import { ClassOfShip, Orientation } from "../types/enums";
import { IShip, ICell, ShipSerialized } from "./types";



interface IProps {
  id: number;
  length: number;
  x: number;
  y: number;
}

export enum Orientation {
  Horizontal = "h",
  Vertical = "v"
}

export enum ClassOfShip {
  Submarine = 1,
  Destroyer = 2,
  Cruiser = 3,
  Battleship = 4,
}

export const BATTLEFIELD_SIZE = 10;
export const TOTAL_SHIPS_COUNT = 10;

export const LABELS_OF_SHIP: { [key: string]: string } = {
  [ClassOfShip.Submarine]: "Submarine",
  [ClassOfShip.Destroyer]: "Destroyer",
  [ClassOfShip.Cruiser]: "Cruiser",
  [ClassOfShip.Battleship]: "Battleship"
};


class Ship extends Position implements IShip {
  id = -1;
  length = 0;
  life = 0;
  orientation = Orientation.Horizontal;
  dragStart = 0;
  invalid = false;

  isDragging = false;

  constructor({ id, x, y, length }: IProps) {
    super(x, y);

    this.id = id;
    this.length = length;
    this.life = length;
  }

  get orient() {
    return this.orientation;
  }

  rotate() {
    this.orientation =
      this.orientation === Orientation.Horizontal
        ? Orientation.Vertical
        : Orientation.Horizontal;
  }

  addDamage() {
    this.life -= 1;
  }

  isDestroyed() {
    return this.life === 0;
  }

  positions() {
    const result: any[] = [];

    let xOffset = 0;
    let yOffset = 0;

    if (this.orientation === Orientation.Horizontal) {
      xOffset = 1;
    } else {
      yOffset = 1;
    }

    for (let i = 0; i < this.length; ++i) {
      const x = this.position.x + i * xOffset;
      const y = this.position.y + i * yOffset;

      result.push({ x, y });
    }

    return result;
  }

  borders() {
    const result: any[] = [];

    if (!this.isOnBoard()) return result;

    if (this.orientation === Orientation.Horizontal) {
      for (let i = 0; i < this.length; ++i) {
        result.push(
          new Position(this.position.x + i, this.position.y - 1),
          new Position(this.position.x + i, this.position.y + 1)
        );
      }

      result.push(
        new Position(this.position.x - 1, this.position.y),
        new Position(this.position.x - 1, this.position.y - 1),
        new Position(this.position.x - 1, this.position.y + 1),
        new Position(this.position.x + this.length, this.position.y),
        new Position(this.position.x + this.length, this.position.y - 1),
        new Position(this.position.x + this.length, this.position.y + 1)
      );
    } else {
      for (let i = 0; i < this.length; ++i) {
        result.push(
          new Position(this.position.x - 1, this.position.y + i),
          new Position(this.position.x + 1, this.position.y + i)
        );
      }

      result.push(
        new Position(this.position.x + 1, this.position.y - 1),
        new Position(this.position.x, this.position.y - 1),
        new Position(this.position.x - 1, this.position.y - 1),
        new Position(this.position.x + 1, this.position.y + this.length),
        new Position(this.position.x, this.position.y + this.length),
        new Position(this.position.x - 1, this.position.y + this.length)
      );
    }

    return result;
  }

  simplify(): ShipSerialized {
    return {
      id: this.id,
      orient: this.orientation,
      x: this.position.x,
      y: this.position.y,
      destroyed: this.isDestroyed()
    };
  }

  static generate() {
    return [
      new Ship({ id: 10, x: 0, y: 0, length: ClassOfShip.Battleship }),
      new Ship({ id: 1, x: 0, y: 0, length: ClassOfShip.Cruiser }),
      new Ship({ id: 2, x: 0, y: 0, length: ClassOfShip.Cruiser }),
      new Ship({ id: 3, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      new Ship({ id: 4, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      new Ship({ id: 5, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      new Ship({ id: 6, x: 0, y: 0, length: ClassOfShip.Submarine }),
      new Ship({ id: 7, x: 0, y: 0, length: ClassOfShip.Submarine }),
      new Ship({ id: 8, x: 0, y: 0, length: ClassOfShip.Submarine }),
      new Ship({ id: 9, x: 0, y: 0, length: ClassOfShip.Submarine })
      // new Ship({ id: 10, x: 0, y: 0, length: ClassOfShip.Battleship }),
      // new Ship({ id: 1, x: 0, y: 0, length: ClassOfShip.Cruiser }),
      // new Ship({ id: 2, x: 0, y: 0, length: ClassOfShip.Cruiser }),
      // new Ship({ id: 3, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      // new Ship({ id: 4, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      // new Ship({ id: 5, x: 0, y: 0, length: ClassOfShip.Destroyer }),
      // new Ship({ id: 6, x: 0, y: 0, length: ClassOfShip.Submarine }),
      // new Ship({ id: 7, x: 0, y: 0, length: ClassOfShip.Submarine }),
      // new Ship({ id: 8, x: 0, y: 0, length: ClassOfShip.Submarine }),
      // new Ship({ id: 9, x: 0, y: 0, length: ClassOfShip.Submarine })
    ];
  }

  static setPositionRandomly(ship: IShip, cells: Map<string, ICell>) {
    const freeCells = Array.from(cells.values()).filter(
      (i: ICell) => !i.isFilled() && !i.isInactive()
    );

    const t = true;
    // let tries = 0;
    // while (tries < 100) {
    while (t) {
      // tries++;
      const index = Math.floor(Math.random() * freeCells.length);

      if (!freeCells[index]?.position) {
        console.error("No freeCells[index].position", freeCells[index]);
        continue;
      }
      ship.move(freeCells[index].position.x, freeCells[index].position.y);

      if (Ship.isPositionValid(ship, cells)) {
        break;
      } else {
        ship.rotate();
      }
    }
    // if(tries >= 95){
    //   console.log("Tries !!!", tries);
    // }

  }

  static isPositionValid(ship: any, cells: any) {
    const shipCells = ship.positions();

    // console.log("shipCells", shipCells);
    for (let i = 0; i < shipCells.length; ++i) {
      const position = shipCells[i];
      const cell = cells.get(`${position.x}:${position.y}`);

      // if (cell) {
      //   console.log("CELL CHECK", ship.id, cell.shipId, cell, cell.isFilled(), cell.isInactive());
      // }

      if (!cell || ((cell.shipId !== ship.id && cell.isFilled()) || cell.isInactive())) {
        //(cell.shipId !== ship.id &&
        return false;
      }
    }

    return true;
  }

  static checkAllPositions(ships: Ship[], cells: Map<string, ICell>) {
    let allGood = true;
    ships.forEach(ship => {
      ship.invalid = ship.isOnBoard() && !this.isPositionValid(ship, cells);
      if (ship.invalid) allGood = false;
      if (!ship.isOnBoard()) allGood = false;
    })
    return allGood;
  }
}



export default Ship;
