import { Button, Link, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tableClassNames } from "../../helpers/table-classnames";
import TransparentTable from "../../components/extensions/TransparentTable";
import { useLetsTalk } from "../../hooks/useLetsTalk";


export default function BattleHistoryPage() {

    const [battleList, setBattleList] = useState<any[]>([]);
    const { publicKey } = useSelector((state: any) => state.walletBalance);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const { doGet } = useLetsTalk();


    const load = () => {
        setLoading(true);

        doGet("battle/get-history").then((data) => {
            setBattleList(data);
            // console.log("Got battles", data);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setError(`An error has occurred while loading. ${error?.toString && error.toString()}`);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (battleList.length > 0) return;
        load();
    }, [publicKey]);

    return (<div>
        <h2 className="text-3xl font-bold m-3">Finished battles</h2>
        <div>
            <TransparentTable
                headers={['ID', "Fighters", "Winner", "Total VSCN"]} //, "Total Bets"]}
                label="Battle history"
                isLoading={loading}
                emptyContent={"No battle history found."}
                error={error}
                load={load}
                data={
                    battleList.map((battle, idx) => {
                        let health1 = Math.round(battle?.amount1 / (battle?.amount1 + battle?.amount2) * 100)
                        if (!health1) health1 = 50;
                        return (
                            [<Link href={`/battle/${battle.id}`} size="sm"  >#{battle.id}</Link>,
                            `${battle?.name1} (${health1}%) vs ${battle?.name2} (${100 - health1}%)`,
                            (battle.winner === 1 ? battle?.name1 : battle?.name2),
                            `${Intl.NumberFormat().format(battle?.amount1 + battle?.amount2)}`,
                            // battle?._count?.bets
                            ]
                        );
                    })
                }
            />

       
            < div className="text-sm m-4" >
                Showing up to 200 latest battles.
            </div >
        </div >
    </div >
    );
}