import { useState, useEffect, useMemo, useRef } from "react";
import Battlefield from "./components/common/Battlefield";
import Ship from "./shared/ship";
import Cell from "./shared/cell";
import { BattleshipsDuelState, IShip, ShipSerialized } from "./shared/types";
import sounds from "../../../services/sounds";


export default function BattleshipsGamePage({ duel, user, whichPlayer, shootCallback }) {

  const [shooting, setShooting] = useState(false);
  //   const nahimov = useMemo(() => createNahimov(), []);

  // const playerOneShips = useMemo(() => Ship.generate(), []);
  // const playerTwoShips = useMemo(() => Ship.generate(), []);
  const [playerOneShips, setPlayerOneShips] = useState<any>(null);
  const [playerTwoShips, setPlayerTwoShips] = useState<any>(null);


  const [playerOneCells, setPlayerOneCells] = useState(mapCells(duel.P1Cells, playerOneShips));
  const [playerTwoCells, setPlayerTwoCells] = useState(mapCells(duel.P2Cells, playerTwoShips));
  const [playerOneShots, setPlayerOneShots] = useState<{ x: number, y: number, hit: boolean }[]>([]);
  const [playerTwoShots, setPlayerTwoShots] = useState<{ x: number, y: number, hit: boolean }[]>([]);
  //   const [gameState, setGameState] = useState(GameStates.Play);
  const [winner, setWinner] = useState(duel?.winner || null);

  const [lastOpponentShot, setLastOpponentShot] = useState(null);

  const [shaking, setShaking] = useState(false);

  const isGameOver = useMemo(() => !!duel.winner, [duel.winner]);

  useEffect(() => {
    // let p1ships: ShipSerialized[] = duel.BattleshipsShips?.find(x => x.userId === duel.player1id)?.ships;
    let p1ships = duel?.P1Ships;
    // console.log("p1ships", p1ships);

    if (!p1ships || p1ships.length < 1) {
      setPlayerOneShips(null);
      return;
    }

    let ships = Ship.generate();
    p1ships?.forEach(ship => {
      let sh = ships.find(s => s.id === ship.id);
      if (!sh) return;
      sh.orientation = ship.orient;
      sh.move(ship.x, ship.y);

    });
    setPlayerOneShips(ships);
  }, [duel.P1Ships, duel.player1id]);

  useEffect(() => {
    // let p2ships: ShipSerialized[] = duel.BattleshipsShips?.find(x => x.userId === duel.player2id)?.ships;
    let p2ships = duel?.P2Ships;
    // console.log("p2ships", p2ships);

    if (!p2ships || p2ships.length < 1) {
      setPlayerTwoShips(null);
      return;
    }

    let ships = Ship.generate();
    p2ships?.forEach(ship => {
      let sh = ships.find(s => s.id === ship.id);
      if (!sh) return;
      sh.orientation = ship.orient;
      sh.move(ship.x, ship.y);
    });
    setPlayerTwoShips(ships);
  }, [duel.P2Ships, duel.player2id]);

  useEffect(() => {
    let p1shots = duel.shots?.filter(x => x.p === 1);
    setPlayerOneShots(p1shots);
    let p2shots = duel.shots?.filter(x => x.p === 2);
    setPlayerTwoShots(p2shots);

    //for sound effects
    let oppShots = duel.shots;
    if (whichPlayer === 1) {
      oppShots = p2shots;
    } else if (whichPlayer === 2) {
      oppShots = p1shots;
    }
    let lastShot = oppShots?.sort((a, b) => b.timestamp - a.timestamp)?.[0];
    if (lastShot && lastShot !== lastOpponentShot) {
      //shot changed
      // console.log("last opp shot changed", lastShot, winner);
      setLastOpponentShot(lastShot);
      if (winner) return;
      if (lastShot) {
        if (lastShot?.hit) {
          sounds.playBattleshipsExplosion();
        } else {
          sounds.playBattleshipsSplash();
        }
      }
    }
  }, [duel.shots, whichPlayer]);

  useEffect(() => {
    setWinner(duel?.winner);
  }, [duel?.winner]);


  function mapCells(newCells, ships) {
    let cells = Cell.generate();
    if (!newCells) {
      // console.error("no new cells", newCells);
      return cells;
    }
    newCells.forEach((c) => {
      let fndCell = cells.get(`${c.x}:${c.y}`);
      if (!fndCell) return console.error("didnt find cell", c.x, c.y);
      fndCell.state = c.currentState;
      fndCell.shipId = c.currentShipId;

    });
    if (ships) {
      Cell.updateCells(cells, ships);
    }
    return cells;
  }

  useEffect(() => {
    // console.log("P1Cells", duel.P1Cells);
    if (duel.P1Cells) {
      let c = mapCells(duel.P1Cells, playerOneShips);
      // console.log("setting p1 cells", c);
      setPlayerOneCells(c);
    }
  }, [duel.P1Cells, playerOneShips])

  useEffect(() => {
    if (duel.P2Cells) {
      let c = mapCells(duel.P2Cells, playerTwoShips);
      // console.log("setting p2 cells", c);
      setPlayerTwoCells(c);
    }
  }, [duel.P2Cells, playerTwoShips])

  const shakeTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  function shakeBattlefield() {
    setShaking(true);
    if (shakeTimeoutRef.current) clearTimeout(shakeTimeoutRef.current);
    shakeTimeoutRef.current = setTimeout(() => setShaking(false), 200);
  }




  async function handleOnClick(x, y) {
    // console.log("handleOnClick", x, y);
    if (shooting || isGameOver || whichPlayer == 0 || (whichPlayer === 1 && duel.state !== BattleshipsDuelState.P1) || (whichPlayer === 2 && duel.state !== BattleshipsDuelState.P2)) {
      shakeBattlefield();
      return;
    }

    console.log("SHOOT", whichPlayer);

    let enemyCells = whichPlayer === 1 ? playerTwoCells : playerOneCells;
    const cell = enemyCells.get(`${x}:${y}`);

    if (!cell || cell.isDamaged() || cell.isOpen() || cell.isInactive()) {
      shakeBattlefield();
      return console.error("invalid cell", cell); //shake
    }

    let shots = whichPlayer === 1 ? playerOneShots : playerTwoShots;

    let foundShot = shots.find(shot => shot.x === x && shot.y === y);
    if (foundShot) {
      shakeBattlefield();
      return console.error("already shot here"); //shake
    }

    setShooting(true);

    sounds.playBattleshipsFire();

    let shootRes = await shootCallback(x, y);
    console.log("shootRes", shootRes);
    //IF HIT PLAY HIT, IF NOT PLAY NOT LMAO
    if (shootRes.hit) {
      sounds.playBattleshipsExplosion();
    } else {
      sounds.playBattleshipsSplash();
    }


    setShooting(false);

  };


  function getPlayerName(side, playerNum) {
    if (side === 1 && playerNum === 1) return "Your ships";
    if (side === 1 && playerNum === 2) return "Enemy's ships";
    if (side === 2 && playerNum === 1) return "Enemy's ships";
    if (side === 2 && playerNum === 2) return "Your ships";
    if (side === 1) return "Player 1";
    if (side === 2) return "Player 2";
  }

  const stateText = useMemo(() => {
    if (duel.state === BattleshipsDuelState.FINISHED) return "Game over!";
    if (whichPlayer === 0) {
      if (duel.state === BattleshipsDuelState.P1) return "Player 1 attacking!";
      if (duel.state === BattleshipsDuelState.P2) return "Player 2 attacking!";
    }
    if ((duel.state === BattleshipsDuelState.P1 && whichPlayer === 1) ||
      (duel.state === BattleshipsDuelState.P2 && whichPlayer === 2)) {
      return "Attack now!";
    } else {
      return "Enemy's turn!";
    }
  }, [duel.state, whichPlayer]);

  // console.log("CELLS", playerOneCells)
  return (
    <div className="flex flex-col justify-items-center justify-center content-center items-center">
      {/* <Header as="h3" content="Whist all up!" /> */}
      <h3 className="text-xl mb-0">{stateText}</h3>

      <div className="flex flex-row flex-wrap md:flex-nowrap gap-4  justify-center">

        <SideBattlefield
          cells={playerOneCells}
          shots={playerTwoShots}
          ships={playerOneShips}
          onCellClick={whichPlayer === 2 ? handleOnClick : undefined}
          shake={shaking && whichPlayer === 2}
          currentTurn={duel.state === BattleshipsDuelState.P1}
          waiting={(duel.state === BattleshipsDuelState.DEPLOYING && (!playerOneShips || playerOneShips?.length < 1))}
          playerName={getPlayerName(1, whichPlayer)}
        />

        <SideBattlefield
          cells={playerTwoCells}
          shots={playerOneShots}
          ships={playerTwoShips}
          onCellClick={whichPlayer === 1 ? handleOnClick : undefined}
          shake={shaking && whichPlayer === 1}
          currentTurn={duel.state === BattleshipsDuelState.P2}
          waiting={(duel.state === BattleshipsDuelState.DEPLOYING && (!playerTwoShips || playerTwoShips?.length < 1))}
          playerName={getPlayerName(2, whichPlayer)}
        />



      </div>

    </div>
  );
};

function SideBattlefield({ cells, shots, ships, onCellClick, shake, waiting, currentTurn, playerName }) {
  return (
    <div className="flex flex-col justify-center  items-center">
      <div className="font-bold mb-2">{playerName}</div>
      {waiting &&
        <div className="z-10 absolute  bg-transparent/50 rounded-lg p-8 ">Waiting for player to deploy</div>
      }
      <div className={`transition-all ${shake ? "shake":''}`}
       style={{ opacity: currentTurn ? 0.75 : 1 }}>
        <Battlefield cells={cells} shots={shots} ships={ships} onCellClick={onCellClick} />
      </div>


    </div>
  )
}

const mapStateToProps = (state) => ({
  disposition: state.disposition,
});

// const mapDispatchToProps = {
//   resetDisposition,
// };

