import { Button, Card, CardBody, Link, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { socket } from "../../services/socket";
import { useDispatch, useSelector } from "react-redux";
import { addDuelAction, updateDuelAction } from "../../store/actions/reducerActions";
import { useLetsTalk } from "../../hooks/useLetsTalk";
import MinefieldDuel from "../../components/duel/minefield/MinefieldDuel";
import RPSDuel from "../../components/duel/rps/RPSDuel";
import CoinFlipDuel from "../../components/duel/coin-flip/CoinFlipDuel";
import { CardNice } from "../../components/small/CardNice";
import BattleshipsDuel from "../../components/duel/battleships/BattleshipsDuel";

export default function DuelPage({ type }) {
    const { id } = useParams();
    const duelId = Number.parseInt(id || '');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const { duels } = useSelector((state: any) => state.duelsData);
    const d = duels.find(x => x.id === duelId);
    const [currentDuel, setCurrentDuel] = useState(d);
    const { doGet } = useLetsTalk();

    const navigate = useNavigate();

    const loadDuel = async () => {
        if (loading) return;
        setLoading(true);
        setError(null);
        doGet(`duel/${type}/get?id=${duelId}`).then((duel) => {
            setLoading(false);
            setError(null);

            console.log("loaded duel", duel);

            if (duel.type && duel.type != type) navigate(`/duel/${duel.type}/${duel.id}`);
            dispatch(addDuelAction(duel));
            let dl = duels.find(x => x.id === duelId);
            setCurrentDuel(dl);
            document.title = `${duel.typeName} Duel ${duel.player1?.substring(0, 6)} vs ${duel.player2?.substring(0, 6) || 'Waiting...'} - VersusCoin Duels - vscn.bet`;
        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setError(error.toString());
        });
    }

    useEffect(() => {
        if (!duelId) redirect("/");
        if (!currentDuel) {
            loadDuel();
        }
    }, [duelId, currentDuel]);

    useEffect(() => {
        let dl = duels.find(x => x.id === duelId);
        setCurrentDuel(dl);
    }, [duels, duelId]);

    useEffect(() => {
        document.title = `${currentDuel?.typeName} Duel ${currentDuel?.player1} vs ${currentDuel?.player2 || 'Waiting...'} - VersusCoin Duels - vscn.bet`;

        loadDuel();

        function joinRoom() {
            socket.emit('join-room', `duel-${duelId}`);
        }
        joinRoom();

        socket.io.on("reconnect", joinRoom);
        return () => {
            socket.emit('leave-room', `duel-${duelId}`);
            socket.io.off("reconnect", joinRoom);
        };
    }, []);

    return (
        <>
            <CardNice
                className="w-full sm:min-w-80 sm:w-auto  "
                //max-w-xl 
                finished={currentDuel?.finished}>
                {/* {currentDuel ? <MinefieldDuel duel={currentDuel} /> : <div className="m-4">Loading duel...</div>} */}
                {loading ?
                    <div className="m-4">
                        <Spinner size="lg" />
                        <div>Loading duel...</div>
                    </div>
                    :
                    error ?
                        <div className="m-4">
                            <div>Error loading duel.</div>
                            <div className="text-sm mb-4 text-danger">{error}</div>
                            <Button color="primary" isLoading={loading} onClick={loadDuel}>Retry</Button>
                        </div>
                        : ''
                }

                {
                    currentDuel && (
                        (() => {
                            switch (currentDuel.type) {
                                case 'minefield':
                                    return <MinefieldDuel duel={currentDuel} />;
                                case 'rps':
                                    return <RPSDuel duel={currentDuel} />;
                                case 'coin':
                                    return <CoinFlipDuel duel={currentDuel} />;
                                case 'battleships':
                                    return <BattleshipsDuel duel={currentDuel} />
                                default:
                                    return <div>Unknown duel type</div>;
                            }
                        })()
                    )
                }
            </CardNice>
        </>
    )
}