import { useEffect, useState } from "react";


import { toast } from "react-toastify";
import { socket } from "../../services/socket";

import LotteryInsinde from "./LotteryInside";

import useDoTransaction from "../../hooks/useDoTransaction";
import { useSelector } from "react-redux";
import useTicketsModalByType from "../../hooks/useTicketsModalByType";
import MessagesBox from "../messages/MessagesBox";
import { MessageModel } from "../messages/Message";



export default function Lottery({ lottery, animationDelay = "0.0" }: any) {
    // const { name, type, img, amount, wallet, tokenWallet,} = lottery;
    const lotteryId = lottery.id;


    // const { publicKey } = useSelector((state: any) => state.walletBalance);
    const { user } = useSelector((state: any) => state.userData);


    const { doTransaction } = useDoTransaction();

    let apiBuyUrl;
    if (lottery.type == "normal") {
        apiBuyUrl = "lottery/buy-tickets";
    } else if (lottery.type == "dice") {
        apiBuyUrl = "lottery/roll-dice";
    }

    const { ModalComponent, openModal, closeModal } = useTicketsModalByType({
        lottery,
        onCloseCallback: () => null,
        onSuccessCallback: async (ticketData) => {
            // console.log("BUYING TICKETS", ticketData);
            let res: any = await doTransaction({
                url: apiBuyUrl, transData: ticketData,
                successMessage: (lottery.type == "normal") ? "Tickets purchase confirmed!" : "Dice roll confirmed!"
            });
            // console.log("RES from api", res);
            if (res && res.ok) {
                return res;
            } else {
                console.error("Error buying tickets", res);
            }
            return res;
        },
        modalData: {
            ticketData: {},
        }
    })


    const buyTicketsButtonClick = async (ticketData) => {
        if (!user) {
            toast.error("Please connect your wallet first!");
            return;
        }
        ticketData.lotteryId = lotteryId;
        openModal(ticketData);
    };


    useEffect(() => {
        // console.log("sending join lottery", lotteryId);
        socket.emit('join-lottery', lotteryId);
    }, [lotteryId]);



    return (<>

        <LotteryInsinde lottery={lottery} buyTicketsButtonCallback={buyTicketsButtonClick} />
        {/* <LotteryMessages lottery={lottery} /> */}

        <MessagesBox url={`lottery/get-messages?lotteryId=${lotteryId}`}
            title={`${lottery.type == "dice" ? "Gamblers'" : "Delusonal Hopers'"} Lounge`}
            noMessages={`${lottery?.type == "dice" ? "Roll the dice" :"Get your tickets"} and engrave your words on the chain!`}
            sideFunc={(message: MessageModel) => (message.user?.wallet == user?.wallet)}
            socketChannel={"lottery-message"}
        />

        {ModalComponent}
    </>);

}