import { useState } from "react";
import { whiteGlow } from "../../../helpers/glows";
import { Card, Spinner } from "@nextui-org/react";
import { CardNice } from "../../small/CardNice";

export interface MinefieldBoardProps {
    size: number;
    mines: number[];
    minesWinner?: number[];
    disabled?: boolean;
    clickCallback?: (coord: number) => void;
    selected?: number | null;
    loading?: number | null;
}

const MinefieldBoard: React.FC<MinefieldBoardProps> = ({
    size, mines, minesWinner = null, selected = null, loading = null, disabled, clickCallback
}) => {


    return (
        <CardNice
            key={mines?.length}
            className={`w-full bg-background/10 dark:bg-default-100/10 aspect-square scale-in-center`}
            // isBlurred
            // className={`m-auto text-center w-full border-none bg-background/10 dark:bg-default-100/10 p-2 aspect-square  scale-in-center`}
            // shadow="sm"
        >
            <div className={`grid w-full gap-1`}
                style={{
                    gridTemplateColumns: `repeat(${size}, 1fr)`,
                    gridTemplateRows: `repeat(${size}, 1fr)`,
                    opacity: disabled ? 0.8 : 1
                }}
            >
                {
                    Array.from({ length: size * size }, (_, i) => i).map((coord, i) => {
                        let used = mines.includes(coord);
                        let winner = minesWinner?.includes(coord);
                        let thisLoading = loading === coord && !used && !winner;

                        let baseClass = "";

                        // Step 2: Append condition-specific classes
                        if (used && minesWinner && !winner) {
                            baseClass += " bg-primary/80";
                        } else if (!used && winner) {
                            baseClass += " bg-success/80";
                        } else if (used && winner) {
                            baseClass += " bg-danger/80";
                        // } else if (!used && !winner && !disabled) {
                        //     baseClass += " hover:bg-foreground/100 cursor-pointer";
                        } else {
                            if (!used && !winner && !disabled) {
                                baseClass += " hover:bg-foreground/100 cursor-pointer";
                            }
                            baseClass += " bg-foreground/50";
                        }


                        return (
                            <div key={i}
                                className={`flex justify-center items-center p-1 rounded-xl drop-shadow-lg aspect-square sm:min-w-20 sm:min-h-20 max-w-20 max-h-20 overflow-hidden  ${baseClass}`}
                                // ${!used && `bg-foreground/50  `} 
                                // className={`flex justify-center items-center bg-foreground/50 p-1
                                // ${used && minesWinner && !winner && `bg-primary/100`}
                                // ${!used && winner && `bg-success/100`}
                                // ${used && winner && `bg-danger/100`}
                                // ${!used && !winner && !disabled && `hover:bg-foreground/100 cursor-pointer`} 
                                // rounded-md drop-shadow-lg aspect-square`}
                                // style={{ ...whiteGlow }}
                                style={{ ...(!selected || selected === coord ? whiteGlow : {}) }}
                                onClick={() => {
                                    // console.log("clicked", coord);
                                    if (used || disabled || !clickCallback) return;
                                    clickCallback(coord)
                                }}
                            >
                                {/* {used && !winner && <img src="/duels/minefield/mine.svg" className="w-full h-full" alt="Mine" />} */}
                                {/* {used}{winner} */}
                                {(!thisLoading && (used || winner)) && <img src="/img/duels/minefield/mine.svg" className="w-full h-full max-w-16 max-h-16" alt="Mine" />}
                                {thisLoading && <Spinner />}

                            </div>
                        );
                    })
                }
            </div>
        </CardNice>
    )
}

export default MinefieldBoard;