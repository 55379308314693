
import { ConnectWalletBox } from "../components/auth/ConnectWallet";

import { Chip, Link, Spinner } from "@nextui-org/react";
import { Navigate, redirect, useNavigate } from "react-router-dom";
// import { setBetaAction } from "../store/actions/reducerActions";
import { useDispatch, useSelector } from "react-redux";


export default function LoginPage() {


    // // const [allowed, setAllowed] = useState(false);

    // // const navigate = useNavigate();

    // const dispatch = useDispatch();

    // const [checking, setChecking] = useState(false);
    // const [error, setError] = useState('');

    // const [betaAccess, setBetaAccess] = useState(false);
    // const { betaAccess } = useSelector((state: any) => state.betaData);


    // useEffect(() => {
    //     setError('');
    //     if (publicKey) {
    //         setChecking(true);
    //         console.log("Checking wallet", publicKey);
    //         fetch(`${process.env.REACT_APP_API_URL}beta/check-wallet`, {
    //             method: "POST",
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ walletId: publicKey.toString() }),
    //         })
    //             .then(response => response.json())
    //             .then((ret) => {
    //                 console.log("response", ret);
    //                 if (ret.allowed) {
    //                     console.log("ALLOWED", ret, ret.allowed);
    //                     dispatch(setBetaData({ betaAccess: ret.allowed, betLimit: ret.betLimit, currentFee: ret.currentFee, lamportsRequiredToBet: ret.lamportsRequiredToBet}));
                        

    //                     // setBetaAccess(ret.allowed);
    //                     // navigate("/battle");
    //                     // return redirect("/battle");

    //                 } else {
    //                     setError(ret.error ? ret.error : "Something went wrong. Please try again later.");
    //                     dispatch(setBetaAccess({ betaAccess: false }));
    //                 }


    //                 setChecking(false);
    //             })
    //             .catch(async (data) => {
    //                 console.error("ERROR", data);
    //                 // if(data.error){
    //                 //     setError(data.error);
    //                 // }
    //                 dispatch(setBetaAccess({ betaAccess: false }));
    //                 // setBetaAccess(false);

    //                 setError(data.error ? data.error : "Server error!");
    //                 setChecking(false);
    //             });
    //     } else {
    //         dispatch(setBetaAccess({ betaAccess: false }));

    //     }
    // }, [publicKey]);


    // const { betaAcess } = useSelector((state: any) => state.betaAccess);
    // useEffect(() => {
    //     const { betaAcess } = useSelector((state: any) => state.betaAccess);


    //     console.log("EFFECT BETACCESS", betaAcess);
    //     if (betaAcess) {
    //         navigate("/battle");
    //     }
    //     // }, [betaAcess]);
    // }, [useSelector((state: any) => state.betaAccess).betaAcess]);

    // useEffect(() => {
    //     console.log("BETA ACCESS", betaAccess);
    //     if (betaAccess) {
    //         console.log("BETA ACCESS NAVIGATE");
    //         navigate("/battle");
    //     }
    // }, [betaAccess, checking, publicKey]);

    // const betaAccessRed = useSelector((state: any) => state.betaAccess).betaAccess;


    // useEffect(() => {
    //     if (betaAccess) {
    //         // history.push('/'); // Redirect to the desired route
    //         navigate("/battle");
    //     }
    // }, [betaAccessRed]);

    const { publicKey } = useSelector((state: any) => state.walletBalance);


    return (
        <>
            <div className="flex flex-col items-center justify-center text-white pb-36">

                <h2 className="text-2xl font-bold">Welcome to VersusCoin open beta!</h2>
                <h3 className="text-lg m-6">Please connect your Solana wallet with <Link color="foreground" href="https://vscn.bet"
            target="_blank">VSCN</Link> to continue.</h3>
                {/* <img src="/logo.png" className="max-w-32" alt="VersusCoin" />
                <p>
                    Hello!
                </p> */}

                <ConnectWalletBox />

                {/* <div className="mt-8">
                    {checking ? <Spinner size="lg" /> : ''}
                    {error && error.length > 0 ? <Chip color="danger" size="lg">{error}</Chip> : ''}
                </div> */}
            </div>
            {/* Access:  {betaAccess} */}
            {publicKey ? <Navigate to="/" /> : ''}

        </>
    );
}

