
import { Button, Tooltip } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import ToolTipFix from "../extensions/TooltipFix";


function getTimeRemaining(t, small = false) {
    if (!t) return '';

    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));

    return (
        <span className={`${small ? 'text-lg' : (hours <= 1 ? 'text-3xl' : 'text-xl')}`}
            style={{
                color: "#f5f5f5",
                textShadow: ((days < 1 && hours < 1 && minutes < 5) ? "0px -2px 4px #fff, 0px -2px 10px #FF3, 0px -10px 20px  #F90, 0px -20px 40px #C33" : '')
            }}
        >
            {/* {days > 0 ? `${days}d ` : ''}{hours > 0 ? `${hours}h ` : ''}{(days < 1 && hours < 5 && minutes > 0) ? `${minutes}m ` : ''}{(days < 1 && hours < 1 && minutes < 30) && seconds > 0 ? `${seconds}s` : ''} */}
            {days > 0 ? `${days}d ` : ''}{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
        </span>
    );

}



export default function BattleTimer({ battleData, onTimeEnd, small, className = "" }: any) {
    let endsAt = new Date(battleData?.endsAt);
    let niceDate = `${endsAt.toLocaleDateString()} ${endsAt.toLocaleTimeString()}`;

    const [timer, setTimer] = useState<any>();
    const [timeLeft, setTimeLeft] = useState(getTimeRemaining(Date.parse(battleData?.endsAt) - Date.now(), small));

    const intervalRef = useRef<any>();

    function update() {
        let t = Date.parse(battleData?.endsAt) - Date.now();
        setTimeLeft(getTimeRemaining(t, small));
        if (t < 1) {
            clearInterval(intervalRef.current);
            onTimeEnd();
        }
    }

    useEffect(() => {
        // console.log("endsAt changed", battleData?.endsAt);
        clearInterval(intervalRef.current);
        const intv = setInterval(() => {
            update();
        }, 1000);
        intervalRef.current = intv;
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [battleData?.endsAt]);

    return (
        // <div className={` m-auto p-2 text-center ${className}`}>
        <div className={`m-auto mx-8 text-center p-2 ${className}`}>
            <ToolTipFix content={niceDate} size="sm" className={`${small ? "pointer-events-none " : ""}`}  >
                <span color="default" className={`mx-2 bg-transparent ${small ? "pointer-events-none " : ""}`} >{timeLeft}</span>
            </ToolTipFix>
        </div>
        // <div className={` m-auto p-2 ${className}`}>
        //     <Tooltip content={niceDate} size="sm"  className={`${small ? "pointer-events-none " : ""}`}  >
        //         <Button color="default" variant="flat" className={`bg-transparent ${small ? "pointer-events-none " : ""}`} >{timeLeft}</Button>
        //     </Tooltip>
        // </div>
    );
}